import { Checkbox, CheckboxProps, FormControlLabel, Box } from '@mui/material';
import { useTheme, Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Scatter,
  ComposedChart,
  TooltipFormatter,
  LabelFormatter
} from 'recharts';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';

export type xValue = number;
export type yValue = number | null | undefined;

export type PointType = {
  x: xValue;
  probe: yValue;
  eq5d: yValue;
  vas: yValue;
  id: number;
  surveyName: string;
};

export interface LineChartProps {
  data: PointType[];
  studies: { surveyName: string; dateCompleted: string; instanceId: number }[];
  onPastSurveyHover?: (instanceId: number | undefined) => void;
}

type Score = 'eq5d' | 'probe' | 'vas';

type Scores = Record<Score, { caption: string; color: string }>;

const AllScores: Score[] = ['probe', 'eq5d', 'vas'];

const CustomColorCheckBox: React.FC<Omit<CheckboxProps, 'color'> & { color: string }> = ({ color, ...props }) => {
  const ColoredCheckBox = withStyles({
    root: {
      color,
      '&$checked': {
        color
      }
    },
    checked: {}
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);
  //@ts-ignore
  return <ColoredCheckBox {...props} />;
};

const useStyle = makeStyles<Theme, {}>(theme =>
  createStyles({
    root: {
      minHeight: '150px',
      width: '100%'
    },
    chart: {
      marginTop: theme.spacing(2)
    },
    chartContainer: {
      height: '300px',
      [theme.breakpoints.up('md')]: {
        height: '380px'
      }
    }
  })
);

const tickFormatter = (suggestedDate: any): string => {
  const date = new Date(suggestedDate);
  if (isNaN(date.getTime())) {
    return '';
  }
  return format(date, 'MMM yyyy');
};

const labelFormatter: LabelFormatter = label => {
  const completedDate = format(new Date(label), 'MMM dd, yyyy');
  return completedDate;
  // const studyName = payload[0]?.payload?.studyName;
  // const completedDate = format(new Date(label), 'MMM dd, yyyy');
  // return (
  //   <>
  //     <Typography variant="body1" component="span">
  //       {studyName}
  //     </Typography>
  //     <br />
  //     <Typography variant="body2" component="span">
  //       {completedDate}
  //     </Typography>
  //   </>
  // );
};

export const LineChart: React.FC<LineChartProps> = ({ data, onPastSurveyHover }) => {
  const [filteredDataIds, setFilteredDataIds] = useState<Score[]>(AllScores);
  const [instanceId] = useState<number | undefined>(undefined);
  const theme = useTheme();
  const classes = useStyle(theme);

  useEffect(() => {
    onPastSurveyHover?.(instanceId);
  }, [onPastSurveyHover, instanceId]);

  const SCORES: Scores = useMemo(
    () => ({
      probe: {
        caption: 'PROBE Score',
        color: theme.palette.primary.main
      },
      vas: {
        caption: 'EQ-5D VAS Score',
        color: theme.palette.secondary.light
      },
      eq5d: {
        caption: 'EQ-5D Score',
        color: theme.palette.secondary.main
      }
    }),
    [theme]
  );

  const formatter: TooltipFormatter = (value, name) => {
    return [value, SCORES[name as Score]?.caption];
  };

  const handleLegendClick = useCallback(
    (id: Score) => (event: React.ChangeEvent<{}>, checked: boolean) => {
      if (checked) {
        setFilteredDataIds(filterIds => [...filterIds, id]);
      } else {
        setFilteredDataIds(filterIds => filterIds.filter(fId => fId !== id));
      }
    },
    [setFilteredDataIds]
  );

  return (
    <div className={classes.root}>
      <Box display="flex" flexWrap="wrap">
        {AllScores.map(id => {
          const { caption, color } = SCORES[id];
          return (
            <FormControlLabel
              key={id}
              control={
                <CustomColorCheckBox
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<CheckCircleIcon />}
                  checked={filteredDataIds.includes(id)}
                  name={id}
                  color={color}
                />
              }
              label={caption}
              onChange={handleLegendClick(id)}
            />
          );
        })}
      </Box>
      <Box className={classes.chartContainer}>
        <ResponsiveContainer width="100%" height="100%" className={classes.chart}>
          <ComposedChart
            data={data}
            margin={{
              top: 5,
              left: -20,
              right: 50,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="x" type="number" domain={['auto', 'auto']} tickFormatter={tickFormatter} tickMargin={10} />
            <YAxis />
            <Tooltip formatter={formatter} labelFormatter={labelFormatter} />
            {filteredDataIds.includes('probe') && <Scatter line dataKey="probe" fill={SCORES['probe'].color} />}
            {filteredDataIds.includes('eq5d') && <Scatter line dataKey="eq5d" fill={SCORES['eq5d'].color} />}
            {filteredDataIds.includes('vas') && <Scatter line dataKey="vas" fill={SCORES['vas'].color} />}
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </div>
  );
};
