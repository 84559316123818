import { FC } from 'react';

import { formatter, Question } from 'services';
import { Typography, Table, TableHead, TableRow, TableCell, TableBody, Theme, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useCurrentQuestion } from '../hooks';

const useStyles = makeStyles<Theme, {}>(theme =>
  createStyles({
    exampleTable: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: theme.shape.borderRadius
    }
  })
);

export const QuestionExampleTableFromQuestion: FC<{ question: Question }> = ({ question }) => {
  const classes = useStyles({});
  const { tableHeading, table } = formatter.splitTableFromQuestion(question);

  if (!table.length) {
    return <></>;
  }

  const [tableHead, ...tableBody] = table;
  return (
    <Box p={2} overflow="auto" width="100%" className={classes.exampleTable}>
      {tableHeading && <Typography variant={'subtitle2'}>{tableHeading}</Typography>}
      <Table stickyHeader style={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            {tableHead.map((headText, headIndex) => (
              <TableCell key={headIndex}>{headText}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableBody.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row.map((cellValue, cellIndex) => (
                <TableCell key={cellIndex}>{cellValue}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export const QuestionExampleTable: FC = () => {
  const question = useCurrentQuestion();
  return <QuestionExampleTableFromQuestion question={question} />;
};
