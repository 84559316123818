import { PROBE_ENDPOINT_BASE, PROBE_RESEARCH_CLIENT_ID } from './constants';
import { get, basicAuthHeader } from './axios-helpers';

const TERMS_ENDPOINT = `${PROBE_ENDPOINT_BASE}api/v1/Account/Terms`;
const PRIVACY_ENDPOINT = `${PROBE_ENDPOINT_BASE}api/v1/Account/Privacy`;

export const getTermOfUse = async (countryId: number, languageId: number) => {
  return (await get(
    `${TERMS_ENDPOINT}?CountryId=${countryId}&LanguageId=${languageId}`,
    {},
    basicAuthHeader(PROBE_RESEARCH_CLIENT_ID)
  )) as { Html: string };
};

export const getPrivacyPolicy = async (countryId: number, languageId: number) => {
  return (await get(
    `${PRIVACY_ENDPOINT}?CountryId=${countryId}&LanguageId=${languageId}`,
    {},
    basicAuthHeader(PROBE_RESEARCH_CLIENT_ID)
  )) as { Html: string };
};
