import {
  SurveyProgressBar,
  surveyQuestions,
  useCurrentQuestion,
  useEffectOnQuestionChanged,
  useResponsive
} from 'components';
import { actions, hasExpired, isFirstQuestion, isLastQuestion, questions, useStateSelector } from 'services';
import { Button, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useAutoSyncQuestion, useUploadCurrentQuestion } from '../hooks/useAutoSyncQuestion';
import { useFocusNext } from '../hooks/useFocusNextQuestion';
import { useQuestionError } from '../hooks/useQuestionError';
import { sideNavWidth } from '../layouts/DashboardWrapper';
import { PATH } from '../routes/routes';

const useStyles = makeStyles<Theme, {}>(theme =>
  createStyles({
    buttonsWrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: theme.spacing(2),
      '& > *': {
        width: '100%'
      }
    },
    surveyContainer: {
      display: 'grid',
      gridTemplateColumns: 'auto',
      gridGap: theme.spacing(2),
      gridTemplateRows: '30px auto 1fr 60px',
      alignItems: 'center',
      justifyItems: 'center',
      width: `calc(100% - ${sideNavWidth}px)`,
      padding: theme.spacing(2, 3),
      height: '100%',
      position: 'fixed',
      top: 0,
      left: sideNavWidth,
      [theme.breakpoints.down('lg')]: {
        width: '100%',
        paddingTop: 75,
        padding: theme.spacing(2),
        left: 0
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: 65
      }
    }
  })
);

export const Survey = () => {
  const survey = useStateSelector('survey');
  const currentQuestion = useCurrentQuestion();
  const uploadQuestion = useUploadCurrentQuestion();
  const dispatch = useDispatch();
  const { t: tSurvey } = useTranslation('survey');
  const containerRef = useFocusNext<HTMLFormElement>(currentQuestion);
  const { isValid, message, rowId } = useQuestionError(currentQuestion);
  const [questionError, setQuestionError] = useState('');
  const classes = useStyles({});
  const { push } = useHistory();
  const { isGuest } = useStateSelector('user');
  const { isMobilePortrait } = useResponsive();
  const isExpired = hasExpired(survey);
  const { submitted } = survey;

  useEffect(() => {
    if (isExpired || submitted) {
      push(PATH.INDEX.ROOT);
    }
  }, [isExpired, submitted, push]);

  useEffect(() => {
    if (survey.isDefined && !survey.instructionsRead && !isGuest) {
      push(PATH.INDEX.INSTRUCTIONS);
    }
  }, [survey, push, isGuest, dispatch]);

  useAutoSyncQuestion();
  useEffectOnQuestionChanged(() => {
    setQuestionError('');
  });

  const isNotFirstQuestion = !isFirstQuestion(currentQuestion, survey);

  const previousQuestion = () => {
    dispatch(actions.selectPreviousQuestion());
  };

  const lastQuestion = isLastQuestion(currentQuestion, survey);
  const nextQuestion = () => {
    if (!isValid && message) {
      setQuestionError(message);
      if (rowId) {
        questions.scrollToRowQuestion(rowId);
      }
      return;
    }
    if (lastQuestion) {
      uploadQuestion(currentQuestion);
      push(PATH.INDEX.SUBMIT);
    } else {
      dispatch(actions.selectNextQuestion(currentQuestion));
    }
  };

  const handleNext = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    nextQuestion();
  };

  const { QuestionRenderer } = surveyQuestions;

  return (
    <form onSubmit={handleNext} className={classes.surveyContainer} ref={containerRef}>
      <Typography variant={isMobilePortrait ? 'subtitle2' : 'h6'}>{survey.Title}</Typography>
      <SurveyProgressBar />
      <QuestionRenderer error={questionError} />
      <div className={isNotFirstQuestion ? classes.buttonsWrapper : ''}>
        <Button type="submit" size="large" color="secondary" endIcon={<ArrowForward />} style={{ order: 2 }}>
          {tSurvey('next')}
        </Button>
        {isNotFirstQuestion && (
          <Button
            type="button"
            onClick={previousQuestion}
            size="large"
            color="primary"
            startIcon={<ArrowBack />}
            style={{ order: 1 }}
          >
            {tSurvey('previous')}
          </Button>
        )}
      </div>
    </form>
  );
};
