import { SignInModal } from 'components';
import { LinearProgress } from '@mui/material';
import { useEffect, lazy } from 'react';
import { useHistory, useLocation, Switch, Route } from 'react-router';
import { PATH } from '../routes/routes';
import { WelcomeText } from '../components/WelcomeText';
import { getInstitutionInfo, InstitutionInfo, useStateSelector } from 'services';
import { useState, Suspense } from 'react';
import { Layout } from './Layout';

const TermsOfUseAndPrivacyPolicyText = lazy(() => import('../components/TermsOfUseAndPrivacyPolicyText'));
const TermsOfUseText = lazy(() => import('../components/TermsOfUseText'));

const SignInRegisterWrapper: React.FC = ({ children }) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { countryId, languageId } = useStateSelector('system');
  const [institutionInfo, setInstitutionInfo] = useState<null | InstitutionInfo>(null);

  useEffect(() => {
    (async () => {
      try {
        const institutionInfo = await getInstitutionInfo(countryId, languageId);
        setInstitutionInfo(institutionInfo);
      } catch (e) {
        // console.error(e);
      }
    })();
  }, [countryId, languageId]);

  const handleLocalization = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    push(PATH.INDEX.LOCALIZATION);
  };

  const handleBack = () => {
    push(PATH.INDEX.ROOT);
  };

  const isNotLocalizationRoute = pathname !== PATH.INDEX.LOCALIZATION;
  const isNotIndexRoute = pathname !== PATH.INDEX.ROOT && isNotLocalizationRoute;

  return (
    <Layout>
      <SignInModal
        logoSrc="/assets/images/logo.png"
        onClickLocalization={isNotLocalizationRoute ? handleLocalization : undefined}
        onClickBack={isNotIndexRoute ? handleBack : undefined}
        informationContent={
          <Switch>
            <Route exact path={PATH.INDEX.REGISTER} component={TermsOfUseAndPrivacyPolicyText} />
            <Route exact path={PATH.INDEX.GUEST} component={TermsOfUseText} />
            <Route render={() => <WelcomeText info={institutionInfo} />} />
          </Switch>
        }
      >
        <Suspense fallback={<LinearProgress />}>{children}</Suspense>
      </SignInModal>
    </Layout>
  );
};

export default SignInRegisterWrapper;
