import {
  useStudiesAllStudiesMetaData as useStudiesMetaData,
  useCompositeSharedInstanceAsync,
  useStudiesPastSurveysV1 as useStudiesPastSurveys,
  // useDashboardParticipantStats,
  useCompositeAddSharedInstanceAsync as useGenerateSharedLink,
  useStudiesOnGoingSurveysV1 as useStudiesOnGoingSurveys,
  StudyV1PastSurveyResponse as PastSurveyResponseBase,
  StudyV1ConfigurationResponse,
  StudyV1ParticipantIsLinkedResponse,
  // DashboardV1ParticipantStatsResponse,
  // DashboardV1QuartileData as QuartileDataResponse,
  StudyV1ParticipantCurrentStepResponse,
  StudyV1OnGoingSurveyResponse,
  StudyV1MetaDataResponse,
  useStudyStudyConfigV1 as useStudyConfigWithoutHeader,
  StudyStudyConfigV1QueryParams as StudyConfigParams,
  AccountGetTokenCacheV1QueryParams as TokenCacheParams,
  StudyV1EnrollmentRequest,
  AccountV1RegisterOAuthRequest,
  AccountV1RegisterRequest,
  AccountV1ValidLinkRequest,
  AccountV1ValidLinkResponse,
  useCompositeSendEmail,
  CompositeSurveysClassesAPIRequisite,
  useAccountGetTokenCacheV1 as useAccountGetTokenCacheWithoutHeader,
  AccountControllerCacheToken,
  AccountV1UserInfoResponse
} from './generated';
import { basicAuthHeader } from '../axios-helpers';
import { PROBE_RESEARCH_CLIENT_ID } from '../constants';
import { ProbeRestfulProvider } from './ProbeRestfulProvider';
import { doNormalize } from '../normalizer';
import { useMemo } from 'react';

export type PastSurveyResponse = PastSurveyResponseBase;
export type ParticipantInfo = Required<StudyV1ParticipantIsLinkedResponse>;
export type CacheToken = Required<AccountControllerCacheToken>;
export type Requisite = Required<CompositeSurveysClassesAPIRequisite>;
export type StudyStep = Required<StudyV1ParticipantCurrentStepResponse>;
// export type ParticipantStats = DashboardV1ParticipantStatsResponse;
// export type ParticipantStatsScore = keyof Omit<DashboardV1ParticipantStatsResponse, 'Demographics' | 'SampleSize'>;
// export type QuartileData = Required<QuartileDataResponse>;
export type StudyEnrollmentRequest = StudyV1EnrollmentRequest;
export type RegisterOAuthRequest = AccountV1RegisterOAuthRequest;
export type RegisterRequest = AccountV1RegisterRequest;
export type ValidLinkRequest = AccountV1ValidLinkRequest;
export type ValidLinkResponse = AccountV1ValidLinkResponse;
export type OnGoingSurveyResponse = Required<StudyV1OnGoingSurveyResponse>;
export type UserInfo = Required<AccountV1UserInfoResponse>;

type valueOf<T> = T[keyof T];

type StudyStepStatus = valueOf<Pick<StudyStep, 'Status'>>;

export type SurveyStatus = StudyStepStatus | 'InProgress';

export type StudyMetaData = Required<Omit<StudyV1MetaDataResponse, 'CurrentStep'>> & {
  CurrentStep: StudyStep;
};

export type PastSurvey = Required<
  Omit<PastSurveyResponseBase, 'ProbeScore' | 'EQ5DScore' | 'EQ5DVas' | 'StudyName'>
> & {
  ProbeScore?: number | null;
  EQ5DScore?: number | null;
  EQ5DVas?: number | null;
};

export type StudyConfiguration = Required<Omit<StudyV1ConfigurationResponse, 'ParticipantInfo'>> & {
  ParticipantInfo: ParticipantInfo;
};

export const useInstanceSurvey = ({ guid }: { guid: string }) => {
  const response = useCompositeSharedInstanceAsync({
    queryParams: {
      readOnlyGuid: guid
    },
    requestOptions: {
      headers: basicAuthHeader(PROBE_RESEARCH_CLIENT_ID)
    }
  });
  const { data } = response;

  const survey = useMemo(() => data && doNormalize(data as any), [data]);
  const scores = useMemo(
    () => ({
      ProbeScore: data?.ProbeScore,
      EQ5DScore: data?.EQ5DScore,
      EQ5DVas: data?.EQ5DVas
    }),
    [data]
  );
  return { ...response, data: { survey, scores, participantIdentifier: data?.AssignedParticipantIdentifier } };
};

export const useAccountTokenCache = ({ guid }: TokenCacheParams) =>
  useAccountGetTokenCacheWithoutHeader({
    queryParams: { guid },
    requestOptions: { headers: basicAuthHeader(PROBE_RESEARCH_CLIENT_ID) }
  });

export const useStudyConfig = ({ token }: StudyConfigParams) =>
  useStudyConfigWithoutHeader({
    queryParams: { token },
    requestOptions: { headers: basicAuthHeader(PROBE_RESEARCH_CLIENT_ID) }
  });

export {
  useStudiesMetaData,
  useCompositeSendEmail,
  ProbeRestfulProvider,
  useStudiesPastSurveys,
  useGenerateSharedLink,
  useStudiesOnGoingSurveys
};
