import sortBy from 'lodash/sortBy';
import debounce from 'lodash/debounce';
import { ISurveyEntry, IWIthId } from './types';

export function findById<T extends IWIthId>(entries: T[], id?: number | null): T {
  const found = entries.find((entry: T) => entry.Id === id);
  if (found) {
    return found;
  }
  throw new Error(`Entity with Id ${id} not found`);
}

export function sortByDisplayOrder<T extends ISurveyEntry>(entries: T[]): T[] {
  return sortBy(entries, 'DisplayOrder');
}

export const asDebounced = (func: any) => debounce(func, 500, { leading: true, trailing: false });
