import { Action, applyMiddleware, combineReducers, createStore, Middleware } from 'redux';
import { useSelector } from 'react-redux';
import { ThunkAction } from 'redux-thunk';
import thunk from 'redux-thunk';
import { SystemState, SystemReducer, initialState as initialSystemState } from './System/System.reducer';
import { UserActions } from './User/User.actions';
import { UserReducer, UserState, initialState as initialUserState } from './User/User.reducer';
import { CurrentSurvey, Stats } from '../types';
import { CurrentSurveyReducer, StatsReducer, initialSurveyState, initialStatsState } from '../reducers';
import { SystemActions } from './System/System.actions';
import * as userActions from './User/User.actions';
import * as systemActions from './System/System.actions';
import { createLogger } from 'redux-logger';

import merge from 'lodash/merge';
import dayjs from 'dayjs';
import { composeWithDevTools } from '@redux-devtools/extension';

export type AppState = {
  system: SystemState;
  user: UserState;
  survey: CurrentSurvey;
  stats: Stats;
};

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action<string>>;

export type AppActions = SystemActions | UserActions;

export const rootReducer = combineReducers<AppState>({
  system: SystemReducer,
  user: UserReducer,
  survey: CurrentSurveyReducer,
  stats: StatsReducer
});

export const actions = {
  user: userActions,
  system: systemActions
};

export const useStateSelector = <K extends keyof AppState>(key: K): AppState[K] => {
  return useSelector<AppState, AppState[K]>(s => {
    return s[key];
  });
};

export const useFormattedSurveySpentTime = () => {
  const stats = useStateSelector('stats');
  return dayjs(stats.timeSpent * 1000).format('mm:ss');
};

const getLocalStorageObject = <T extends object>(key: string): T | {} => {
  const jsonString = localStorage.getItem(key);
  return jsonString ? (JSON.parse(jsonString) as T) : {};
};

const [userSurveyLocalStoreKey, localizationLocalStoreKey] = ['user_survey', 'localization'];
const userSurveyState = getLocalStorageObject<Omit<AppState, 'system'>>(userSurveyLocalStoreKey);
const localizationState =
  getLocalStorageObject<Pick<AppState['system'], 'countryId' | 'languageId'>>(localizationLocalStoreKey);

export const initialAppState: AppState = merge(
  {
    system: { ...initialSystemState },
    survey: { ...initialSurveyState },
    user: { ...initialUserState },
    stats: { ...initialStatsState }
  },
  userSurveyState,
  {
    system: localizationState
  }
);

const middleware: Middleware[] = [thunk];

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger();
  middleware.push(logger);
}

const storeEnhancer = applyMiddleware(...middleware);

const store = createStore(
  rootReducer,
  initialAppState,
  process.env.NODE_ENV === 'development' ? composeWithDevTools(storeEnhancer) : storeEnhancer
);

export const cleanUserLocalStoreSurvey = () => localStorage.removeItem(userSurveyLocalStoreKey);

store.subscribe(() => {
  const {
    system: { countryId, languageId },
    user,
    stats,
    survey: { Questions, Sections, Requisites, ...survey }
  } = store.getState();
  if (user.token) {
    localStorage.setItem(userSurveyLocalStoreKey, JSON.stringify({ user, stats, survey }));
  } else {
    cleanUserLocalStoreSurvey();
  }
  localStorage.setItem(localizationLocalStoreKey, JSON.stringify({ countryId, languageId }));
});

export default store;
