import { gradients } from 'theme';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const progressSize = 200;
export const progressSizeMobile = 120;
const cardTopSpacing = 78;
export const cardBoxShadow = '0px 10px 20px rgba(0, 0, 0, 0.14)';

export const useHomeStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      width: '100%',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('lg')]: {
        paddingTop: cardTopSpacing
      }
    },
    rootDashboard: {
      width: '100%',
      minHeight: '100vh',
      [theme.breakpoints.down('lg')]: {
        paddingTop: cardTopSpacing
      }
    },
    embeddedDashboard: {
      width: '100%',
      minHeight: '100vh'
    },
    buttonsWrapper: {
      display: 'grid',
      gridGap: theme.spacing(2),
      gridTemplateColumns: 'auto auto',
      width: 'max-content',
      '& > *': {
        width: '100%'
      },
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
        width: '100%'
      }
    },
    content: {
      width: '100%',
      padding: theme.spacing(2),
      margin: '0 auto',
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(0)
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: 980
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2, 6),
        maxWidth: 1200
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1440
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: 1440
      }
    },
    model: {
      gridArea: '1 / 1 / 2 / 2',
      position: 'relative',
      padding: theme.spacing(4, 2),
      [theme.breakpoints.up('sm')]: {
        marginTop: progressSize / 4.5,
        padding: theme.spacing(4)
      },
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2)
      },
      width: '100%',
      boxShadow: cardBoxShadow,
      overflow: 'inherit',
      '& > * + *': {
        marginTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          marginTop: theme.spacing(1)
        }
      }
    },
    progress: {
      display: 'grid',
      width: progressSize,
      height: progressSize,
      borderRadius: progressSize,
      alignItems: 'center',
      justifyItems: 'center',
      ...gradients.TealLinear,
      boxShadow: cardBoxShadow,
      justifySelf: 'end',
      alignSelf: 'center',
      zIndex: 1,
      '& > *': {
        gridArea: '1 / 1 / 2 / 2',
        color: 'white',
        [theme.breakpoints.down('md')]: {
          ...theme.typography.h4
        }
      },
      [theme.breakpoints.down('md')]: {
        margin: '0 auto',
        marginTop: theme.spacing(2),
        width: progressSizeMobile,
        height: progressSizeMobile
      },
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        right: -progressSize / 5,
        top: '15%',
        transform: 'translateY(-50%)',
        marginTop: 0
      }
      // [theme.breakpoints.down('sm')]: {
      //   position: 'absolute',
      //   width: progressSizeMobile,
      //   height: progressSizeMobile,
      //   top: -progressSizeMobile / 6,
      //   right: -theme.spacing(1),
      //   marginTop: 0
      // },
    },
    progressLabel: {
      [theme.breakpoints.down('md')]: {
        ...theme.typography.h5
      }
    },
    titleSection: {
      [theme.breakpoints.up('sm')]: {
        paddingRight: progressSize / 1.5
      }
    },
    surveyTitle: {
      [theme.breakpoints.down('sm')]: {
        ...theme.typography.h5
      }
    },
    cardTitle: {
      ...theme.typography.h5,
      [theme.breakpoints.down('sm')]: {
        ...theme.typography.subtitle1
      }
    },
    stats: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(1, 3)
      }
    },
    textCenter: {
      textAlign: 'center'
    }
  })
);
