import { FC, ReactNode } from 'react';
import { useTransition, animated as Animated } from '@react-spring/web';

interface Props {
  children: ReactNode;
}

export const Fade: FC<Props> = ({ children }) => {
  const transitions = useTransition(true, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });
  return <>{transitions((style, item) => (item ? <Animated.div style={style}>{children}</Animated.div> : <></>))}</>;
};
