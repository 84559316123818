import { FC } from 'react';

import { ColumnAnswerComponentProps as ColumnAnswerComponentPropsWithAnswerRender, questions } from 'services';
import { ColumnTextAnswer } from './ColumnTextAnswer';
import { ColumnSelectBoxAnswer } from './ColumnSelectBoxAnswer';

export type ColumnAnswerComponentProps = Omit<
  ColumnAnswerComponentPropsWithAnswerRender,
  'answerTableTextQuestion' | 'answerTableFixedQuestion'
>;

const { QuestionTypes } = questions;

export const ColumnAnswerRenderer: FC<ColumnAnswerComponentProps> = props => {
  const { column } = props;
  switch (column.QuestionType.Id) {
    case QuestionTypes.CHECKBOX:
    case QuestionTypes.RADIO:
    case QuestionTypes.TRUE_FALSE:
    case QuestionTypes.DROP_DOWN:
      return <ColumnSelectBoxAnswer {...props} />;
    case QuestionTypes.TEXT:
    case QuestionTypes.TEXT_NUMERIC:
    case QuestionTypes.TEXT_AREA:
      return <ColumnTextAnswer {...props} />;
    default:
      return <></>;
  }
};
