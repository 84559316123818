import { IndexRoutes } from './routes/IndexRoutes';
import { CONFIG } from '../services/constants';
import { Helmet } from 'components';

const Tracker = () => {
  const { trackingId, isProd } = CONFIG;
  if (!isProd || !trackingId) {
    return null;
  }

  return (
    <Helmet>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${trackingId}`} />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${trackingId}');
        `}
      </script>
    </Helmet>
  );
};

export const App = () => {
  return (
    <>
      <Tracker></Tracker>
      <IndexRoutes></IndexRoutes>
    </>
  );
};
