import cryptoJS from 'crypto-js';
import sha256 from 'crypto-js/sha256';

export const btoa = (message: string): string => {
  const wordArray = cryptoJS.enc.Utf8.parse(message);
  return base64(wordArray);
};

export const base64URLEncode = (wordArray: any): string =>
  base64(wordArray).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');

export const generateRandomString = () => {
  const wordArray = cryptoJS.lib.WordArray.random(32);
  return base64URLEncode(wordArray);
};

export const createHashFor = (message: string): string => {
  const wordArray = sha256(message);
  return base64URLEncode(wordArray);
};

const base64 = (wordArray: any): string => cryptoJS.enc.Base64.stringify(wordArray);
