import axios, { AxiosPromise, AxiosRequestConfig, Method } from 'axios';
import { NetworkConnectionError, ServerError } from './models/errors';
import { btoa } from './crypto';
import { APP_FULL_NAME } from './constants';

export const tokenAuthHeader = (token: string) => ({
  Authorization: `Bearer ${token}`
});

export const basicAuthHeader = (username: string, password = '') => ({
  Authorization: `Basic ${btoa(`${username}:${password}`)}`
});

export const appHeader = {
  'x-client-name': APP_FULL_NAME
};

const jsonHeader = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

const noCacheHeader = {
  'Cache-Control': 'no-cache, no-store, must-revalidate',
  Pragma: 'no-cache',
  Expires: 0
};

export const get = <T = any>(
  url: string,
  config?: Record<string, unknown>,
  additionalHeaders?: Record<string, string>,
  addAppHeader = true
) => {
  const headers = {
    ...noCacheHeader,
    ...jsonHeader,
    ...(addAppHeader ? appHeader : undefined),
    ...additionalHeaders
  };

  const axiosConfig = {
    ...config,
    headers
  };

  return request<T>(url, 'get', axiosConfig);
};

export const post = <T = any>(
  url: string,
  data: any,
  additionalHeaders?: Record<string, string>,
  addAppHeader = true
) => dataRequest<T>(url, 'post', data, additionalHeaders, addAppHeader);

export const put = <T = any>(url: string, data: any, additionalHeaders?: Record<string, string>, addAppHeader = true) =>
  dataRequest<T>(url, 'put', data, additionalHeaders, addAppHeader);

export const del = <T = any>(url: string, data: any, additionalHeaders?: Record<string, string>, addAppHeader = true) =>
  dataRequest<T>(url, 'delete', data, additionalHeaders, addAppHeader);

const dataRequest = <T = any>(
  url: string,
  method: Method,
  data: any,
  additionalHeaders: Record<string, string> | undefined,
  addAppHeader: boolean
) => {
  const config = {
    headers: {
      ...jsonHeader,
      ...additionalHeaders,
      ...(addAppHeader ? appHeader : undefined)
    },
    data: JSON.stringify(data)
  };

  return request<T>(url, method, config);
};

const request = <T = any>(url: string, method: Method, config: Record<string, unknown>) => {
  const axiosConfig: AxiosRequestConfig = {
    ...config,
    url,
    method
  };
  return handleError(() => axios.request<T>(axiosConfig));
};

const handleError = async <T = any>(requestPromiseFunc: () => AxiosPromise<T>): Promise<T> => {
  try {
    const res = await requestPromiseFunc();
    return res.data;
  } catch (error: any) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      throw new ServerError(error.response.data, error);
    } else {
      // could not connect to the server, we are offline
      throw new NetworkConnectionError(error);
    }
  }
};

// axios.interceptors.request.use(request => {
//   return request;
// });

// const timeout = ms => new Promise(res => setTimeout(res, ms));
