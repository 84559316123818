export const insertItem = (array: any[], index: number, item: any) => [
  ...array.slice(0, index),
  item,
  ...array.slice(index)
];

export const updateItem = (array: any[], index: number, item: any) => [
  ...array.slice(0, index),
  item,
  ...array.slice(index + 1)
];

export const removeItem = (array: any[], index: number) => [...array.slice(0, index), ...array.slice(index + 1)];

export const addItem = (array: any[], item: any) => [...array, item];
