import { get, post, put, tokenAuthHeader } from './axios-helpers';
import { PROBE_ENDPOINT_BASE, ALTERNATE_TEXT_ID } from './constants';
import { toSerializable } from './serializable-question-mapper';
import { answeredQuestionsInSurvey, visibleQuestionsInSurvey } from './survey-manager';
import { CompositeSurvey, CurrentSurvey, Question, Stats, Token } from './types.js';

const COMPOSITE_ENDPOINT = `${PROBE_ENDPOINT_BASE}api/v1/composite/`;
const SAVE_QUESTIONS = `${COMPOSITE_ENDPOINT}questions/save`;
const SURVEY_TEMP = `${COMPOSITE_ENDPOINT}Instance/TempData`;
const INSTANCE_ID_SURVEY = `${COMPOSITE_ENDPOINT}Instance`;

const STUDY_ENDPOINT = `${PROBE_ENDPOINT_BASE}api/v1/study/`;
const LOAD_SURVEY_STUDY = `${STUDY_ENDPOINT}survey`;
const NEW_SURVEY_STUDY = `${STUDY_ENDPOINT}survey/new`;
const SUBMIT_SURVEY_STUDY = `${STUDY_ENDPOINT}submit`;

export const loadSurveyAsync = (studyId: number, token: Token): Promise<CompositeSurvey> => {
  // try to load an existing survey is one is available. This will return a new one if none was created
  // or throw an exception is the load survey has expired
  const params = { StudyId: studyId, alternateText: ALTERNATE_TEXT_ID };

  return get(LOAD_SURVEY_STUDY, { params }, tokenAuthHeader(token.access_token));
};

export const createNewSurveyAsync = async (studyId: number, token: Token): Promise<CompositeSurvey> => {
  const params = { StudyId: studyId, alternateText: ALTERNATE_TEXT_ID };

  return await get(NEW_SURVEY_STUDY, { params }, tokenAuthHeader(token.access_token));
};

export const loadSurveyUsingInstanceIdAsync = (instanceId: number, token: Token): Promise<CompositeSurvey> =>
  get(
    INSTANCE_ID_SURVEY,
    { params: { instanceId, alternateText: ALTERNATE_TEXT_ID } },
    tokenAuthHeader(token.access_token)
  );

export const submitSurveyAsync = async (
  currentSurvey: CurrentSurvey,
  stats: Stats,
  studyId: number,
  token: Token
): Promise<any> => {
  const { InstanceId } = currentSurvey;
  const questions = visibleQuestionsInSurvey(currentSurvey);
  const answeredQuestions = answeredQuestionsInSurvey(currentSurvey);
  const { timeSpent: completionTimeSecs } = stats;
  const totalQuestions = questions.length;
  const completedQuestions = answeredQuestions.length;

  const metrics = { completionTimeSecs, totalQuestions, completedQuestions };

  const submitParams = {
    InstanceId,
    Metrics: metrics,
    ForceSubmit: false,
    StudyId: studyId
  };

  const forceSubmitParams = {
    ...submitParams,
    ForceSubmit: true
  };
  const authHeader = tokenAuthHeader(token.access_token);
  try {
    await uploadQuestionsAsync(questions, InstanceId, token);
    const { Submitted: submitted } = await post(SUBMIT_SURVEY_STUDY, submitParams, authHeader);
    if (!submitted) {
      await post(SUBMIT_SURVEY_STUDY, forceSubmitParams, authHeader);
    }
  } catch (error) {
    // Error during question upload. Because we are trying to submit the survey,
    // let's force submit there as well
    await post(SUBMIT_SURVEY_STUDY, forceSubmitParams, authHeader);
  }
};

export const uploadQuestionsAsync = (questions: Question[], instanceId: number, token: Token): Promise<boolean> => {
  const data = {
    InstanceId: instanceId,
    Questions: questions.map(toSerializable)
  };

  return put(SAVE_QUESTIONS, { ...data }, tokenAuthHeader(token.access_token));
};

export type SurveyInstanceTemp = Pick<
  CurrentSurvey,
  'currentQuestionId' | 'instructionsRead' | 'submitted' | 'studyId'
> &
  Pick<Stats, 'timeSpent'>;

const tempUrl = (instanceId: number) => `${SURVEY_TEMP}?InstanceId=${instanceId}`;

export const updateSurveyInstanceTempAsync = (data: SurveyInstanceTemp, instanceId: number, token: Token) =>
  put(tempUrl(instanceId), { ...data }, tokenAuthHeader(token.access_token));

export const getSurveyInstanceTempAsync = (instanceId: number, token: Token): Promise<Partial<SurveyInstanceTemp>> =>
  get(SURVEY_TEMP, { params: { InstanceId: instanceId } }, tokenAuthHeader(token.access_token));
