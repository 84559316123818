import { useTranslation } from 'react-i18next';
import { questions, Question } from 'services';

export const useQuestionError = (question: Question) => {
  const { t } = useTranslation();
  const { isValid, error, rowId } = questions.validateQuestion(question);
  if (error) {
    const { args } = error;
    let errorArgs = {};
    if (args) {
      Object.keys(args).forEach((key: string) => {
        // @ts-ignore
        errorArgs = { ...errorArgs, [key]: t(args[key]) };
      });
    }
    return {
      isValid,
      message: t(error.message, errorArgs),
      rowId
    };
  }
  return { isValid };
};
