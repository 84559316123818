import { FC, useState, useEffect } from 'react';
import { FormControl, Checkbox, FormControlLabel, Radio, Typography, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Answer, actions, questions } from 'services';
import { useDispatch } from 'react-redux';
import some from 'lodash/some';
import CheckedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import UncheckedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import { useCurrentQuestion } from '../../hooks';

export const useMultipleAnswersStyles = makeStyles<Theme>(theme =>
  createStyles({
    fieldset: {
      width: '100%'
    },
    activeOption: {
      backgroundColor: `${theme.palette.secondary.main}22`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1, 0),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      paddingRight: theme.spacing(2)
    },
    option: {
      display: 'flex',
      alignItems: 'start'
    },
    label: {
      paddingTop: 11,
      paddingBottom: 11
    }
  })
);

export const MultipleChoiceQuestion: FC = () => {
  const question = useCurrentQuestion();
  const classes = useMultipleAnswersStyles();
  const dispatch = useDispatch();
  const [isAnswered, setAnswered] = useState(false);

  const handleChange = (answer: Answer, selected: boolean) => () => {
    if (!isAnswered) {
      dispatch(actions.answerFixedQuestion(question, answer));
      if (!selected && questions.isSingleAnswer(question)) {
        setAnswered(true);
      }
    }
  };

  useEffect(() => {
    if (!isAnswered) {
      return;
    }
    const nextQuestionTimer = setTimeout(() => {
      setAnswered(false);
      dispatch(actions.selectNextQuestion(question));
    }, 200);
    return () => clearTimeout(nextQuestionTimer);
  }, [question, isAnswered, dispatch]);

  const renderAnswer = (answer: Answer) => {
    const selected = some(question.Result, { AnswerId: answer.Id });

    return (
      <FormControlLabel
        key={answer.Id}
        value={answer.Id.toString()}
        className={`${classes.option} ${selected ? classes.activeOption : ''}`}
        onMouseDown={handleChange(answer, selected)}
        onKeyPress={handleChange(answer, selected)}
        control={
          questions.isSingleAnswer(question) ? (
            <Radio checked={selected} icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} />
          ) : (
            <Checkbox checked={selected} />
          )
        }
        label={
          <Typography variant="body2" className={classes.label}>
            {answer.Value}
          </Typography>
        }
      />
    );
  };

  return (
    <FormControl component="fieldset" className={classes.fieldset}>
      {question.Answers.map(renderAnswer)}
    </FormControl>
  );
};
