import { LanguageId, useStateSelector } from 'services';
import { Typography } from '@mui/material';
import { WidgetCard } from 'components';

const Disclaimer = () => {
  const { languageId } = useStateSelector('system');

  if (languageId === LanguageId.EN) {
    return null;
  }

  return (
    <WidgetCard>
      <Typography>
        Welcome to the beta version of the dashboard. For now, this is only available in English, but we wanted to make
        it available to everyone. We will translate it after the beta-testing.
      </Typography>
    </WidgetCard>
  );
};

export default Disclaimer;
