import { Space, Stack } from 'components';
import {
  actions,
  firstUnansweredQuestionInSurvey,
  numberOfDaysLeftToCompleteSurvey,
  questions,
  useStateSelector
} from 'services';
import { Button, Card, Divider, Typography } from '@mui/material';
import ArrowForward from '@mui/icons-material/ArrowForward';
import CalenderIcon from '@mui/icons-material/InsertInvitationOutlined';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { PATH } from '../../routes/routes';
import { useHomeStyles } from '../../styles/Home.style';
import { useAsyncDispatch } from '../../hooks/useAsyncDispatch';
import { SurveyStats } from './SurveyStats';
import StaticCircularProgress from './StaticCircularProgress';

export const ContinueSurvey = () => {
  const { t: tDashboard } = useTranslation('dashboard');
  const { isGuest } = useStateSelector('user');
  const { push } = useHistory();
  const classes = useHomeStyles();
  const dispatch = useAsyncDispatch();

  const survey = useStateSelector('survey');
  const totalDays = numberOfDaysLeftToCompleteSurvey(survey);
  const currentStats = questions.currentQuestionsStats(survey);
  const percentCheck = Math.ceil((currentStats.done / currentStats.outOf) * 100);

  const percent = isNaN(percentCheck) ? 0 : percentCheck;

  const changeCurrentQuestionToFirstUnanswered = useCallback(() => {
    const firstQuestion = firstUnansweredQuestionInSurvey(survey);
    if (firstQuestion) {
      dispatch(actions.updateCurrentQuestion(firstQuestion.Id));
    }
  }, [survey, dispatch]);

  const gotoSurvey = useCallback(() => {
    push(PATH.SURVEY.ROOT);
  }, [push]);

  const handleFirstUnansweredQuestion = useCallback(() => {
    changeCurrentQuestionToFirstUnanswered();
    gotoSurvey();
  }, [changeCurrentQuestionToFirstUnanswered, gotoSurvey]);

  const handleContinueSurvey = useCallback(() => {
    if (!survey.currentQuestionId || survey.currentQuestionId < 1) {
      changeCurrentQuestionToFirstUnanswered();
    }
    gotoSurvey();
  }, [changeCurrentQuestionToFirstUnanswered, gotoSurvey, survey.currentQuestionId]);

  useEffect(() => {
    if (isGuest) {
      gotoSurvey();
    }
  }, [isGuest, gotoSurvey]);

  return (
    <Card className={classes.model}>
      <Stack gap={2} className={classes.titleSection}>
        <Typography variant="h3" className={classes.surveyTitle}>
          {tDashboard('surveyOnGoing')}
        </Typography>
        <Stack variant="horizontal" gap={2}>
          <CalenderIcon color="primary" />
          <Typography variant="subtitle1">
            {tDashboard('daysLeftToCompleteSurvey', {
              count: totalDays,
              name: survey.Title
            })}
          </Typography>
        </Stack>
      </Stack>
      <StaticCircularProgress percent={percent} />
      <Divider />
      <SurveyStats />
      <Space gap={2} />
      <div className={classes.buttonsWrapper}>
        <Button color="secondary" size="large" onClick={handleContinueSurvey} startIcon={<ArrowForward />}>
          {tDashboard('gotoNextQuestion')}
        </Button>
        <Button color="primary" size="large" onClick={handleFirstUnansweredQuestion}>
          {tDashboard('gotoFirstUnansweredQuestion')}
        </Button>
      </div>
    </Card>
  );
};
