import { useHomeStyles } from '../styles/Home.style';

interface ContentProps {
  rootClass?: string;
}

const Content: React.FC<ContentProps> = ({ children, rootClass }) => {
  const classes = useHomeStyles();
  return (
    <div className={rootClass ?? classes.root}>
      <div data-testid="home-page" className={classes.content}>
        {children}
      </div>
    </div>
  );
};

export default Content;
