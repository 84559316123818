import { useMemo } from 'react';
import {
  QuestionComponent,
  ColumnQuestionComponent,
  ReadOnlyMultipleChoiceAnswer,
  ReadOnlyTextAnswer,
  NoAnswerComponent
} from './ReadOnlyAnswerRender';
import { questions } from 'services';
import { Stack } from '../Stack/Stack';
import { Typography, Table, TableBody, TableRow, TableCell, TableHead, Box } from '@mui/material';
import { currentValueFor } from '../SurveyQuestions/TableQuestion/EQ5DVASQuestion';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const { QuestionTypes } = questions;

export const ReadOnlyColumnAnswerRenderer: ColumnQuestionComponent = ({ question }) => {
  switch (question.QuestionType.Id) {
    case QuestionTypes.CHECKBOX:
    case QuestionTypes.RADIO:
    case QuestionTypes.TRUE_FALSE:
    case QuestionTypes.DROP_DOWN:
      return <ReadOnlyMultipleChoiceAnswer question={question} />;

    case QuestionTypes.TEXT:
    case QuestionTypes.TEXT_NUMERIC:
    case QuestionTypes.TEXT_AREA:
      return <ReadOnlyTextAnswer question={question} />;

    default:
      return <></>;
  }
};

export const ReadOnlyFixedTableAnswer: QuestionComponent = ({ question }) => {
  const table = questions.tableFrom(question);

  return (
    <Stack variant="vertical" gap={2}>
      {table.Rows.map(row => (
        <Stack variant="horizontal" key={row.Id} gap={1}>
          {row.Columns.map(column => (
            <ReadOnlyColumnAnswerRenderer key={column.Id} question={column} />
          ))}
        </Stack>
      ))}
    </Stack>
  );
};

export const ReadOnlyFixedTableAnswerWithHeader: QuestionComponent = ({ question }) => {
  const table = questions.tableFrom(question);
  const header = useMemo(
    () =>
      table.Rows[0] && (
        <TableRow>
          {table.Rows[0].Columns.map(column => (
            <TableCell key={column.Id}>{column.Header}</TableCell>
          ))}
        </TableRow>
      ),
    [table]
  );

  if (!table.Rows.length) {
    return <NoAnswerComponent />;
  }

  return (
    <Table>
      <TableHead>{header}</TableHead>
      <TableBody>
        {table.Rows.map(row => (
          <TableRow key={row.Id}>
            {row.Columns.map(column => (
              <TableCell key={column.Id}>
                <ReadOnlyColumnAnswerRenderer key={column.Id} question={column} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export const useStyles = makeStyles<Theme>(theme =>
  createStyles({ strong: { fontWeight: 500, paddingRight: theme.spacing(1) } })
);

export const ReadOnlyMultipleOptionTableAnswer: QuestionComponent = ({ question }) => {
  const classes = useStyles();
  const table = questions.tableFrom(question);
  return (
    <Box>
      {table.Rows.map(row => {
        const rowAnswers = row.Columns.filter(column => column.Result.length);
        return (
          <Box key={row.Id} display="flex" alignItems="center" pb={2}>
            <Typography variant="body2" classes={{ root: classes.strong }}>
              {row.Header}:{' '}
            </Typography>
            {rowAnswers.length ? (
              rowAnswers.map(column => <ReadOnlyColumnAnswerRenderer key={column.Id} question={column} />)
            ) : (
              <NoAnswerComponent />
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export const ReadOnlyEQ5DAnswer: QuestionComponent = ({ question }) => {
  const { Supplements } = question;
  const healthToday = Supplements?.Localizations?.yourHealth;
  const value = currentValueFor(question);
  return <Box display="inline">{healthToday ? `${healthToday}: ${value}` : <NoAnswerComponent />}</Box>;
};
