import createStyles from '@mui/styles/createStyles';

export const gradients = createStyles({
  TealLinear: {
    background: 'linear-gradient(168deg, #1f7f95 8%, #36d6e7 96%)'
  },
  TealBlueLinear: {
    background: 'linear-gradient(144deg, #1f7f95 0%, #36d6e7 100%)'
  },
  TealTransparentBackground: {
    background: 'linear-gradient(237.62deg, rgba(255, 255, 255, 0) 6.67%, #36d6e7 90.71%), #FFFFFF;'
  },
  IconGradient: {
    background: 'linear-gradient(119.49deg, #1f7f95 36.79%, #36d6e7 100%);'
  }
});
