import produce from 'immer';
import findIndex from 'lodash/findIndex';
import each from 'lodash/each';
import cloneDeep from 'lodash/cloneDeep';
import indexOf from 'lodash/indexOf';
import {
  ADD_ROW_TO_REPEATING_TABLE_QUESTION,
  ANSWER_MULTIPLE_ANSWER_PER_ROW_TABLE_QUESTION,
  ANSWER_MULTIPLE_ANSWER_QUESTION,
  ANSWER_SINGLE_ANSWER_PER_ROW_TABLE_QUESTION,
  ANSWER_SINGLE_ANSWER_QUESTION,
  ANSWER_TEXT_QUESTION,
  ANSWER_TEXT_TABLE_QUESTION,
  CLEAR_QUESTION_ERROR,
  REMOVE_ROW_FROM_REPEATING_TABLE_QUESTION,
  SELECT_NEXT_QUESTION,
  SELECT_PREVIOUS_QUESTION,
  SET_SURVEY_EXPIRED,
  SHOW_QUESTION_ERROR,
  SURVEY_INSTRUCTIONS_READ,
  UNANSWER_SINGLE_ANSWER_PER_ROW_TABLE_QUESTION,
  UNANSWER_SINGLE_ANSWER_QUESTION,
  UPDATE_CURRENT_QUESTION
} from '../actions/current-survey';
import { LOADING_SURVEY_SUCCESS, SUBMIT_SURVEY_SUCCESS, UPDATE_TEMP_DATA } from '../actions/survey';
import { findById } from '../common';
import { REGIMEN_HISTORY_ID } from '../constants';
import { QuestionTypes } from '../models';
import { nextAvailableDynamicProps, tableFrom } from '../questions';
import { SIGN_OUT } from '../store/User/User.actions';
import { nextVisibleQuestion, previousVisibleQuestion } from '../survey-manager';
import { now } from '../time-helpers';
import {
  Answer,
  Column,
  ColumnWrapper,
  CurrentSurvey,
  IAnswerable,
  NormalizedSurvey,
  Question,
  Result,
  Row,
  State
} from '../types';
import { addItem, removeItem } from './helpers';
import { Reducer } from 'redux';

export const initialState: CurrentSurvey = {
  isDefined: false,
  submitted: false,
  Instructions: '',
  LanguageCode: '',
  Title: '',
  instructionsRead: false,
  Sections: [],
  Questions: [],
  Requisites: {},
  currentQuestionId: -1,
  ProjectId: -1,
  InstanceId: -1,
  Id: -1,
  studyId: -1,
  CreatedOn: now(),
  ExpiresOn: now(),
  error: null
};

const retrieveNextQuestionId = (currentSurvey: CurrentSurvey): number => nextVisibleQuestion(currentSurvey).Id;

const retrievePreviousQuestionId = (currentSurvey: CurrentSurvey): number => previousVisibleQuestion(currentSurvey).Id;

const updateSingleAnswerResultFor = (questionable: IAnswerable, answer: Answer, isTable: boolean): Result[] => {
  const multiple = false;
  // Drop down in non table behaves like radio button for now
  const removeAnswerIfAlreadyPartOfResult = !(isTable && questionable.QuestionType.Id === QuestionTypes.DROP_DOWN);
  return updatedResultsFor(questionable, answer, multiple, removeAnswerIfAlreadyPartOfResult);
};

const updateMultipleAnswerResultFor = (questionable: IAnswerable, answer: Answer): Result[] => {
  const multiple = true;
  return updatedResultsFor(questionable, answer, multiple);
};

const updatedResultsFor = (
  questionable: IAnswerable,
  answer: Answer,
  multiple: boolean,
  removeAnswerIfAlreadyPartOfResult = true
): Result[] => {
  const answerResult = { AnswerId: answer.Id };
  const currentResults = questionable.Result;
  const answerIndex = findIndex(currentResults, answerResult);
  // Answer already exists in results and we should remove the answer if it was already there
  if (answerIndex >= 0 && removeAnswerIfAlreadyPartOfResult) {
    return multiple ? removeItem(currentResults, answerIndex) : [];
  }

  // Answer was not present or should not be removed
  return multiple ? addItem(currentResults, answerResult) : [answerResult];
};

const draftQuestionIn = (draft: CurrentSurvey, question: Question): Question => findById(draft.Questions, question.Id);

const draftRowIn = (draft: CurrentSurvey, question: Question, row: Row): Row => {
  const draftQuestion = draftQuestionIn(draft, question);
  const table = tableFrom(draftQuestion);
  return findById(table.Rows, row.Id);
};

const draftColIn = (draft: CurrentSurvey, columnWrapper: ColumnWrapper): { draftRow: Row; draftCol: Column } => {
  const { question, column, row } = columnWrapper;
  const draftRow = draftRowIn(draft, question, row);
  const draftCol = findById(draftRow.Columns, column.Id);
  return { draftRow, draftCol };
};

const clearAllResultsIn = (draftRow: Row): void => {
  each(draftRow.Columns, col => {
    col.Result = [];
  });
};

const clearError = (draft: CurrentSurvey): void => {
  draft.error = null;
};

const newRepeatingRowBasedOn = (row: Row, questionId: number): Row => {
  const newRow = cloneDeep(row);
  clearAllResultsIn(newRow);
  // special treatment for regiment history
  if (questionId === REGIMEN_HISTORY_ID) {
    // copy results : To into from
    newRow.Columns[0].Result = cloneDeep(row.Columns[1].Result);
  }
  return newRow;
};

const reducer: Reducer<CurrentSurvey> = (state = initialState, action: any): CurrentSurvey =>
  produce(state, (draft: CurrentSurvey) => {
    switch (action.type) {
      case ANSWER_SINGLE_ANSWER_QUESTION: {
        const { question, answer } = action;
        const draftQuestion = draftQuestionIn(draft, question);
        draftQuestion.Result = updateSingleAnswerResultFor(draftQuestion, answer, false);
        clearError(draft);
        return;
      }
      case UNANSWER_SINGLE_ANSWER_QUESTION: {
        const { question } = action;
        const draftQuestion = draftQuestionIn(draft, question);
        draftQuestion.Result = [];
        clearError(draft);
        return;
      }
      case ANSWER_MULTIPLE_ANSWER_QUESTION: {
        const { question, answer } = action;
        const draftQuestion = draftQuestionIn(draft, question);
        draftQuestion.Result = updateMultipleAnswerResultFor(draftQuestion, answer);
        clearError(draft);
        return;
      }
      case ANSWER_TEXT_QUESTION: {
        const { question, text } = action;
        const draftQuestion = draftQuestionIn(draft, question);
        draftQuestion.Result = [{ InputText: text }];
        clearError(draft);
        return;
      }
      case ANSWER_TEXT_TABLE_QUESTION: {
        const { columnWrapper, text } = action;
        const { draftCol } = draftColIn(draft, columnWrapper);
        draftCol.Result = [{ InputText: text }];
        clearError(draft);
        return;
      }
      case ANSWER_SINGLE_ANSWER_PER_ROW_TABLE_QUESTION: {
        const { columnWrapper, answer } = action;
        const { draftRow, draftCol } = draftColIn(draft, columnWrapper);
        // save result before clearing all rows
        const result = updateSingleAnswerResultFor(draftCol, answer, true);
        // Single answer table have multiple columns per row, but only one row should have an answer
        clearAllResultsIn(draftRow);
        draftCol.Result = result;
        clearError(draft);
        return;
      }
      case UNANSWER_SINGLE_ANSWER_PER_ROW_TABLE_QUESTION: {
        const { columnWrapper } = action;
        const { draftRow } = draftColIn(draft, columnWrapper);
        clearAllResultsIn(draftRow);
        clearError(draft);
        return;
      }
      case ANSWER_MULTIPLE_ANSWER_PER_ROW_TABLE_QUESTION: {
        const { columnWrapper, answer } = action;
        const { draftCol } = draftColIn(draft, columnWrapper);
        // Multiple answer per row but still only one answer per column allowed
        draftCol.Result = updateSingleAnswerResultFor(draftCol, answer, true);
        clearError(draft);
        return;
      }
      case ADD_ROW_TO_REPEATING_TABLE_QUESTION: {
        const { question, currentRow }: { question: Question; currentRow: Row } = action;
        const draftQuestion = draftQuestionIn(draft, question);
        const draftTable = tableFrom(draftQuestion);
        const newRow = newRepeatingRowBasedOn(currentRow, question.Id);
        [newRow.Id, newRow.DisplayOrder] = nextAvailableDynamicProps(draftTable.Rows);
        draftTable.Rows.push(newRow);
        clearError(draft);
        return;
      }
      case REMOVE_ROW_FROM_REPEATING_TABLE_QUESTION: {
        const { question, rowToRemove } = action;
        const draftQuestion = draftQuestionIn(draft, question);
        const draftTable = tableFrom(draftQuestion);
        const draftRow = draftRowIn(draft, question, rowToRemove);
        draftTable.Rows = removeItem(draftTable.Rows, indexOf(draftTable.Rows, draftRow));
        clearError(draft);
        return;
      }
      case UPDATE_CURRENT_QUESTION: {
        const { questionId } = action;
        draft.currentQuestionId = questionId;
        clearError(draft);
        return;
      }
      case SHOW_QUESTION_ERROR: {
        draft.error = action.error;
        return;
      }
      case CLEAR_QUESTION_ERROR: {
        clearError(draft);
        return;
      }
      case SELECT_NEXT_QUESTION: {
        draft.currentQuestionId = retrieveNextQuestionId(draft);
        clearError(draft);
        return;
      }
      case SELECT_PREVIOUS_QUESTION: {
        draft.currentQuestionId = retrievePreviousQuestionId(draft);
        clearError(draft);
        return;
      }
      case SURVEY_INSTRUCTIONS_READ: {
        draft.instructionsRead = true;
        return;
      }
      case LOADING_SURVEY_SUCCESS: {
        const { normalizedSurvey, studyId }: { normalizedSurvey: NormalizedSurvey; studyId: number } = action;
        const { Questions, Instructions } = normalizedSurvey;
        const forceInstructionRead = requiresNoInstructions(Instructions);
        const { instructionsRead, currentQuestionId } = state;
        return {
          ...normalizedSurvey,
          currentQuestionId: currentQuestionId === -1 ? Questions[0].Id : currentQuestionId,
          isDefined: true,
          instructionsRead: forceInstructionRead || instructionsRead,
          submitted: false,
          studyId,
          error: null
        };
      }
      case UPDATE_TEMP_DATA: {
        const { tempData } = action;

        const { instructionsRead, currentQuestionId, studyId } = tempData;
        //we only update the temp data if they are actually set
        draft.instructionsRead = instructionsRead ?? draft.instructionsRead;
        draft.currentQuestionId = currentQuestionId ?? draft.currentQuestionId;
        //if neither studyId nor draft are defined, this is an old PROBE Study. We need to update
        draft.studyId = studyId ?? draft.studyId;
        return;
      }
      case SET_SURVEY_EXPIRED:
        draft.ExpiresOn = action.expiryOn;
        return;
      case SUBMIT_SURVEY_SUCCESS:
        return {
          ...initialState,
          InstanceId: draft.InstanceId,
          submitted: true
        };
      case SIGN_OUT:
        return { ...initialState };
    }
  });

export default reducer;

export const currentSurveySelector = (state: State): CurrentSurvey => state.currentSurvey;

function requiresNoInstructions(instructions: string): boolean {
  return (
    instructions === null ||
    instructions === undefined ||
    instructions === '' ||
    instructions === 'Instructions' ||
    instructions === 'instructions'
  );
}
