import { FC, ReactNode } from 'react';
import { Theme, BoxProps, Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export interface StackProps extends BoxProps {
  variant?: 'vertical' | 'horizontal';
  gap?: number;
  children: ReactNode;
}

const useStyles = makeStyles<Theme, StackProps>(theme =>
  createStyles({
    wrapper: {
      display: 'inline-grid',
      gridAutoFlow: ({ variant }) => (variant === 'horizontal' ? 'column' : 'row'),
      gridGap: ({ gap }) => theme.spacing(gap || 2)
    },
    horizontalGap: {
      '& > *:last-child': {
        marginRight: 'unset'
      },
      '& > *': {
        marginRight: ({ gap }) => (gap ? theme.spacing(gap) : 'unset')
      }
    }
  })
);

export const Stack: FC<StackProps> = props => {
  const { children, variant, gap, className, ...BoxProps } = props;
  const classes = useStyles(props);
  return variant !== 'horizontal' ? (
    <Box className={[classes.wrapper, className].join(' ')} {...BoxProps}>
      {children}
    </Box>
  ) : (
    <Box display="flex" className={[classes.horizontalGap, className].join(' ')} {...BoxProps}>
      {children}
    </Box>
  );
};
