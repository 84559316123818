import { extractErrorMessage } from '../error';

export class ExtendableError extends Error {
  constructor(message: string, innerError: Error | null) {
    super();
    this.message = this.createMessage(message, innerError);
    this.stack = new Error().stack;
    this.name = this.constructor.name;
  }

  createMessage = (message: string, innerError: Error | null) => {
    if (!innerError) {
      return message;
    }

    const { message: innerMessage } = innerError;
    if (innerMessage !== null && innerMessage !== message) {
      return `${message}\n${innerMessage}`;
    }

    return message;
  };
}

export class NetworkConnectionError extends ExtendableError {
  isNetworkError: boolean;

  constructor(innerError: Error | null) {
    super('Network Error', innerError);
    this.isNetworkError = true;
  }
}

export class ServerError extends ExtendableError {
  data: any;
  constructor(data: any, innerError: Error | null) {
    const message = extractErrorMessage(data);
    super(message, innerError);
    this.data = data;
    this.message = message;
  }
}
