import TextField, { StandardTextFieldProps } from '@mui/material/TextField';

// import useForm from 'react-hook-form';

//TODO formRef: ReturnType<typeof useForm>;
export type InputProps<Form = Record<string, any>> = StandardTextFieldProps & {
  name: keyof Form;
  label: string;
  placeholder?: string;
  formRef: any;
};

export const TextInput = <Form extends {}>(props: InputProps<Form>) => {
  const { formRef, ...inputProps } = props;

  const { register, errors, formState } = formRef;
  const name = inputProps.name;

  const error = errors[name];
  const message = error && error.message ? error.message : '';

  return (
    <TextField
      className={formState.isValid ? 'success' : ''}
      helperText={message}
      error={!!message}
      disabled={formState.isSubmitting}
      fullWidth
      inputRef={register}
      {...inputProps}
      inputProps={{
        'aria-label': inputProps.label,
        ...props.inputProps
      }}
      name={name}
    />
  );
};
