export const QuestionTypes = {
  CHECKBOX: 1,
  DROP_DOWN: 2,
  RADIO: 3,
  TEXT_AREA: 4,
  TEXT: 5,
  TEXT_NUMERIC: 6,
  TRUE_FALSE: 7,
  TABLE: 8,
  DISPLAY_ONLY: 9
};

export const TableTypes = {
  FIXED: 1,
  REPEAT_ROW: 2,
  RADIO: 3,
  CHECKBOX: 4
};
