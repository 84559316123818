import { useState, ChangeEventHandler, FC } from 'react';
import { Swipe } from '../Transitions/Swipe';
import { Stack } from '../Stack/Stack';
import { useTranslation } from 'react-i18next';

import PersonAddOutlined from '@mui/icons-material/PersonAddOutlined';
import CheckBoxOutlineBlankOutlined from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlined from '@mui/icons-material/CheckBoxOutlined';

import { Typography, Link, FormControlLabel, Checkbox, Fab } from '@mui/material';
import { CountrySelection } from '../CountrySelection/CountrySelection';
import { LanguageSelection } from '../LanguageSelection/LanguageSelection';
import { object, string, ref } from 'yup';
import useForm from 'react-hook-form';
import { TextInput } from '../Form/TextInput';
import { PasswordInput } from '../Form/PasswordInput';
import { auth, Token, UserInfo, useStateSelector } from 'services';
import { useNotification } from '../Notification/Notification';
import { MIN_PASSWORD_LENGTH } from 'services/constants';

interface RegisterProps {
  onRegisterSuccess: (token: Token, userInfo: UserInfo) => void;
  gotoSignIn: () => void;
}

interface RegisterFormProps {
  username: string;
  password: string;
  confirmPassword: string;
}

export const RegisterForm: FC<RegisterProps> = ({ onRegisterSuccess, gotoSignIn }) => {
  const { t: tAccount } = useTranslation('account');
  const { t: tError } = useTranslation('errors');
  const { t: tCommon } = useTranslation('common');
  const { countryId, languageId, studyGuid } = useStateSelector('system');

  const [formToggles, setFormToggles] = useState<{
    agreed: boolean;
    submittingProcess: boolean;
  }>({
    agreed: false,
    submittingProcess: false
  });
  const { notify } = useNotification();

  const required = tCommon('required');

  const form = useForm<RegisterFormProps>({
    mode: 'onBlur',
    validationSchema: object<RegisterFormProps>({
      username: string().required(required),
      password: string()
        .required(required)
        .min(MIN_PASSWORD_LENGTH, tError('passwordTooShort', { length: MIN_PASSWORD_LENGTH })),
      confirmPassword: string()
        .oneOf([ref('password'), undefined], tError('repeatPasswordDoesNotMatch'))
        .required(required)
    })
  });

  const RegisterFormHandle = async ({ username, password }: RegisterFormProps) => {
    setFormToggles(toggles => ({ ...toggles, submittingProcess: true }));
    try {
      await auth.registerProbeUserAsync({
        CountryId: countryId,
        LanguageId: languageId,
        UserName: username,
        Password: password,
        StudyParticipantGuid: studyGuid
      });
      const { token, userInfo } = await auth.signInWithUsernameAndPasswordAsync(username, password);
      onRegisterSuccess(token, userInfo);
    } catch (error: any) {
      notify({
        type: 'error',
        message: tError(error.message)
      });
    } finally {
      setFormToggles(toggles => ({ ...toggles, submittingProcess: false }));
    }
  };

  const handleAgreeCheckbox: ChangeEventHandler<HTMLInputElement> = () => {
    setFormToggles(toggles => ({ ...toggles, agreed: !toggles.agreed }));
  };

  return (
    <Swipe direction="left">
      <form onSubmit={form.handleSubmit(RegisterFormHandle)}>
        <Stack gap={3} width="100%">
          <Stack variant="horizontal" alignItems="center" gap={2}>
            <PersonAddOutlined color="primary" fontSize="large" />
            <div>
              <Typography variant="h5">{tAccount('signUp')}</Typography>
              <Typography variant="caption">{tAccount('useProbeAccount')}</Typography>
            </div>
          </Stack>
          <div>
            <Typography variant="body2">
              {tAccount('alreadyHaveAnAccount')} <Link onClick={gotoSignIn}>{tAccount('signIn')}</Link>
            </Typography>
          </div>
          <Stack gap={2}>
            <CountrySelection fullWidth />
            <LanguageSelection fullWidth />
            <TextInput<RegisterFormProps>
              name="username"
              formRef={form}
              label={tAccount('usernameOrEmail')}
              inputProps={{ 'data-testid': 'username' }}
            />
            <PasswordInput<RegisterFormProps>
              name="password"
              formRef={form}
              label={tAccount('password')}
              inputProps={{ 'data-testid': 'password' }}
            />
            <PasswordInput<RegisterFormProps>
              name="confirmPassword"
              formRef={form}
              label={tAccount('repeatPassword')}
              inputProps={{ 'data-testid': 'confirmPassword' }}
            />
          </Stack>
          <FormControlLabel
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankOutlined color="secondary" />}
                checkedIcon={<CheckBoxOutlined color="secondary" />}
                checked={formToggles.agreed}
                onChange={handleAgreeCheckbox}
              />
            }
            label={<Typography variant="body2">{tAccount('probeAcceptTermOfUseAgreementAndPrivacyPolicy')}</Typography>}
          />
          <Fab
            variant="extended"
            type="submit"
            color="primary"
            style={{ width: 'max-content' }}
            disabled={!formToggles.agreed || formToggles.submittingProcess}
          >
            <PersonAddOutlined />
            {tAccount('signUp')}
          </Fab>
        </Stack>
      </form>
    </Swipe>
  );
};
