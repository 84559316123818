import dayjs from 'dayjs';
import { Stats, State } from '../types';
import { now } from '../time-helpers';
import { TIME_DELTA_THRESHOLD_IN_SECONDS } from '../constants';

import {
  ANSWER_MULTIPLE_ANSWER_QUESTION,
  ANSWER_MULTIPLE_ANSWER_PER_ROW_TABLE_QUESTION,
  ANSWER_SINGLE_ANSWER_QUESTION,
  ANSWER_SINGLE_ANSWER_PER_ROW_TABLE_QUESTION,
  ANSWER_TEXT_QUESTION,
  ANSWER_TEXT_TABLE_QUESTION
} from '../actions/current-survey';

import { LOADING_SURVEY_SUCCESS, UPDATE_TEMP_DATA } from '../actions';
import { Reducer } from 'redux';

const createInitialState = (): Stats => ({
  timeSpent: 0,
  lastSaveDate: now()
});

export const initialState: Stats = createInitialState();

const calculateTimeSpentSinceLastSave = (lastSaveDate: string | Date, newSaveDate: Date): number => {
  const diffInSecond = dayjs(newSaveDate).diff(lastSaveDate, 's');
  // Time difference of more than
  if (diffInSecond > TIME_DELTA_THRESHOLD_IN_SECONDS) {
    return 0;
  }

  return diffInSecond;
};

const statsReducer: Reducer<Stats> = (state: Stats = initialState, action: any) => {
  switch (action.type) {
    case ANSWER_MULTIPLE_ANSWER_QUESTION:
    case ANSWER_MULTIPLE_ANSWER_PER_ROW_TABLE_QUESTION:
    case ANSWER_SINGLE_ANSWER_QUESTION:
    case ANSWER_SINGLE_ANSWER_PER_ROW_TABLE_QUESTION:
    case ANSWER_TEXT_QUESTION:
    case ANSWER_TEXT_TABLE_QUESTION: {
      const { timeSpent, lastSaveDate } = state;
      const newSaveDate = now();
      const timeSpentDelta = calculateTimeSpentSinceLastSave(lastSaveDate, newSaveDate);
      return {
        ...state,
        timeSpent: timeSpent + timeSpentDelta,
        lastSaveDate: newSaveDate
      };
    }
    case UPDATE_TEMP_DATA: {
      const { tempData } = action;
      const { timeSpent } = tempData;
      return { ...state, timeSpent: timeSpent || 0 };
    }
    case LOADING_SURVEY_SUCCESS:
    default:
      return state;
  }
};

export default statsReducer;

export const statsSelector = (state: State): Stats => state.stats;
