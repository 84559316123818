import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { store, useStudyConfig, StudyConfiguration } from 'services';
import { PATH } from '../routes/routes';
import { LoadingBar, useHandler, useNotification } from 'components';
import { useTranslation } from 'react-i18next';
import { useAsyncDispatch } from '../hooks/useAsyncDispatch';

export const StartStudyHandler = () => {
  const { guid } = useParams() as { guid: string };
  const dispatch = useAsyncDispatch();
  const { push } = useHistory();
  const {
    data: dataStudyStatus,
    loading: loadingIsLinked,
    cancel: cancelStudyStatus
  } = useStudyConfig({
    token: guid
  });
  const { notify } = useNotification();
  const { t: tError } = useTranslation('errors');

  const loadStudyFromLink = useHandler(async (studyConfiguration: StudyConfiguration) => {
    const participantInfo = studyConfiguration.ParticipantInfo;

    const { startStudyFromLink, resumeFromStudyLink } = store.actions.system;
    try {
      //This is the first time that the link was started
      if (participantInfo.FirstLogin) {
        await dispatch(startStudyFromLink(guid, studyConfiguration));
        push(PATH.INDEX.ROOT);
        return;
      }

      //In this case, the study was already started . We need to setup the config and if possible navigate to the dashboard
      await dispatch(resumeFromStudyLink(guid, studyConfiguration));
      push(PATH.INDEX.ROOT);
    } catch (error: any) {
      notify({ message: tError(error.message), type: 'error' });
      push(PATH.INDEX.ROOT);
    }
  });

  useEffect(() => {
    if (loadingIsLinked || !dataStudyStatus) {
      return;
    }

    loadStudyFromLink(dataStudyStatus as StudyConfiguration);

    return () => {
      cancelStudyStatus();
    };
  }, [loadingIsLinked, dataStudyStatus, cancelStudyStatus, loadStudyFromLink]);

  return <LoadingBar show={true} />;
};
