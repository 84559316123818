import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { AppState } from 'services/store';

export type ReduxDispatch = ThunkDispatch<AppState, {}, Action>;

export const useAsyncDispatch = (): ReduxDispatch => {
  return useDispatch<ReduxDispatch>();
};
