import { actions, Question, questions } from 'services';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useCurrentQuestion } from '../../hooks';
import { RangeSelector } from '../../RangeSelector/RangeSelector';
import { FC } from 'react';

export const currentValueFor = (question: Question): number | null => {
  const column = questions.answerColumnForEQ5D(question);
  const value = Number(questions.answerTextFor(column));
  if (Number.isNaN(value)) {
    return null;
  }
  return value;
};

const useStyle = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center'
    },
    health: {
      width: 160,
      textAlign: 'center',
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(2),
      color: theme.palette.primary.contrastText,
      margin: 0
    },
    healthTitle: {
      ...theme.typography.body1,
      paddingBottom: 0,
      [theme.breakpoints.down('md')]: {
        ...theme.typography.body2
      }
    },
    healthValue: {
      ...theme.typography.h3,
      paddingTop: 0,
      [theme.breakpoints.down('md')]: {
        ...theme.typography.h4
      }
    }
  })
);

export const EQ5DVASQuestion: FC<{ question: Question }> = ({ question }) => {
  const { root, health, healthTitle, healthValue } = useStyle({});
  const { Supplements } = question;
  const { t } = useTranslation('eq5d');
  const healthToday = Supplements?.Localizations?.yourHealth ?? t('yourHealth');
  const value = currentValueFor(question);

  return (
    <div className={root}>
      <p className={`${health} ${healthTitle}`}>{healthToday}</p>
      <p className={`${health} ${healthValue}`}>{value !== 0 && !value ? '-' : value}</p>
    </div>
  );
};

export const EQ5DVASQuestionRange = () => {
  const question = useCurrentQuestion();
  const dispatch = useDispatch();
  const currentValue = currentValueFor(question);

  const rangeHandler = (range: number) => {
    if (currentValue === range) {
      return;
    }
    const row = questions.answerRowForEQ5D(question);
    const column = questions.answerColumnForEQ5D(question);
    const columnWrapper = {
      question,
      row,
      column
    };

    dispatch(actions.answerTableTextQuestion(columnWrapper, range.toString()));
  };

  return <RangeSelector question={question} defaultValue={currentValue || undefined} onChange={rangeHandler} />;
};
