const LanguageId = {
  EN: 1,
  FR: 2,
  ES: 3,
  DE: 4,
  NL: 6,
  HU: 7,
  IT: 8,
  JA: 9,
  PL: 10,
  VI: 11,
  DA: 12,
  RU: 13,
  ZU: 14,
  TH: 15,
  AR: 16,
  AF: 17,
  ST: 18,
  XH: 19,
  ZH: 21,
  BG: 22,
  HE: 23,
  KO: 24,
  SQ: 25,
  HY: 26,
  ET: 27,
  FI: 28,
  FA: 29,
  KY: 30,
  LV: 31,
  LT: 32,
  PT: 33,
  SV: 34,
  UK: 35,
  AZ: 36,
  HR: 37,
  CS: 38,
  HI: 40,
  BN: 41,
  MS: 45,
  NE: 46,
  TL: 47,
  SR: 51,
  TR: 53,
  EL: 54,
  PA: 55,
  NO: 56,
  PA_ARAB: 57,
  UR: 110
};

export default LanguageId;
