import { FC } from 'react';
import { Theme, Button, Fab } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import Delete from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';

import { Question, Row, questions, actions } from 'services';
import last from 'lodash/last';
import { FixedTableRows } from './FixedTableQuestion';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles<Theme, {}>(theme =>
  createStyles({
    deleteButton: {
      minWidth: 40,
      minHeight: 40,
      backgroundColor: theme.palette.error.main,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
        boxShadow: 'none'
      }
    }
  })
);

export const RepeatRowQuestion: FC<{ question: Question }> = ({ question }) => {
  const classes = useStyles({});
  const table = questions.tableFrom(question);
  const dispatch = useDispatch();
  const { t } = useTranslation('buttons');

  const deleteEnabled = table.Rows.length > 1;

  const handleAddRow = () => {
    const lastRow = last(table.Rows) || table.Rows[0];
    dispatch(actions.addRowToRepeatingTableQuestion(question, lastRow));
  };

  const handleRemoveRow = (row: Row) => () => {
    dispatch(actions.removeRowFromRepeatingTableQuestion(question, row));
  };

  return (
    <FixedTableRows
      question={question}
      columnAppendRender={row =>
        deleteEnabled && (
          <Fab
            size="small"
            aria-label="delete"
            color="primary"
            className={classes.deleteButton}
            onClick={handleRemoveRow(row)}
          >
            <Delete />
          </Fab>
        )
      }
      rowAppendRender={() => (
        <Button onClick={handleAddRow} startIcon={<Add />}>
          {t('addNewEntry')}
        </Button>
      )}
    />
  );
};
