import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { PATH } from '../routes/routes';
import { store } from 'services';
import { Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Logout: React.FC<{
  openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}> = ({ openState }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { t: tAccount } = useTranslation('account');
  const { t: tSettings } = useTranslation('settings');
  const { t: tButtons } = useTranslation('buttons');
  const [open, setOpen] = openState;

  const handleClose = () => {
    setOpen(false);
  };

  const signOut = () => {
    dispatch(store.actions.user.signOut());
    push(PATH.INDEX.SIGN_IN_OPTIONS);
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="logout-title" aria-describedby="logout-description">
      <DialogContent>
        <DialogContentText id="logout-description">{tSettings('areYouSureYouWantToSignOut')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="text" color="primary">
          {tButtons('cancel')}
        </Button>
        <Button onClick={signOut} variant="text" color="secondary">
          {tAccount('signOut')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
