import { Typography } from '@mui/material';
import { Space } from 'components';
import { useHomeStyles } from '../../styles/Home.style';
import { useTranslation } from 'react-i18next';
import { WidgetCard } from 'components';

export const SubmittedSurvey = () => {
  const classes = useHomeStyles();
  const { t: tDashboard } = useTranslation('dashboard');
  return (
    <WidgetCard>
      <Typography variant="h3" className={classes.surveyTitle}>
        {tDashboard('surveySubmitted')}
      </Typography>
      <Space gap={2} />
      <Typography variant="body1">{tDashboard('surveySubmittedDescription')}</Typography>
      <Space gap={2} />
    </WidgetCard>
  );
};
