import { useRef, useEffect, useMemo } from 'react';

export const useHandler = <T extends (...args: any[]) => any>(handler: T): T => {
  const handlerRef = useRef(handler);
  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  const actualHandler = useMemo(() => {
    return (...args: any[]) => handlerRef.current(...args);
  }, []) as T;

  return actualHandler;
};
