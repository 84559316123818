import { isSingleAnswer, isSingleAnswerTableQuestion, validateQuestion } from '../questions';
import { Question, Answer, ColumnWrapper, Row, SurveyError } from '../types';
import { createAction } from './helpers';

export const ANSWER_SINGLE_ANSWER_QUESTION = 'ANSWER_SINGLE_ANSWER_QUESTION';
export const UNANSWER_SINGLE_ANSWER_QUESTION = 'UNANSWER_SINGLE_ANSWER_QUESTION';
export const ANSWER_SINGLE_ANSWER_PER_ROW_TABLE_QUESTION = 'ANSWER_SINGLE_ANSWER_PER_ROW_TABLE_QUESTION';
export const UNANSWER_SINGLE_ANSWER_PER_ROW_TABLE_QUESTION = 'UNANSWER_SINGLE_ANSWER_PER_ROW_TABLE_QUESTION';
export const ANSWER_MULTIPLE_ANSWER_QUESTION = 'ANSWER_MULTIPLE_ANSWER_QUESTION';
export const ANSWER_MULTIPLE_ANSWER_PER_ROW_TABLE_QUESTION = 'ANSWER_MULTIPLE_ANSWER_PER_ROW_TABLE_QUESTION';
export const ANSWER_TEXT_QUESTION = 'ANSWER_TEXT_QUESTION';
export const ANSWER_TEXT_TABLE_QUESTION = 'ANSWER_TEXT_TABLE_QUESTION';
export const ADD_ROW_TO_REPEATING_TABLE_QUESTION = 'ADD_ROW_TO_REPEATING_TABLE_QUESTION';
export const REMOVE_ROW_FROM_REPEATING_TABLE_QUESTION = 'REMOVE_ROW_FROM_REPEATING_TABLE_QUESTION';

export const SURVEY_INSTRUCTIONS_READ = 'SURVEY_INSTRUCTIONS_READ';

export const UPDATE_CURRENT_QUESTION = 'UPDATE_CURRENT_QUESTION';
export const SELECT_NEXT_QUESTION = 'SELECT_NEXT_QUESTION';
export const SELECT_PREVIOUS_QUESTION = 'SELECT_PREVIOUS_QUESTION';

export const SHOW_QUESTION_ERROR = 'SHOW_QUESTION_ERROR';
export const CLEAR_QUESTION_ERROR = 'CLEAR_QUESTION_ERROR';
export const SET_SURVEY_EXPIRED = 'SET_SURVEY_EXPIRED';

export const answerFixedQuestion = (question: Question, answer: Answer) => {
  if (isSingleAnswer(question)) {
    return createAction(ANSWER_SINGLE_ANSWER_QUESTION, { question, answer });
  }

  return createAction(ANSWER_MULTIPLE_ANSWER_QUESTION, { question, answer });
};

export const unanswerSingleFixedQuestion = (question: Question) => {
  if (isSingleAnswer(question)) {
    return createAction(UNANSWER_SINGLE_ANSWER_QUESTION, { question });
  }
};

export const answerTableFixedQuestion = (columnWrapper: ColumnWrapper, answer: Answer) => {
  const { question } = columnWrapper;
  const params = {
    question,
    columnWrapper,
    answer
  };
  if (isSingleAnswerTableQuestion(question, columnWrapper.column)) {
    return createAction(ANSWER_SINGLE_ANSWER_PER_ROW_TABLE_QUESTION, params);
  }

  return createAction(ANSWER_MULTIPLE_ANSWER_PER_ROW_TABLE_QUESTION, params);
};

export const unanswerTableRowQuestion = (columnWrapper: ColumnWrapper) => {
  const { question } = columnWrapper;
  const params = {
    question,
    columnWrapper
  };
  if (isSingleAnswerTableQuestion(question, columnWrapper.column)) {
    return createAction(UNANSWER_SINGLE_ANSWER_PER_ROW_TABLE_QUESTION, params);
  }
};

export const addRowToRepeatingTableQuestion = (question: Question, currentRow: Row) =>
  createAction(ADD_ROW_TO_REPEATING_TABLE_QUESTION, { question, currentRow });

export const removeRowFromRepeatingTableQuestion = (question: Question, rowToRemove: Row) =>
  createAction(REMOVE_ROW_FROM_REPEATING_TABLE_QUESTION, {
    question,
    rowToRemove
  });

export const answerTableTextQuestion = (columnWrapper: ColumnWrapper, text: string) =>
  createAction(ANSWER_TEXT_TABLE_QUESTION, {
    question: columnWrapper.question,
    columnWrapper,
    text
  });

export const answerTextQuestion = (question: Question, text: string) =>
  createAction(ANSWER_TEXT_QUESTION, { question, text });

export const selectNextQuestion = (question: Question) => {
  let surveyError: SurveyError;

  const { isValid, rowId, error } = validateQuestion(question);
  if (!isValid) {
    const { message, args } = error;
    surveyError = {
      message,
      args,
      questionId: question.Id,
      rowId
    };
    return createAction(SHOW_QUESTION_ERROR, { error: surveyError });
  }
  return createAction(SELECT_NEXT_QUESTION, {});
};

export const updateCurrentQuestion = (questionId: Question['Id']) =>
  createAction(UPDATE_CURRENT_QUESTION, { questionId });

export const selectPreviousQuestion = () => createAction(SELECT_PREVIOUS_QUESTION, {});

export const surveyInstructionsRead = () => createAction(SURVEY_INSTRUCTIONS_READ, {});

export const clearQuestionError = () => createAction(CLEAR_QUESTION_ERROR, {});

export const setSurveyExpired = (expiryOn: string) => createAction(SET_SURVEY_EXPIRED, { expiryOn });
