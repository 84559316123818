import { FC } from 'react';
import { Question, questions, models, Column } from 'services';
import { Box, Typography } from '@mui/material';

import {
  ReadOnlyFixedTableAnswer,
  ReadOnlyMultipleOptionTableAnswer,
  ReadOnlyEQ5DAnswer,
  ReadOnlyFixedTableAnswerWithHeader
} from './ReadOnlyTableAnswer';
import { useTranslation } from 'react-i18next';

const { QuestionTypes } = questions;
const { TableTypes } = models;

export type QuestionComponent = FC<{ question: Question }>;
export type ColumnQuestionComponent = FC<{ question: Column | Question }>;

export const NoAnswerComponent = () => {
  const { t } = useTranslation('survey');
  return <Typography variant="body2">{t('notAnswered')}</Typography>;
};

export const ReadOnlyAnswerRender: QuestionComponent = ({ question }) => {
  const { QuestionType } = question;

  switch (QuestionType.Id) {
    case QuestionTypes.DROP_DOWN:
    case QuestionTypes.CHECKBOX:
    case QuestionTypes.RADIO:
    case QuestionTypes.TRUE_FALSE:
      return <ReadOnlyMultipleChoiceAnswer question={question} />;

    case QuestionTypes.TEXT:
    case QuestionTypes.TEXT_NUMERIC:
    case QuestionTypes.TEXT_AREA:
      return <ReadOnlyTextAnswer question={question} />;

    case QuestionTypes.TABLE:
      return <ReadOnlyTableAnswer question={question} />;

    default:
      return <NoAnswerComponent />;
  }
};

export const ReadOnlyMultipleChoiceAnswer: ColumnQuestionComponent = ({ question }) => {
  const answers = question.Result.map(({ AnswerId }) => question.Answers.find(({ Id }) => Id === AnswerId)?.Value);

  return <span>{answers?.length > 1 ? answers.join(', ') : answers}</span>;
};

export const ReadOnlyTextAnswer: ColumnQuestionComponent = ({ question }) => {
  const answer = questions.answerTextFor(question);
  return <Box display="inline">{answer ? answer : <NoAnswerComponent />}</Box>;
};

export const ReadOnlyTableAnswer: QuestionComponent = ({ question }) => {
  const table = questions.tableFrom(question);
  const tableType = table.TableType.Id;

  switch (tableType) {
    case TableTypes.RADIO:
    case TableTypes.CHECKBOX:
      return <ReadOnlyMultipleOptionTableAnswer question={question} />;

    case TableTypes.REPEAT_ROW:
      return <ReadOnlyFixedTableAnswerWithHeader question={question} />;

    case TableTypes.FIXED:
      return questions.isEQ5DVAS(question) ? (
        <ReadOnlyEQ5DAnswer question={question} />
      ) : (
        <ReadOnlyFixedTableAnswer question={question} />
      );

    default:
      return <NoAnswerComponent />;
  }
};
