import { Requisite, UserInfo } from './api-hooks';

export interface Config {
  oAuthEndPoint: string;
  probeEndPointBase: string;
  studyGuid: string;
  studyId: number;
  isProd: boolean;
  appVersion: string;
  trackingId: string;
  providers: Record<SSO_PROVIDER, ProviderConfig>;
}

export type SSO_PROVIDER = 'PROBEResearch';

export type ProviderConfig = {
  endPointBase: string;
  authorizationEndPoint: string;
  tokenEndPoint: string;
  clientId: string;
  scope?: string;
};

export interface LanguageDefinition {
  /*
   * Specifies whether the language is LTR or RTL
   * */
  isRTL: boolean;

  /*
   * Specifies whether the language has reduced font faces for bold (Japanese, Chinese etc..)
   * */
  isReducedBold: boolean;
}

export interface Language extends LanguageDefinition {
  /*
   * The internal id used in PROBE to match the language to a dialect. Multiple language can have the same id
   * (Spanish Mexico and Spanish Spain for example)
   * */
  languageId: number;

  /*
   * Language code. Unique identifier
   * */
  code: string;

  /*
   * Display of the language in this language
   * */
  display: string;
}

export interface Dialect {
  language: Language;

  /*
   * Dialect code. Unique identifier
   * */
  code: string;

  /*
   * The internal country Id used to identify a specific dialect. Only set for a dialect and not for the main language
   * */
  countryId?: number;
}

export interface Country {
  /*
   * Country id as defined in the PROBE database
   * */
  id: number;

  /*
   * International country code
   * */
  code: string;

  /*
   * Display in default language
   * */
  display: string;

  /*
   * Is login enabled for this country
   * */
  loginEnabled: boolean;
}

export interface Token {
  id_token?: string;
  access_token: string;
  expires_in: number;
  refresh_token: string;
  expires_on: Date | string;
}

export interface Settings {
  language: string | undefined;
  dialect: string | undefined;
  country: number | undefined;
  notifyNextSurvey: boolean;
}

export interface IUpdateUser {
  email?: string;
  language?: string;
  country?: number;
}

export interface ICreateUser extends IUpdateUser {
  username?: string;
  password?: string;
}

/*
 * Interface defining a User in the PROBE Database.
 * */
export interface ProbeUser {
  CountryId?: number;
  LanguageId?: number;
  Email?: string;
  UserName?: string;
  PhoneNumber?: string;
  FirstName?: string;
  LastName?: string;
  Password?: string;
}

export interface ISurveyProperties extends IWIthId {
  Instructions: string;
  LanguageCode: string;
  Title: string;
  InstanceId: number;
  ProjectId: number;
  CreatedOn: Date | string;
  ExpiresOn: Date | string;
}

export interface NormalizedSurvey extends ISurveyProperties {
  Sections: NormalizedSection[];
  Questions: Question[];
  Requisites: Record<number, Requisite>;
}

export interface SurveyError {
  message: string;
  questionId: number;
  rowId: number | null;
  args?: object;
}

export interface CurrentSurvey extends NormalizedSurvey {
  isDefined: boolean;
  /*
   * Specifies whether the survey was submitted or not. Flag is only true after submission and will be reset upon start
   */
  submitted: boolean;
  currentQuestionId: number;
  instructionsRead: boolean;
  studyId: number;

  // current error to be displayed to the user
  error: SurveyError | null;
}

export interface Sync {
  questionIds: number[];
}

export interface Stats {
  // accumulated time spent in seconds on the survey
  timeSpent: number;
  // When was an answer changed for the last time
  lastSaveDate: string | Date;
}

export interface CompositeSurvey extends ISurveyProperties {
  Requisites: Requisite[];
  Sections: Section[];
  AlternateText: AlternateText[];
}

export interface IWIthId {
  Id: number;
}

export interface NormalizedSection extends IWIthId {
  Title: string;
  Questions: number[];
}

export interface Section extends IWIthId {
  Title: string;
  Questions: Question[];
}

export interface ISurveyEntry extends IWIthId {
  Required: boolean;
  DisplayOrder: number;
}

export interface IAnswerable extends ISurveyEntry {
  QuestionType: QuestionType;
  Answers: Answer[];
  Result: Result[];
}

export interface Localizations {
  eq5dCopyright?: string;
  theBestHealth?: string;
  theWorstHealth?: string;
  yourHealth?: string;
}

export interface Question extends IAnswerable {
  DependencyExpression: string | null;

  // Table is null except if QuestionType is table, to which case Table is not null
  Table: Table | null;
  Text: string;
  DisplayOrder: number;
  SourceSurveyId: number;
  ExternalId: number;
  ProcessingOrder: number;
  Visible: boolean;
  Supplements?: {
    [key: string]: {
      [key: string]: string;
    };
  };
}

export interface Result {
  AnswerId?: number | null;
  InputText?: string | null;
}

export interface SerializableAnswerable extends IWIthId {
  Result?: Result[];
}

export interface SerializedQuestion extends SerializableAnswerable {
  ProcessingOrder: number;
  Table?: SerializedTable;
}

export interface SerializedTable extends IWIthId {
  Rows: SerializableRow[];
}

export interface SerializableRow extends IWIthId {
  Columns: SerializableAnswerable[];
  DisplayOrder?: number;
}

export interface Answer extends IWIthId {
  Value: string;
}

export interface Table extends IWIthId {
  Rows: Row[];
  TableType: TableType;
  GroupColumns: boolean;
}

export interface Row extends ISurveyEntry {
  Columns: Column[];
  Header: string | null;
  Repeating?: boolean;
  GroupColumns: boolean;
}

export interface Column extends IAnswerable {
  Header: string;
  Grouping: boolean;
  HideAnswerText: boolean;
}

export interface ColumnWrapper {
  question: Question;
  row: Row;
  column: Column;
}

export interface TableType extends IWIthId {
  Description?: string;
}

export interface QuestionType extends IWIthId {
  Description?: string;
}

export interface Network {
  isConnected: boolean;
  hasCheckedStatus: boolean;
}

export interface AuthenticationParams {
  token: Token;
  // what oauth provider was used for login (only available if using 3rd Party Auth)
  provider?: string;
  // provider token defined BEFORE the exchange
  providerToken?: Token;
  userInfo?: UserInfo;
  studyGuid?: string;
  studyId?: number;
}

export interface Auth extends AuthenticationParams {
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  isSigningUp: boolean;
  isUpdating: boolean;
}

export interface Loading {
  isLoading: boolean;
  message: string;
}

export interface State {
  auth: Auth;
  network: Network;
  loading: Loading;
  currentSurvey: CurrentSurvey;
  alert: Alert;
  settings: Settings;
  stats: Stats;
  sync: Sync;
}

export interface ValidationResult<T> {
  invalid: boolean;
  valid: boolean;
  error: string;
  validatedItem: T;
}

export interface TableComponentProps {
  // Use for questions with predefined list of answers
  answerTableFixedQuestion: (column: ColumnWrapper, answer: Answer) => void;
  // Use for questions with input text
  answerTableTextQuestion: (column: ColumnWrapper, text: string) => void;
}

export interface QuestionComponentProps extends TableComponentProps {
  // any error related to the current question that should be displayed
  error: SurveyError | null;

  // The question being rendered
  question: Question;

  // Use for questions with predefined list of answers
  answerFixedQuestion: (question: Question, answer: Answer) => void;

  // Use for questions with input text
  answerTextQuestion: (question: Question, text: string) => void;

  // Use to add a clone of the current row to the question
  addRowToRepeatingTableQuestion: (question: Question, current: Row) => void;

  removeRowFromRepeatingTableQuestion: (question: Question, rowToRemove: Row) => void;
}

export interface ColumnAnswerComponentProps extends TableComponentProps, ColumnWrapper {}

export type AlertType = 'error' | 'info' | 'warn' | 'success';

export interface Alert {
  message: string;
  title: string;
  type: AlertType;
}

export interface TranslatableError {
  message: string;
  args?: object;
}

export interface ValidationOutcome {
  isValid: boolean;
  rowId: number | null;
  error: TranslatableError;
}

export interface Range {
  min: number;
  max: number;
}

export interface AlternateText {
  Id: number;
  ConnectionString: string;
  ResourceSet: string;
  SourceId: number;
}

export enum LoginType {
  Probe = 1 << 0,
  ThirdPartyApp = 1 << 1,
  Guest = 1 << 2,
  ParticipantLink = 1 << 3
}
