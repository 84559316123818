import { createAction } from '../../actions';
import {
  SSO,
  refreshProbeAccessTokenAsync,
  shouldRefresh,
  enrollInStudyAsync,
  registerForStudyGuestLoginAsync,
  updateProbeUserAsync
} from '../../auth';
import { UserState } from './User.reducer';
import { setAppLoading, setStudyLinkId } from '../System/System.actions';
import { SystemState } from '../System/System.reducer';
import { Token } from '../../types';
import { UserInfo } from '../../api-hooks';
export const SIGN_OUT = 'SIGN_OUT';

export const setUserState = (newUserState: Partial<UserState>) => createAction('SET_USER_STATE', { newUserState });

export const signOut = () => createAction(SIGN_OUT, {});

export type UserActions = ReturnType<typeof setUserState | typeof signOut>;

export const refreshTokenIfRequired =
  () =>
  async (dispatch: any, getState: () => { user: UserState }): Promise<void> => {
    const { user } = getState();
    if (user.token && shouldRefresh(user.token)) {
      try {
        dispatch(setAppLoading(true));
        const refreshedToken = await refreshProbeAccessTokenAsync(user.token);
        dispatch(setUserState({ ...user, token: refreshedToken }));
      } catch (error) {
        dispatch(signOut());
      } finally {
        dispatch(setAppLoading(false));
      }
    }
  };

export const signInUsingProbe =
  (token: Token, userInfo: UserInfo) =>
  async (dispatch: any, getState: () => { user: UserState; system: SystemState }): Promise<void> => {
    const { user, system } = getState();
    const { studyGuid } = system;
    await enrollInStudyAsync(studyGuid, token);
    dispatch(setUserState({ ...user, token, userInfo, isGuest: false }));
  };

export const signInAsGuest =
  () =>
  async (dispatch: any, getState: () => { system: SystemState }): Promise<void> => {
    const { system } = getState();
    const { studyGuid, countryId, languageId } = system;
    const token = await registerForStudyGuestLoginAsync(studyGuid);
    await updateProbeUserAsync({ CountryId: countryId, LanguageId: languageId }, token);
    await enrollInStudyAsync(studyGuid, token);
    dispatch(setUserState({ token, isGuest: true }));
  };

export const signInAsStudyParticipant =
  () =>
  async (dispatch: any, getState: () => { system: SystemState }): Promise<void> => {
    const { system } = getState();
    const { studyGuid, countryId, languageId } = system;
    const token = await registerForStudyGuestLoginAsync(studyGuid);
    await updateProbeUserAsync({ CountryId: countryId, LanguageId: languageId }, token);
    await enrollInStudyAsync(studyGuid, token);
    dispatch(setUserState({ token, isGuest: false }));
  };

export const signInUsingSSO =
  (authCode: string) =>
  async (dispatch: any, getState: () => { user: UserState; system: SystemState }): Promise<void> => {
    const { user } = getState();
    const response = await SSO().authenticateAsync(authCode);
    if (!response) {
      return;
    }

    const { userInfo, providerToken, provider, token, studyGuid, studyId } = response;
    if (studyGuid && studyId) {
      await enrollInStudyAsync(studyGuid, token);
      // firstLogin false as the user already selected the login method
      dispatch(setStudyLinkId(studyGuid, studyId, /*firstLogin*/ false));
    }

    dispatch(setUserState({ ...user, userInfo, providerToken, provider, token, isGuest: false }));
  };
