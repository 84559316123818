import { FC, ReactNode } from 'react';
import { RestfulProvider } from 'restful-react';
import { PROBE_ENDPOINT_BASE } from '../constants';
import { tokenAuthHeader, appHeader } from '../axios-helpers';

export const ProbeRestfulProvider: FC<{ children: ReactNode; authToken?: string }> = ({ children, authToken }) => {
  const authHeader = authToken ? tokenAuthHeader(authToken) : undefined;
  return (
    //@ts-ignore
    <RestfulProvider
      base={PROBE_ENDPOINT_BASE}
      requestOptions={{
        headers: { ...appHeader, ...authHeader }
      }}
    >
      {children}
    </RestfulProvider>
  );
};
