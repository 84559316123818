import { auth, Token, UserInfo } from 'services';
import { Button, Typography } from '@mui/material';

import ArrowForward from '@mui/icons-material/ArrowForward';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';

import { FC } from 'react';

import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import { PasswordInput, TextInput } from '..';
import { Stack } from '../Stack/Stack';
import { Swipe } from '../Transitions/Swipe';
import { useNotification } from '../Notification/Notification';
import { ForgotPassword } from '../ForgotPassword/ForgotPassword';
import { Space } from '../Space/Space';

type LoginForm = {
  username: string;
  password: string;
};

interface SignInFormProps {
  onSignInSuccess: (token: Token, userInfo: UserInfo) => void;
  onClickRegister?: () => void;
}

export const SignInForm: FC<SignInFormProps> = ({ onSignInSuccess, onClickRegister }) => {
  const { t: tAccount } = useTranslation('account');
  const { t: tError } = useTranslation('errors');
  const { notify } = useNotification();
  const form = useForm<LoginForm>({
    mode: 'onBlur',
    validationSchema: object<LoginForm>({
      username: string().required(),
      password: string().required()
    })
  });

  const username = form.watch('username', '');

  const onSubmit = async (formData: LoginForm) => {
    try {
      const { token, userInfo } = await auth.signInWithUsernameAndPasswordAsync(formData.username, formData.password);
      onSignInSuccess(token, userInfo);
    } catch (e: any) {
      notify({ type: 'error', message: tError(e.message) });
    }
  };

  return (
    <Swipe direction="left">
      <Stack gap={3} width="100%">
        <Stack variant="horizontal" alignItems="center" gap={2}>
          <AccountCircleOutlined color="primary" fontSize="large" />
          <Typography variant="h5">{tAccount('signIn')}</Typography>
        </Stack>
        <Typography variant="body2">{tAccount('loginAsAProbeUserDescription')}</Typography>
        <form data-testid="sign-in-page">
          <Stack gap={3} width="100%">
            <TextInput<LoginForm>
              name="username"
              formRef={form}
              label={tAccount('usernameOrEmail')}
              inputProps={{ 'data-testid': 'username' }}
            />
            <PasswordInput<LoginForm>
              name="password"
              formRef={form}
              label={tAccount('password')}
              inputProps={{ 'data-testid': 'password' }}
            />
          </Stack>
          <Stack variant="horizontal" alignItems="center" justifyContent="end">
            <ForgotPassword defaultEmail={username} />
          </Stack>
          <Space gap={2} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Button
              type="submit"
              size="large"
              color="secondary"
              data-testid="submit"
              onClick={form.handleSubmit(onSubmit)}
              endIcon={<ArrowForward />}
              disabled={form.formState.isSubmitting}
            >
              {tAccount('signIn')}
            </Button>
          </div>
        </form>
      </Stack>
    </Swipe>
  );
};
