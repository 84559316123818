import { FC } from 'react';

import { questions } from 'services';
import { useCurrentQuestion } from '../hooks';
import { TextQuestion } from './TextQuestion/TextQuestion';
import { TableQuestion } from './TableQuestion/TableQuestion';
import { MultipleChoiceQuestion } from './MultipleChoiceQuestion/MultipleChoiceQuestion';

const { QuestionTypes } = questions;

export const QuestionComponentFactory: FC = () => {
  const { QuestionType } = useCurrentQuestion();
  switch (QuestionType.Id) {
    case QuestionTypes.DROP_DOWN:
    case QuestionTypes.CHECKBOX:
    case QuestionTypes.RADIO:
    case QuestionTypes.TRUE_FALSE:
      return <MultipleChoiceQuestion />;
    case QuestionTypes.TEXT:
    case QuestionTypes.TEXT_NUMERIC:
    case QuestionTypes.TEXT_AREA:
      return <TextQuestion />;
    case QuestionTypes.TABLE:
      return <TableQuestion />;
    case QuestionTypes.DISPLAY_ONLY:
      return null;
    default:
      return <MultipleChoiceQuestion />;
  }
};
