import { LoadingBar, Notification, Helmet } from 'components';
import { lazy, Suspense, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory, Redirect } from 'react-router';
import { Home } from '../components/Home';
import { Survey } from '../components/Survey';
import { DashboardWrapper } from '../layouts/DashboardWrapper';
import { PATH } from './routes';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { store, isSurveyInvalid, ProbeRestfulProvider, auth, useStateSelector } from 'services';
import { useTranslation } from 'react-i18next';
import { SignInLayout } from '../layouts/SignInLayout';
import { StartStudyHandler } from '../components/StartStudyHandler';
import { WelcomeSurvey } from '../components/WelcomeSurveyPage';
import { AppDashboardHandler } from 'app/components/AppDashboardHandler';

const SharedSurveyView = lazy(() => import('../components/SharedSurvey/View'));
const SubmitPage = lazy(() => import('../components/SubmitPage'));
const SharePage = lazy(() => import('../components/SharePage'));
const SettingsPage = lazy(() => import('../components/SettingsPage'));
const TermsOfUsePage = lazy(() => import('../components/TermsOfUsePage'));
const PrivacyPolicyPage = lazy(() => import('../components/PrivacyPolicyPage'));
const EmbeddedDashboard = lazy(() => import('../components/EmbeddedDashboard'));

const Page404 = lazy(() => import(/* webpackChunkName: "Page404" */ '../components/404'));

const useStyles = makeStyles<Theme, { appLoading: boolean }>(
  createStyles({
    global: {
      color: '#444',
      pointerEvents: ({ appLoading }) => (appLoading ? 'none' : 'all')
    }
  })
);

export const IndexRoutes = () => {
  const user = useStateSelector('user');
  const { appLoading } = useStateSelector('system');
  const survey = useStateSelector('survey');
  const { token } = user;
  const dispatch = useDispatch();
  const { t: tDashboard } = useTranslation('dashboard');
  const {
    push,
    location: { pathname }
  } = useHistory();
  const { current: initialLoadedPath } = useRef(pathname);
  const classes = useStyles({ appLoading });

  useEffect(() => {
    dispatch(store.actions.user.refreshTokenIfRequired());
  }, [dispatch]);

  useEffect(() => {
    if (!token && initialLoadedPath === PATH.INDEX.ROOT) {
      push(PATH.INDEX.LOCALIZATION);
    }
  }, [token, push, initialLoadedPath]);

  const surveyInvalid = isSurveyInvalid(survey);

  const tokenValid = token && !auth.shouldRefresh(token);

  return (
    <Suspense fallback={<LoadingBar show />}>
      <Helmet>
        <title>{tDashboard('welcomeToProbe', { studyName: 'PROBE' })}</title>
      </Helmet>
      <div className={classes.global}>
        <Switch>
          <Route exact path={PATH.INDEX.SHARED_SURVEY} component={SharedSurveyView} />
          <Route exact path={PATH.INDEX.STUDY} component={StartStudyHandler} />
          <Route exact path={PATH.INDEX.APP_DASHBOARD} component={AppDashboardHandler} />

          <Route>
            {!token && (
              <Switch>
                <Route exact path={PATH.INDEX.TERMS} component={TermsOfUsePage} />
                <Route exact path={PATH.INDEX.PRIVACY} component={PrivacyPolicyPage} />
                <Route component={SignInLayout}></Route>
              </Switch>
            )}
            {tokenValid && (
              <ProbeRestfulProvider authToken={token.access_token}>
                <Switch>
                  <Route exact path={PATH.INDEX.EMBEDDED_DASHBOARD} component={EmbeddedDashboard} />
                  <Route>
                    <DashboardWrapper>
                      <Switch>
                        <Route exact path={PATH.INDEX.ROOT} component={Home} />
                        <Route exact path={PATH.INDEX.INSTRUCTIONS} component={WelcomeSurvey} />
                        <Route exact path={PATH.INDEX.SETTINGS} component={SettingsPage} />
                        <Route exact path={PATH.INDEX.SUBMIT} component={SubmitPage} />
                        <Route exact path={PATH.INDEX.SHARE} component={SharePage} />
                        <Route exact path={PATH.INDEX.SHARED_SURVEY_PREVIEW} component={SharedSurveyView} />
                        {surveyInvalid ? (
                          <Redirect to={PATH.INDEX.ROOT} />
                        ) : (
                          <Route exact path={PATH.SURVEY.ROOT} component={Survey} />
                        )}
                        <Route component={Page404} />
                      </Switch>
                    </DashboardWrapper>
                  </Route>
                </Switch>
              </ProbeRestfulProvider>
            )}
          </Route>
        </Switch>
      </div>
      <Notification />
    </Suspense>
  );
};
