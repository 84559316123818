import { Snackbar, Theme, Slide, IconButton, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { SnackbarCloseReason, SnackbarProps as MuiSnackbarProps } from '@mui/material/Snackbar';
import { FC } from 'react';

import Close from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import DoneIcon from '@mui/icons-material/Done';
import { NotificationInterface } from './Notification';
import { green, grey } from '@mui/material/colors';

interface SnackbarProps extends MuiSnackbarProps {
  onClose?: () => void;
  type: NotificationInterface['type'];
}

const SlideTransition = (props: any) => {
  return <Slide {...props} direction="right" />;
};

const useStyle = makeStyles<Theme, SnackbarProps>(theme =>
  createStyles({
    root: ({ type }) => ({
      backgroundColor: type ? { error: theme.palette.error.main, success: green[400] }[type] : grey[200]
    }),
    whiteColor: {
      color: '#fff'
    }
  })
);

export const SnackBar: FC<SnackbarProps> = props => {
  const classes = useStyle(props);
  const { type, ...snackbarProps } = props;

  const SnackIcon = type ? { error: ErrorIcon, success: DoneIcon }[type] : ErrorIcon;

  const handleClose = (event: React.SyntheticEvent<any> | Event, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <Snackbar
      TransitionComponent={SlideTransition}
      onClose={handleClose}
      autoHideDuration={6000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      ContentProps={{ className: classes.root }}
      action={
        <IconButton aria-label="Close" onClick={props.onClose} size="large">
          <Close className={classes.whiteColor} />
        </IconButton>
      }
      {...snackbarProps}
      message={
        <Box alignItems="center" display="flex">
          <SnackIcon className={classes.whiteColor} style={{ marginRight: 8, marginLeft: 8 }} />
          {props.message}
        </Box>
      }
    />
  );
};
