import { extractErrorMessage } from '../error';
import { Network } from '../types';

export const createAction = <A extends string, T extends object>(type: A, params: T) => ({
  type,
  ...params
});

export const createError = (type: string, error: Error, params: any = {}) =>
  createAction(type, {
    errorMessage: extractErrorMessage(error),
    error,
    ...params
  });

export const isOffline = (network: Network): boolean => {
  const { isConnected, hasCheckedStatus } = network;
  return !isConnected && hasCheckedStatus;
};

export const isNetworkError = (error: any): boolean => !!error.isNetworkError;
