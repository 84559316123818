import { LoadingBar, Main } from 'components';
import { hasExpired, useStateSelector } from 'services';
import { Box } from '@mui/material';
import { BarChart, Home, Lock, Settings } from '@mui/icons-material';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, Route, Switch, useHistory, useLocation } from 'react-router';
import { HomeLoading } from '../components/HomeLoading';
import { Logout } from '../components/Logout';
import { SideMenuItemProps, SideNav } from '../components/SideNav/SideNav';
import { useFetchSurvey } from '../hooks/useFetchSurvey';
import { DashboardContextProvider } from '../hooks/useDashboardContext';
import { PATH } from '../routes/routes';

const activeLinkChecker = (pathname: string) => (item: SideMenuItemProps) => {
  return !!matchPath(pathname, {
    exact: item.link === PATH.INDEX.ROOT,
    path: item.link
  });
};

export const sideNavWidth = 280;

export const DashboardWrapper: React.FC = ({ children }) => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { t: tMenu } = useTranslation('menu');
  const { t: tSettings } = useTranslation('settings');
  const { isFetching } = useFetchSurvey();
  const [openLogout, setLogout] = useState(false);
  const survey = useStateSelector('survey');
  const { appLoading } = useStateSelector('system');
  const { isGuest } = useStateSelector('user');

  const topMenuItems: SideMenuItemProps[] = [
    {
      label: tMenu('dashboard'),
      icon: Home,
      link: PATH.INDEX.ROOT,
      hidden: isGuest
    },
    {
      label: tMenu('survey'),
      icon: BarChart,
      link: PATH.SURVEY.ROOT,
      hidden: hasExpired(survey) || survey.submitted
    }
  ];

  const bottomMenuItems: SideMenuItemProps[] = [
    {
      label: tMenu('settings'),
      icon: Settings,
      link: PATH.INDEX.SETTINGS,
      hidden: useStateSelector('user').isGuest
    },
    {
      label: tSettings('signOut'),
      icon: Lock,
      link: PATH.INDEX.LOGOUT,
      onClick: () => setLogout(true)
    }
  ];

  const showProgressBar = appLoading || isFetching;
  return (
    <Fragment>
      {showProgressBar && <LoadingBar show color="secondary" />}
      <Box display="flex">
        <SideNav
          logoSrc="/assets/images/logo_transparent.png"
          headerLogoSrc="/assets/images/logo_transparent.png"
          topMenuItems={topMenuItems}
          bottomMenuItems={bottomMenuItems}
          onMenuItemClick={push}
          activeLinkChecker={activeLinkChecker(pathname)}
          width={sideNavWidth}
        />
        {isFetching ? (
          <Switch>
            <Route exact path={PATH.INDEX.ROOT} component={HomeLoading} />
          </Switch>
        ) : (
          <DashboardContextProvider>
            <Main>{children}</Main>
          </DashboardContextProvider>
        )}
        <Logout openState={[openLogout, setLogout]} />
      </Box>
    </Fragment>
  );
};
