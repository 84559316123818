import { Card, CardProps } from '@mui/material';
const cardBoxShadow = '0px 10px 20px rgba(0, 0, 0, 0.14)';

export const WidgetCard = (props: CardProps) => (
  <Card
    sx={{
      width: '100%',
      boxShadow: cardBoxShadow,
      padding: {
        xs: 4,
        sm: [4, 2]
      },
      marginTop: {
        xs: 2
      }
    }}
    {...props}
  ></Card>
);
