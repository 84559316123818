import { FC } from 'react';
import { NormalizedSurvey, visibleQuestionsInSection, CurrentSurvey, Question } from 'services';
import { Typography, List, ListItem, ListItemText, ListSubheader, Box } from '@mui/material';
import { ReadOnlyAnswerRender } from './ReadOnlyAnswerRender';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { QuestionText } from '../SurveyQuestions/QuestionTitle';

export const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    rangeSelector: { breakBefore: 'page' },
    strong: { fontWeight: 600 },
    header: { fontWeight: 500 },
    question: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      '@media print': {
        paddingTop: 0,
        paddingBottom: 0
      }
    }
  })
);

export const ReadOnlyQuestionRender: FC<{ question: Question }> = ({ question }) => {
  const style = useStyles();
  return (
    question && (
      <ListItem divider>
        <Box width="100%">
          <ListItemText
            primary={
              <Box className={style.question}>
                <QuestionText question={question} />
              </Box>
            }
            classes={{ primary: style.strong }}
          />
          <ReadOnlyAnswerRender question={question} />
        </Box>
      </ListItem>
    )
  );
};

export const ReadOnlySurveyRender: FC<{ survey: NormalizedSurvey }> = ({ survey }) => {
  const style = useStyles();
  const currentSurvey: CurrentSurvey = {
    ...survey,
    isDefined: true,
    studyId: -1,
    currentQuestionId: -1,
    submitted: true,
    instructionsRead: true,
    error: null
  };
  return (
    <>
      {survey.Sections.map((section, sectionIndex) => {
        const questions = visibleQuestionsInSection(section, currentSurvey);

        if (!questions.length) {
          return null;
        }

        return (
          <List key={sectionIndex}>
            <ListSubheader>
              <Typography variant="h6" classes={{ h5: style.header }} color="primary">
                {section.Title}
              </Typography>
            </ListSubheader>
            <ListItem disableGutters dense className={style.question}>
              <List component="ol" className={style.question}>
                {questions.map(question => (
                  <ReadOnlyQuestionRender key={question.Id} question={question} />
                ))}
              </List>
            </ListItem>
          </List>
        );
      })}
    </>
  );
};
