import { useMemo, FC } from 'react';
import { availableCountries, store, useStateSelector } from 'services';
import { SelectionBox } from '../SelectionBox/SelectionBox';
import { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export const CountrySelection: FC<SelectProps> = props => {
  const { t } = useTranslation('account');
  const { countryId, availableCountryIds } = useStateSelector('system');
  const dispatch = useDispatch();
  const countries = useMemo(() => availableCountries(availableCountryIds), [availableCountryIds]);

  const handleChange = (e: SelectChangeEvent<any>) => {
    dispatch(store.actions.system.setCountryId(e.target.value));
  };

  return (
    <SelectionBox
      onChange={handleChange}
      label={t('countrySelection')}
      options={countries}
      value={countryId}
      {...props}
    />
  );
};
