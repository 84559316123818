export const extractErrorMessage = (error: any): string => {
  if (!error) {
    return 'unknown_error';
  }

  if (typeof error === 'string') {
    return error;
  }

  if (typeof error.Error === 'object') {
    return extractErrorMessage(error.Error);
  }

  return (
    error.message ||
    error.error ||
    error.Error ||
    error.Message ||
    error.ErrorMessage ||
    error.Data ||
    error.data ||
    error.error_description ||
    JSON.stringify(error)
  );
};
