import { answerFor, answerTextFor, isNumeric } from './questions';
import { IAnswerable, Question, Range, TranslatableError, ValidationOutcome, ValidationResult } from './types';

// tslint:disable-next-line:max-line-length
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateNotEmpty = (itemToValidate: string, optional = false): ValidationResult<string> => {
  if (optional || (itemToValidate && itemToValidate.length >= 1)) {
    return validState(itemToValidate);
  }

  return invalidState('errors:valueRequired');
};

export const validateEmail = (email: string, optional = false): ValidationResult<string> => {
  if (!email && optional) {
    return validState(email);
  }

  const valid = EMAIL_REGEX.test(email);
  if (valid) {
    return validState(email);
  }
  return invalidState('errors:emailInvalid');
};

export const validatePassword = (password: string): ValidationResult<string> => {
  const minPasswordLength = 6;
  const valid = password.length >= minPasswordLength;
  if (valid) {
    return validState(password);
  }
  return invalidState('errors:passwordTooShort');
};

const invalidState = (error: string): ValidationResult<any> => ({
  invalid: true,
  valid: false,
  error,
  validatedItem: null
});

const validState = (validatedItem: string): ValidationResult<any> => ({
  invalid: false,
  valid: true,
  error: '',
  validatedItem
});

const isNumber = (value: number | string) => {
  const parsedValue = Number(value);
  return !Number.isNaN(parsedValue) && Number.isFinite(parsedValue);
};

const isValueInRange = (value: string, min: number, max: number) => {
  const parsedValue = Number(value);
  return isNumber(parsedValue) && parsedValue <= max && parsedValue >= min;
};

const createValidationOutcome = (
  error: TranslatableError,
  isValid = false,
  rowId: number | null = null
): ValidationOutcome => ({
  error,
  isValid,
  rowId
});

export const validValidationOutcome = (): ValidationOutcome => createValidationOutcome({ message: '' }, true);

export const invalidValidationOutcome = (errorMessage: string, rowId: number | null = null): ValidationOutcome =>
  createValidationOutcome({ message: errorMessage }, false, rowId);

export const minMaxValidator =
  (item: string, range: Range, units?: string | null) =>
  (question: Question): ValidationOutcome => {
    const { Required } = question;

    const value = answerTextFor(question);
    if (!value && !Required) {
      return validValidationOutcome();
    }

    const { min, max } = range;
    let isValid = false;

    const errorMessage = {
      message: isValid ? '' : 'messages:minMaxError',
      args: { item, min, max, units }
    };

    if (!value) {
      return createValidationOutcome(errorMessage);
    }

    isValid = isValueInRange(value, min, max);
    return createValidationOutcome(errorMessage, isValid);
  };

export const unitsValidator =
  (item: string, ranges: Record<string, { min: number; max: number }>) =>
  (question: Question): ValidationOutcome => {
    const errorMessage = {
      message: 'messages:emptyValue',
      args: { item }
    };

    if (!question.Table) {
      return createValidationOutcome(errorMessage);
    }

    const row = question.Table.Rows[0];
    const rowId = row.Id;

    const result1 = row.Columns[0].Result;
    const result2 = row.Columns[1].Result;
    const { Required } = question;

    if (!result1.length && !result2.length && !Required) {
      return validValidationOutcome();
    }

    if (!result1.length) {
      return createValidationOutcome(errorMessage, false, rowId);
    }

    if (!result2.length) {
      return invalidValidationOutcome('messages:emptySelection', rowId);
    }

    const value = row.Columns[0].Result[0].InputText;
    const units = row.Columns[1].Result[0].AnswerId;

    if (!units) {
      return invalidValidationOutcome('messages:emptySelection', rowId);
    }

    const { min, max } = ranges[units];
    const isValid = isValueInRange(value as string, min, max);

    const unitAnswer = answerFor(row.Columns[1]);
    const unitLabel = unitAnswer ? unitAnswer.Value.toLowerCase() : null;

    const message = isValid ? '' : max === Infinity ? 'messages:numericError' : 'messages:minMaxError';

    return createValidationOutcome(
      {
        message,
        args: { item, min, max, units: unitLabel }
      },
      isValid,
      rowId
    );
  };

export const numericValidator = (question: Question): ValidationOutcome => {
  if (isNumeric(question)) {
    return singleLineNumericValidator(question);
  }

  return tableNumericValidator(question);
};

export const singleLineNumericValidator = (answerable: IAnswerable): ValidationOutcome => {
  const value = answerTextFor(answerable);
  const required = answerable.Required;

  if (!value) {
    if (!required) {
      return validValidationOutcome();
    }
    return invalidValidationOutcome('messages:emptyValue');
  }
  const isValid = isNumber(value);
  return createValidationOutcome(
    {
      message: isValid ? '' : 'messages:numericError',
      args: { item: 'messages:value' }
    },
    isValid
  );
};

const tableNumericValidator = (question: Question): ValidationOutcome => {
  const errorMessage = {
    message: 'messages:emptyValue',
    args: { item: 'messages:value' }
  };

  if (!question.Table) {
    return createValidationOutcome(errorMessage);
  }

  const rows = question.Table.Rows;

  let rowId: number | null = null;

  const someItemsAreInvalid = rows.some(row => {
    const columns = row.Columns;

    return columns.filter(isNumeric).some(column => {
      const { isValid } = singleLineNumericValidator(column);
      if (!isValid) {
        rowId = row.Id;
        return true;
      }
      return false;
    });
  });

  return createValidationOutcome(
    {
      message: 'messages:numericError',
      args: { item: 'messages:value' }
    },
    !someItemsAreInvalid,
    rowId
  );
};
