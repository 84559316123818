import { Typography, Button, Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CountrySelection, LanguageSelection, Stack, Space, Swipe } from '../../components';
import { LanguageOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { PATH } from '../routes/routes';
import { useStateSelector, supportsRegistration, LoginType } from '../../services';
import { useCallback } from 'react';

const useStyle = makeStyles<Theme, {}>(theme =>
  createStyles({
    grid: {
      minWidth: '400px',
      [theme.breakpoints.down('sm')]: {
        minWidth: '300px'
      }
    }
  })
);

const CountryAndLanguageSelection: React.FC = () => {
  const { t } = useTranslation('account');
  const { push } = useHistory();
  const classes = useStyle({});
  const { countryId, loginType } = useStateSelector('system');

  const allowsLogin = supportsRegistration(countryId, loginType);

  const handleContinue = useCallback(() => {
    const path = allowsLogin
      ? PATH.INDEX.SIGN_IN_OPTIONS
      : loginType === LoginType.ParticipantLink
      ? PATH.INDEX.PARTICIPANT
      : PATH.INDEX.GUEST;
    push(path);
  }, [allowsLogin, push, loginType]);

  return (
    <Swipe direction="left">
      <Stack data-testid="country-and-language-select" gap={3} minWidth="350px" className={classes.grid}>
        <Box display="flex" alignItems="center">
          <LanguageOutlined color="primary" fontSize="large" />
          <Space gap={2} horizontal />
          <Typography variant="h5"> {t('localization')}</Typography>
        </Box>
        <Typography variant="body1">{t('selectCountryAndLanguage')}</Typography>
        <CountrySelection />
        <LanguageSelection />
        <Button size="large" onClick={handleContinue}>
          {t('continue')}
        </Button>
      </Stack>
    </Swipe>
  );
};

export default CountryAndLanguageSelection;
