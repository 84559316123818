import { FC } from 'react';

import { actions, questions, formatter } from 'services';
import { useDispatch } from 'react-redux';
import { SelectProps } from '@mui/material/Select';
import { ColumnAnswerComponentProps } from './ColumnAnswerRenderer';
import { SelectionBox } from '../../SelectionBox/SelectionBox';

export const ColumnSelectBoxAnswer: FC<ColumnAnswerComponentProps> = props => {
  const { column, row } = props;
  const dispatch = useDispatch();
  const handleAnswerChanged: SelectProps['onChange'] = e => {
    dispatch(
      actions.answerTableFixedQuestion(
        props,
        column.Answers.find(ans => ans.Id === parseInt(e.target.value as string, 10)) || column.Answers[0]
      )
    );
  };

  const answer = questions.answerFor(column);
  const defaultValue = answer?.Id || '';

  const columnTitleText = formatter.columnTitle(column, row);
  return (
    <SelectionBox
      onChange={handleAnswerChanged}
      label={columnTitleText.text}
      value={defaultValue}
      options={[
        {
          value: '',
          label: '---'
        },
        ...column.Answers.map(ans => ({
          value: ans.Id,
          label: ans.Value
        }))
      ]}
    />
  );
};
