import { FC, ChangeEventHandler } from 'react';
import { actions, questions } from 'services';
import { useDispatch } from 'react-redux';
import { ColumnAnswerComponentProps } from './ColumnAnswerRenderer';
import { TextQuestionComponent } from '..';
import { formatter } from 'services';

export const ColumnTextAnswer: FC<ColumnAnswerComponentProps> = props => {
  const { column, row } = props;
  const dispatch = useDispatch();
  const isNumeric = questions.isNumeric(column);
  const handleAnswer: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
    const value = isNumeric ? e.target.value.replace(/\D/g, '') : e.target.value;
    dispatch(actions.answerTableTextQuestion(props, value));
  };
  const defaultValue = questions.answerTextFor(column);

  return (
    <TextQuestionComponent
      label={formatter.columnTitle(column, row).text}
      // label={formatter.rowTitle(row).text}
      type={questions.isMultiline(column) ? 'multiline' : questions.isNumeric(column) ? 'number' : 'text'}
      value={defaultValue}
      onChange={handleAnswer}
    />
  );
};
