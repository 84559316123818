import { SignInForm, useHandler, useNotification, Helmet } from 'components';
import { store, Token, UserInfo } from 'services';
import { useHistory } from 'react-router';
import { PATH } from '../routes/routes';
import { useTranslation } from 'react-i18next';
import { useAsyncDispatch } from '../hooks/useAsyncDispatch';

const SignIn = () => {
  const dispatch = useAsyncDispatch();
  const { push } = useHistory();
  const { t: tAccount } = useTranslation('account');
  const { notify } = useNotification();
  const { t: tError } = useTranslation('errors');
  const { signInUsingProbe } = store.actions.user;

  const onSignInSuccess = useHandler(async (token: Token, userInfo: UserInfo) => {
    try {
      await dispatch(signInUsingProbe(token, userInfo));
    } catch (error: any) {
      notify({ message: tError(error.message), type: 'error' });
    }
    push(PATH.INDEX.ROOT);
  });

  const onClickRegister = useHandler(() => push(PATH.INDEX.REGISTER));

  return (
    <>
      <Helmet>
        <title> {tAccount('signIn')} </title>
      </Helmet>
      <SignInForm onSignInSuccess={onSignInSuccess} onClickRegister={onClickRegister} />
    </>
  );
};

export default SignIn;
