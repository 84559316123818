import { Typography, Box, Link, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { PATH } from '../routes/routes';
import { useTheme } from '@mui/material/styles';
import { CONFIG } from '../../services';
import { styled } from '@mui/system';

const APP_VERSION = CONFIG.isProd ? CONFIG.appVersion : `S-${CONFIG.appVersion}`;

const LinkContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'space-around'
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    marginTop: theme.spacing(2)
  }
}));

const LinkEntry = (props: any) => {
  return <Link color="primary" sx={{ marginRight: 1, marginLeft: 1 }} {...props}></Link>;
};

export const Footer = () => {
  const { t } = useTranslation('settings');
  const { t: tMenu } = useTranslation('menu');
  const { push } = useHistory();
  const theme = useTheme();
  const smallAndUp = useMediaQuery(theme.breakpoints.up('sm'));
  const navigateTo = (route: string) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    push(route);
  };

  return (
    <Box textAlign="center" m={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="caption">
        Copyright &copy; {new Date().getFullYear()} Patient Outcomes Research Group Ltd. All Rights Reserved.
      </Typography>
      <Typography variant="caption">
        <LinkContainer>
          <LinkEntry onClick={navigateTo(PATH.INDEX.PRIVACY)}>{t('privacyPolicy')}</LinkEntry>
          {smallAndUp && ' | '}
          <LinkEntry onClick={navigateTo(PATH.INDEX.TERMS)}>{t('userAgreement')}</LinkEntry>
          {smallAndUp && ' | '}
          <LinkEntry href="http://www.probestudy.org/">{tMenu('probeStudyWebsite')}</LinkEntry>
        </LinkContainer>
      </Typography>
      <Typography variant="caption" sx={{ marginTop: 1 }}>
        {t('version')}: {APP_VERSION}
      </Typography>
    </Box>
  );
};
