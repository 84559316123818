import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { EN, locals, dialectFor } from '../languages-list/locals';
import axios from 'axios';

export const initialLanguage = EN;

export const localizationInit = () => {
  i18n
    .use(detector)
    .use(initReactI18next)
    .init({
      load: 'languageOnly',
      fallbackLng: initialLanguage,
      debug: process.env.NODE_ENV === 'development',
      keySeparator: '.',

      interpolation: {
        escapeValue: false // React does this automatically
      },
      resources: locals,
      defaultNS: 'common'
    });
  handleLanguageLoad();
  i18n.on('languageChanged', handleLanguageLoad);
};

const fetchLang = async (lang: string) => {
  return (i18n.getDataByLanguage(lang) || (await axios.get('/assets/locals/' + lang + '.json')).data) as Record<
    string,
    any
  >;
};

const loadLanguage = async (lang: string) => {
  const language = dialectFor(lang);
  const data = await fetchLang(language.code);

  Object.keys(data).forEach((ns: string) => {
    i18n.addResources(lang, ns, data[ns]);
  });
};

const handleLanguageLoad = async () => {
  const lang = i18n.language;
  const data = i18n.getDataByLanguage(lang);

  if (data) {
    return;
  }
  try {
    await loadLanguage(lang);
    i18n.changeLanguage(lang);
  } catch (error) {
    //console.log(error);
  }
};
