import { api, useStateSelector, Question, actions } from 'services';
import { useState } from 'react';
import {
  useEffectOnQuestionAnswerChanged,
  useEffectOnQuestionChanged,
  useCurrentQuestion,
  useNotification
} from 'components';
import { useDispatch } from 'react-redux';

export const useUploadCurrentQuestion = () => {
  const { token } = useStateSelector('user');
  const { InstanceId } = useStateSelector('survey');
  const { notify } = useNotification();

  return (currentQuestion: Question) => {
    if (!token) {
      return;
    }
    (async () => {
      try {
        await api.uploadQuestionsAsync([currentQuestion], InstanceId, token);
      } catch (error) {
        notify({ type: 'error', message: error.message });
      }
    })();
  };
};

export const useAutoSyncQuestion = () => {
  const currentQuestion = useCurrentQuestion();
  const uploadQuestion = useUploadCurrentQuestion();
  const [isQuestionAnswered, setIsQuestionAnswered] = useState(false);
  const [answeredQuestion, setAnsweredQuestion] = useState(currentQuestion);
  const dispatch = useDispatch();

  useEffectOnQuestionAnswerChanged(() => {
    setIsQuestionAnswered(true);
    setAnsweredQuestion(currentQuestion);
  });

  useEffectOnQuestionChanged(() => {
    if (isQuestionAnswered) {
      setIsQuestionAnswered(false);
      uploadQuestion(answeredQuestion);
      dispatch(actions.updateSurveyTemp());
    }
  });
};
