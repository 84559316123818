import { isTable, tableFrom } from './questions';
import {
  IAnswerable,
  Question,
  Row,
  SerializableAnswerable,
  SerializableRow,
  SerializedQuestion,
  SerializedTable,
  Table
} from './types';

export const toSerializable = (question: Question): SerializedQuestion => {
  const { Id, ProcessingOrder } = question;

  if (!isTable(question)) {
    return {
      ...toSerializedAnswerable(question),
      ProcessingOrder
    };
  }

  const table = tableFrom(question);
  return {
    Id,
    ProcessingOrder,
    Table: toSerializedTable(table)
  };
};

const toSerializedTable = (table: Table): SerializedTable => {
  return {
    Id: table.Id,
    Rows: table.Rows.map(toSerializedRow)
  };
};

const toSerializedRow = (row: Row): SerializableRow => {
  return {
    Id: row.Id,
    Columns: row.Columns.map(toSerializedAnswerable),
    DisplayOrder: row.DisplayOrder
  };
};

const toSerializedAnswerable = (answerable: IAnswerable): SerializableAnswerable => {
  return {
    Id: answerable.Id,
    Result: answerable.Result
  };
};
