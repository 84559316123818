import { now } from '../time-helpers';
import { Question, ValidationOutcome } from '../types';
import { minMaxValidator, unitsValidator } from '../validator';

const year = now().getFullYear();

const validators: Record<number, (question: Question) => ValidationOutcome> = {
  // Year of birth
  720: minMaxValidator('validators:yearOfBirth', {
    min: year - 94,
    max: year - 10
  }),

  // Weight
  721: unitsValidator('validators:weight', {
    // Kilograms
    335: {
      min: 9,
      max: 159
    },
    // Pounds
    336: {
      min: 21,
      max: 350
    }
  }),

  // Height
  732: unitsValidator('validators:height', {
    // Centimetres
    381: {
      min: 100,
      max: 220
    },
    // Inches
    382: {
      min: 40,
      max: 87
    }
  }),

  // How old were you when you first started school
  723: minMaxValidator('validators:age', { min: 0, max: 94 }, 'validators:years'),

  // Years of school/education completed.
  724: minMaxValidator('validators:value', { min: 0, max: 90 }),

  // Percentage of full-time work that your part-time work represents.
  671: minMaxValidator('validators:value', { min: 0, max: 100 }, 'validators:percent'),

  // How many days during the past 12 months were you
  // not able to work or attend school due to health related reasons?
  678: minMaxValidator('validators:value', { min: 0, max: 365 }, 'validators:days')
};

export const getValidatorFor = (questionId: number) => {
  return validators[questionId] || null;
};
