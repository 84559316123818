import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  TableContainerProps
} from '@mui/material';
import { lighten, Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useTableStyle = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      height: 'fit-content'
    },
    tableHeader: {
      lineHeight: '1.5rem',
      textTransform: 'uppercase',
      color: '#3b3e66',
      backgroundColor: '#F4F4F9',
      borderColor: '#dcdef1'
    },
    tableRow: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover
      },
      '&:hover': {
        backgroundColor: lighten(theme.palette.primary.main, 0.75)
      },
      cursor: 'pointer'
    },
    tableCell: {
      whiteSpace: 'nowrap',
      padding: theme.spacing(2)
    },
    tableHighlight: {
      backgroundColor: lighten(theme.palette.primary.main, 0.75)
    },
    tableCellActive: {
      backgroundColor: lighten(theme.palette.secondary.main, 0.75)
    },
    chip: {
      marginLeft: theme.spacing(2)
    }
  })
);

export type CustomTableProps = {
  headers: string[];
  rows: TableCellProps[][];
} & TableContainerProps;

export const CustomTable: React.FC<CustomTableProps> = ({ headers, rows, ...tableContainerProps }) => {
  const tableClasses = useTableStyle();

  return (
    <TableContainer
      {...tableContainerProps}
      component={Paper}
      className={`${tableClasses.root} ${tableContainerProps.className}`}
    >
      <Table aria-label="customized table" stickyHeader>
        <TableHead>
          <TableRow>
            {headers.map((headerValue, hIndex) => (
              <TableCell
                key={headerValue}
                style={{ textAlign: hIndex !== 0 ? 'center' : 'left' }}
                className={tableClasses.tableHeader}
              >
                {headerValue}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((cells, rIndex) => (
            <TableRow key={rIndex} className={tableClasses.tableRow}>
              {cells.map((cellProps, cIndex) => (
                <TableCell key={`${rIndex}-${cIndex}`} {...cellProps} />
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
