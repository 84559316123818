import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHomeStyles } from '../../styles/Home.style';
import { CircularProgress, Typography } from '@mui/material';
interface StaticCircularProgressProps {
  percent: number;
}

const StaticCircularProgress = ({ percent }: StaticCircularProgressProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const circularRadius = isMobile ? 95 : 160;
  const classes = useHomeStyles();

  return (
    <div className={classes.progress}>
      <CircularProgress variant="determinate" value={percent} size={circularRadius} />
      <Typography variant="h3" className={classes.progressLabel}>
        {percent}%
      </Typography>
    </div>
  );
};

export default StaticCircularProgress;
