import { useState, useContext, createContext, useMemo, FC, ReactNode } from 'react';
import { SnackBar } from './SnackBar';

export type NotificationInterface = {
  type: 'error' | 'success' | null;
  message: string;
};

interface NotificationContextProps {
  notify: (notification: NotificationInterface) => void;
  notification: NotificationInterface;
}

const NotificationContext = createContext<NotificationContextProps>({
  notify: () => {},
  notification: { type: null, message: '' }
});

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [notification, notify] = useState<NotificationInterface>({
    type: null,
    message: ''
  });

  const value = useMemo(
    () => ({
      notification,
      notify
    }),
    [notification]
  );

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

export const Notification = () => {
  const { notification, notify } = useContext(NotificationContext);
  const handleClose = () => notify({ message: '', type: null });
  return (
    <SnackBar
      type={notification.type}
      open={!!notification.message}
      message={notification.message}
      onClose={handleClose}
    />
  );
};
