/* Generated by restful-react */

import React from 'react';
import { Get, GetProps, useGet, UseGetProps, Mutate, MutateProps, useMutate, UseMutateProps } from 'restful-react';
export const SPEC_VERSION = 'v1';
export interface AccountControllerCacheToken {
  access_token?: string;
  refresh_token?: string;
}

export interface AccountV1ValidLinkRequest {
  guid?: string;
}

export interface AccountV1ValidLinkResponse {
  isValidLink?: boolean;
}

export interface AccountV1PrivacyRequest {
  /**
   * Country
   */
  CountryId?: number;
  /**
   * Language
   */
  LanguageId?: number;
  /**
   * indicates if all required parameters are greater than 0
   */
  IsValid?: boolean;
}

/**
 * Privacy text to be returned in the specified language
 */
export interface AccountV1PrivacyResponse {
  /**
   * Html formatted Privacy text
   */
  Html?: string;
}

export interface AccountV1ForgotPasswordRequest {
  Email?: string;
}

export interface AccountV1RegisterAnonRequest {
  CountryId?: number;
  LanguageId?: number;
}

export interface AccountV1RegisterAnonResponse {
  oAuth?: {
    [key: string]: string;
  };
  UserId?: number;
}

/**
 * Terms and Conditions to be returned in the specified language
 */
export interface AccountV1TermsResponse {
  /**
   * Html formatted Terms and Conditions
   */
  Html?: string;
}

export interface AccountV1UserInfoResponse {
  Anonymous?: boolean;
  CountryId?: number;
  Email?: string;
  LanguageId?: number;
  Locale?: string;
  Name?: string;
  ProviderInfo?: AccountV1UserInfo[];
  UserName?: string;
}

export interface AccountV1UserInfo {
  Email?: string;
  Name?: string;
  Provider?: string;
  UserName?: string;
}

export interface AccountV1RegisterOAuthRequest {
  Provider?: string;
  Token?: string;
  StudyParticipantGuid?: string;
}

export interface AccountV1RegisterOAuthResponse {
  Name?: string;
  UserId?: number;
}

export interface AccountV1ChangePasswordRequest {
  CurrentPassword?: string;
  NewPassword?: string;
}

export interface CompositeLibViewModelsUserUpdateRequest {
  CountryId?: number;
  Email?: string;
  LanguageId?: number;
}

export interface AccountV1RegisterRequest {
  CountryId?: number;
  LanguageId?: number;
  UserName?: string;
  Password: string;
  PhoneNumber?: string;
  Email?: string;
  StudyParticipantGuid?: string;
}

export interface AccountV1RegisterResponse {
  UserId?: number;
}

export interface CompositeSurveysViewModelsAPIV1SaveRequest {
  InstanceId?: number;
  LanguageCode?: string;
  ProjectId?: number;
  Questions?: CompositeSurveysClassesAPIQuestion[];
  UserId?: number;
}

export interface CompositeSurveysClassesAPIQuestion {
  Answers?: CompositeSurveysClassesAPIAnswer[];
  DependencyExpression?: string;
  DisplayOrder?: number;
  ExternalId?: number;
  ExternalSurveyId?: number;
  Id?: number;
  ProcessingOrder?: number;
  QuestionType?: CompositeSurveysClassesAPIQuestionType;
  Required?: boolean;
  Result?: CompositeSurveysClassesAPIResult[];
  SourceSurveyId?: number;
  Supplements?: {
    [key: string]: {
      [key: string]: string;
    };
  };
  Table?: CompositeSurveysClassesAPITable;
  Text?: string;
  Visible?: boolean;
}

export interface CompositeSurveysClassesAPIAnswer {
  Id?: number;
  Value?: string;
}

export interface CompositeSurveysClassesAPIQuestionType {
  Id?: number;
  Description?: string;
}

export interface CompositeSurveysClassesAPIResult {
  AnswerId?: number;
  InputText?: string;
}

export interface CompositeSurveysClassesAPITable {
  Id?: number;
  GroupColumns?: boolean;
  Rows?: CompositeSurveysClassesAPIRow[];
  TableType?: CompositeSurveysClassesAPITableType;
}

export interface CompositeSurveysClassesAPIRow {
  Columns?: CompositeSurveysClassesAPIColumn[];
  DisplayOrder?: number;
  GroupColumns?: boolean;
  Header?: string;
  Id?: number;
  Repeating?: boolean;
  Required?: boolean;
}

export interface CompositeSurveysClassesAPITableType {
  Id?: number;
  Description?: string;
}

export interface CompositeSurveysClassesAPIColumn {
  Answers?: CompositeSurveysClassesAPIAnswer[];
  DisplayOrder?: number;
  Grouping?: boolean;
  Header?: string;
  HideAnswerText?: boolean;
  Id?: number;
  QuestionType?: CompositeSurveysClassesAPIQuestionType;
  Required?: boolean;
  Result?: CompositeSurveysClassesAPIResult[];
}

export interface CompositeSurveysViewModelsAPIV1SaveResponse {
  Failed?: number[];
  Processing?: number;
  Saved?: number[];
  Success?: boolean;
  ViolateRequisites?: number[];
  ToProcess?: number[];
}

/**
 * information required to obtain scores for an instance
 */
export interface CompositeV1MetricsRequest {
  /**
   * the instance to query
   */
  InstanceId?: number;
}

/**
 * Any metrics of the instance we may want to report
 */
export interface CompositeV1MetricsResponse {
  /**
   * The EQ5D Score (currently Global value is always used)
   */
  EQ5DScore?: number;
  /**
   * The Probe score
   */
  ProbeScore?: number;
}

export interface StudyV1ReadOnlyLinkRequest {
  InstanceId?: number;
  ReadOnlyGuid?: string;
  UserIdentifier?: string;
}

export interface CompositeV1SharedInstanceResponse {
  AssignedParticipantIdentifier?: string;
  ProbeScore?: number;
  EQ5DScore?: number;
  EQ5DVas?: number;
  AlternateText?: CompositeSurveysClassesAPIAlternateText[];
  CreatedOn?: string;
  ExpiresOn?: string;
  Id?: number;
  InstanceId?: number;
  Instructions?: string;
  LanguageCode?: string;
  ProjectId?: number;
  Requisites?: CompositeSurveysClassesAPIRequisite[];
  Sections?: CompositeSurveysClassesAPISection[];
  Title?: string;
}

export interface CompositeSurveysClassesAPIAlternateText {
  Id?: number;
  ConnectionString?: string;
  ResourceSet?: string;
  SourceId?: number;
}

export interface CompositeSurveysClassesAPIRequisite {
  AnswerId?: number;
  QuestionId?: number;
  RequisiteId?: number;
}

export interface CompositeSurveysClassesAPISection {
  Id?: number;
  Title?: string;
  Questions?: CompositeSurveysClassesAPIQuestion[];
}

export interface CompositeSurveysViewModelsAPIV1SubmitRequest {
  ForceSubmit?: boolean;
  InstanceId?: number;
  Metrics?: {
    [key: string]: number;
  };
  ProjectId?: number;
  UserId?: number;
}

export interface CompositeSurveysViewModelsAPIV1SubmitResponse {
  Complete?: boolean;
  Incomplete?: number[];
  Metrics?: {
    [key: string]: number;
  };
  Submitted?: boolean;
  ViolateRequisites?: number[];
}

export interface CompositeSurveysViewModelsAPIV1HistoryRequest {
  CompositeId?: number;
  ProjectId?: number;
  UserId?: number;
}

export interface CompositeSurveysViewModelsAPIV1HistoryResponse {
  Instances?: CompositeSurveysViewModelsAPIV1History[];
}

export interface CompositeSurveysViewModelsAPIV1History {
  Completed?: boolean;
  InstanceId?: number;
  Submitted?: string;
}

export interface CompositeSurveysViewModelsAPIInstanceRequest {
  AlternateText?: number[];
  CompositeId?: number;
  InstanceId?: number;
  LanguageCode?: string;
  ProjectId?: number;
  UserId?: number;
}

export interface CompositeSurveysClassesAPICompositeSurvey {
  AlternateText?: CompositeSurveysClassesAPIAlternateText[];
  CreatedOn?: string;
  ExpiresOn?: string;
  Id?: number;
  InstanceId?: number;
  Instructions?: string;
  LanguageCode?: string;
  ProjectId?: number;
  Requisites?: CompositeSurveysClassesAPIRequisite[];
  Sections?: CompositeSurveysClassesAPISection[];
  Title?: string;
}

/**
 * Data needed to facilitate the retrieval of a survey
 */
export interface CompositeV1SurveyRequest {
  /**
   * An array indicating the order of preference for AlternateText that may exist
   */
  AlternateText?: number[];
  /**
   * The Id of the composite survey to retrieve
   */
  CompositeId?: number;
  /**
   * The User to which the survey belongs
   */
  UserId?: number;
}

export interface CompositeV1UserRegisterExternalRequest {
  CompositeId?: number;
  RegistrationData?: CompositeLibViewModelsRegistrationData;
}

export interface CompositeLibViewModelsRegistrationData {
  Anonymous?: boolean;
  CountryId?: number;
  Email?: string;
  FirstName?: string;
  LastName?: string;
  LanguageId?: number;
  Password?: string;
  PIN?: string;
  UserName?: string;
}

export interface CompositeV1UserIsRegisteredRequest {
  CompositeId?: number;
}

export interface CompositeV1IsRegisteredResponse {
  FullyRegistered?: boolean;
  MissingData?: CompositeLibViewModelsRegistrationItem[];
}

export interface CompositeLibViewModelsRegistrationItem {
  Name?: string;
  Optional?: boolean;
  Type?: string;
}

/**
 * Collection of information about an in-progress survey
 */
export interface StudyV1OnGoingSurveyResponse {
  /**
   * Composite Id associated with the instance
   */
  CompositeId?: number;
  /**
   * Id of the instance
   */
  InstanceId?: number;
  /**
   * Id of the related study
   */
  StudyId?: number;
  /**
   * Name of the related study
   */
  StudyName?: string;
  /**
   * Indicates when the instance expires
   */
  Expires?: string;
}

/**
 * Collection of information about a previosuly completed survey
 */
export interface StudyV1PastSurveyResponse {
  /**
   * Name of the study contact
   */
  ContactName?: string;
  /**
   * Email addresses of study contacts
   */
  Contacts?: string[];
  /**
   * Date the survey was completed
   */
  DateCompleted?: string;
  /**
   * Calculated EQ5D Score
   */
  EQ5DScore?: number;
  /**
   * Calculated VAS Score
   */
  EQ5DVas?: number;
  /**
   * Id of the instance
   */
  InstanceId?: number;
  /**
   * Calculated Probe score
   */
  ProbeScore?: number;
  /**
   * Name of the survey instance
   */
  SurveyName?: string;
  /**
   * Name of the related study
   */
  StudyName?: string;
}

/**
 * Any StudyMetaData
 */
export interface StudyV1MetaDataResponse {
  /**
   * Composite Ids associated with the study
   */
  CompositeIds?: number[];
  /**
   * Contact Name for the study
   */
  ContactName?: string;
  /**
   * Main contact person for the study
   */
  Contacts?: string[];
  CurrentStep?: StudyV1ParticipantCurrentStepResponse;
  /**
   * The study description
   */
  Description?: string;
  /**
   * The study name/title
   */
  Name?: string;
  /**
   * Indicates if this is a "Probe Like" study (Assumption?: uses Composite Id 5?)
   */
  ProbeLike?: boolean;
  /**
   * Study Id the meta data is associated with
   */
  StudyId?: number;
}

/**
 * Data about hte participants current step for a study
 */
export interface StudyV1ParticipantCurrentStepResponse {
  /**
   * The date that the current step is available to be completed
   */
  AvailabilityDate?: string;
  /**
   * The composite Id associated with the current study step
   */
  CompositeId?: number;
  /**
   * The order of the current step in the study sequence
   */
  Order?: number;
  /**
   * The participant Id of the user in the study
   */
  ParticipantId?: number;
  /**
   * Current status of the step (Available, Unavailable, Completed)
   */
  Status?: 'Available' | 'Completed' | 'Unavailable';
  /**
   * The study Id the step belongs to
   */
  StudyId?: number;
  /**
   * The id of the study step
   */
  StudyStepId?: number;
}

export interface AccountControllerGuidLoginRequest {
  guid?: string;
}

export interface StudyV1CandidateRequest {
  ExternalId?: string;
  StudyId?: number;
}

export interface StudyV1CandidateResponse {
  Token?: string;
  isExisting?: boolean;
}

/**
 * Request object for enrolling a user as a participant in a study
 */
export interface StudyV1EnrollmentRequest {
  /**
   * A study specific or participant specific Guid
   */
  Token?: string;
}

/**
 * Data needed to facilitate the retrieval of a survey as part of a study
 */
export interface StudyV1SurveyRequest {
  /**
   * The Study Id to which the composite belongs
   */
  StudyId?: number;
  /**
   * An array indicating the order of preference for AlternateText that may exist
   */
  AlternateText?: number[];
  /**
   * The Id of the composite survey to retrieve
   */
  CompositeId?: number;
  /**
   * The User to which the survey belongs
   */
  UserId?: number;
}

/**
 * information required to obtain a Study MetaData
 */
export interface StudyV1ConfigurationRequest {
  /**
   * Participant GUID
   */
  Token?: string;
}

/**
 * Any StudyMetaData
 */
export interface StudyV1ConfigurationResponse {
  /**
   * Id of the study
   */
  StudyId?: number;
  /**
   * Non-localized name of the study
   */
  Name?: string;
  /**
   * List of available login types for this study (Guest = 0, Guid = 1, Probe = 2, ThirdPartyApp = 3)
   */
  LoginTypes?: ('Guest' | 'ParticipantLink' | 'Probe' | 'ThirdPartyApp')[];
  AuthorizedCountries?: number[];
  AuthorizedLanguages?: number[];
  AdditionalInfo?: string;
  ParticipantInfo?: StudyV1ParticipantIsLinkedResponse;
}

export interface StudyV1ParticipantIsLinkedResponse {
  FirstLogin?: boolean;
  UrlLoginConfirmed?: boolean;
  ParticipantLinked?: boolean;
  Provider?: string;
  Guest?: boolean;
}

export interface StudyV1ParticipantIsLinkedRequest {
  ParticipantGuid?: string;
}

/**
 * Request object to obtain a participant's current step for a given study
 */
export interface StudyV1ParticipantCurrentStepRequest {
  /**
   * Id of the user in question
   */
  UserId?: number;
  /**
   * The study in question
   */
  StudyId?: number;
}

/**
 * information required to obtain a Study MetaData
 */
export interface StudyV1MetaDataRequest {
  /**
   * the study to query
   */
  StudyId?: number;
}

/**
 * represents a language in the database
 */
export interface UtilityV1Language {
  /**
   * The 2 or 3 letter code for this country
   */
  Code?: string;
  /**
   * the name of the language in English
   */
  Description?: string;
  /**
   * the id of the language in the database
   */
  Id?: number;
  /**
   * The native name of the language
   */
  Name?: string;
}

/**
 * represents a country in the database
 */
export interface UtilityV1Country {
  /**
   * The 2 letter code for this country
   */
  Code?: string;
  /**
   * the name of the country in English
   */
  Description?: string;
  /**
   * the id of the country in the database
   */
  Id?: number;
  /**
   * The native name of the country
   */
  Name?: string;
}

/**
 * Parameters needed for OAuth2 Token requests
 */
export interface ProbeResearchApiControllersTokenControllerTokenRequest {
  /**
   * The OAuth2 grant type (ie password or refresh_token)
   */
  grant_type?: string;
  /**
   * The user's username
   */
  username?: string;
  /**
   * The user's password
   */
  password?: string;
  /**
   * The refresh token previously issued to the application
   */
  refresh_token?: string;
}

export type StudyV1ReadOnlyLinkRequestRequestBody = StudyV1ReadOnlyLinkRequest;

/**
 * The UserId and CompositeId in the CompositeDbContext
 */
export type CompositeSurveysViewModelsAPIV1SubmitRequestRequestBody = CompositeSurveysViewModelsAPIV1SubmitRequest;

/**
 * The UserId and CompositeId in the CompositeDbContext
 */
export type CompositeSurveysViewModelsAPIV1SaveRequestRequestBody = CompositeSurveysViewModelsAPIV1SaveRequest;

export interface AccountGetTokenCacheV1QueryParams {
  guid: string;
}

export type AccountGetTokenCacheV1Props = Omit<
  GetProps<AccountControllerCacheToken, unknown, AccountGetTokenCacheV1QueryParams, void>,
  'path'
>;

/**
 * Accept a guid, returning the associated access/refresh token pair
 *
 * Cached tokens will only remain cached for 5 minutes
 */
export const AccountGetTokenCacheV1 = (props: AccountGetTokenCacheV1Props) => (
  <Get<AccountControllerCacheToken, unknown, AccountGetTokenCacheV1QueryParams, void>
    path={`/api/v1/Account/TokenCache`}
    {...props}
  />
);

export type UseAccountGetTokenCacheV1Props = Omit<
  UseGetProps<AccountControllerCacheToken, unknown, AccountGetTokenCacheV1QueryParams, void>,
  'path'
>;

/**
 * Accept a guid, returning the associated access/refresh token pair
 *
 * Cached tokens will only remain cached for 5 minutes
 */
export const useAccountGetTokenCacheV1 = (props: UseAccountGetTokenCacheV1Props) =>
  useGet<AccountControllerCacheToken, unknown, AccountGetTokenCacheV1QueryParams, void>(
    `/api/v1/Account/TokenCache`,
    props
  );

export type AccountPostTokenCacheV1Props = Omit<
  MutateProps<string, unknown, void, AccountControllerCacheToken, void>,
  'path' | 'verb'
>;

/**
 * Accept access and refresh token and caches them, returning a Guid to later retrieve them
 *
 * Cached tokens will only remain cached for 5 minutes
 */
export const AccountPostTokenCacheV1 = (props: AccountPostTokenCacheV1Props) => (
  <Mutate<string, unknown, void, AccountControllerCacheToken, void>
    verb="POST"
    path={`/api/v1/Account/TokenCache`}
    {...props}
  />
);

export type UseAccountPostTokenCacheV1Props = Omit<
  UseMutateProps<string, unknown, void, AccountControllerCacheToken, void>,
  'path' | 'verb'
>;

/**
 * Accept access and refresh token and caches them, returning a Guid to later retrieve them
 *
 * Cached tokens will only remain cached for 5 minutes
 */
export const useAccountPostTokenCacheV1 = (props: UseAccountPostTokenCacheV1Props) =>
  useMutate<string, unknown, void, AccountControllerCacheToken, void>('POST', `/api/v1/Account/TokenCache`, props);

export interface AccountValidLinkQueryParams {
  guid?: string;
}

export type AccountValidLinkProps = Omit<
  GetProps<AccountV1ValidLinkResponse, unknown, AccountValidLinkQueryParams, void>,
  'path'
>;

/**
 * This endpoint check whether a guid belongs to the currently logged-in user or not
 */
export const AccountValidLink = (props: AccountValidLinkProps) => (
  <Get<AccountV1ValidLinkResponse, unknown, AccountValidLinkQueryParams, void>
    path={`/api/v1/Account/ValidLink`}
    {...props}
  />
);

export type UseAccountValidLinkProps = Omit<
  UseGetProps<AccountV1ValidLinkResponse, unknown, AccountValidLinkQueryParams, void>,
  'path'
>;

/**
 * This endpoint check whether a guid belongs to the currently logged-in user or not
 */
export const useAccountValidLink = (props: UseAccountValidLinkProps) =>
  useGet<AccountV1ValidLinkResponse, unknown, AccountValidLinkQueryParams, void>(`/api/v1/Account/ValidLink`, props);

export interface AccountPrivacyV1QueryParams {
  /**
   * Country
   */
  countryId?: number;
  /**
   * Language
   */
  languageId?: number;
}

export type AccountPrivacyV1Props = Omit<
  GetProps<AccountV1PrivacyResponse, unknown, AccountPrivacyV1QueryParams, void>,
  'path'
>;

/**
 * This method returns the text of the Privacy agreement as Html
 */
export const AccountPrivacyV1 = (props: AccountPrivacyV1Props) => (
  <Get<AccountV1PrivacyResponse, unknown, AccountPrivacyV1QueryParams, void>
    path={`/api/v1/Account/Privacy`}
    {...props}
  />
);

export type UseAccountPrivacyV1Props = Omit<
  UseGetProps<AccountV1PrivacyResponse, unknown, AccountPrivacyV1QueryParams, void>,
  'path'
>;

/**
 * This method returns the text of the Privacy agreement as Html
 */
export const useAccountPrivacyV1 = (props: UseAccountPrivacyV1Props) =>
  useGet<AccountV1PrivacyResponse, unknown, AccountPrivacyV1QueryParams, void>(`/api/v1/Account/Privacy`, props);

export interface AccountForgotPasswordV1AsyncResponse {
  [key: string]: any;
}

export type AccountForgotPasswordV1AsyncProps = Omit<
  MutateProps<AccountForgotPasswordV1AsyncResponse, unknown, void, AccountV1ForgotPasswordRequest, void>,
  'path' | 'verb'
>;

/**
 * This method will generate a password reset email for a user in the ProbeResearch Database
 */
export const AccountForgotPasswordV1Async = (props: AccountForgotPasswordV1AsyncProps) => (
  <Mutate<AccountForgotPasswordV1AsyncResponse, unknown, void, AccountV1ForgotPasswordRequest, void>
    verb="POST"
    path={`/api/v1/Account/ForgotPassword`}
    {...props}
  />
);

export type UseAccountForgotPasswordV1AsyncProps = Omit<
  UseMutateProps<AccountForgotPasswordV1AsyncResponse, unknown, void, AccountV1ForgotPasswordRequest, void>,
  'path' | 'verb'
>;

/**
 * This method will generate a password reset email for a user in the ProbeResearch Database
 */
export const useAccountForgotPasswordV1Async = (props: UseAccountForgotPasswordV1AsyncProps) =>
  useMutate<AccountForgotPasswordV1AsyncResponse, unknown, void, AccountV1ForgotPasswordRequest, void>(
    'POST',
    `/api/v1/Account/ForgotPassword`,
    props
  );

export type AccountRegisterAnonV1Props = Omit<
  MutateProps<AccountV1RegisterAnonResponse, unknown, void, AccountV1RegisterAnonRequest, void>,
  'path' | 'verb'
>;

/**
 * This method will register a user in the ProbeResearch Database
 */
export const AccountRegisterAnonV1 = (props: AccountRegisterAnonV1Props) => (
  <Mutate<AccountV1RegisterAnonResponse, unknown, void, AccountV1RegisterAnonRequest, void>
    verb="POST"
    path={`/api/v1/Account/Register/Anon`}
    {...props}
  />
);

export type UseAccountRegisterAnonV1Props = Omit<
  UseMutateProps<AccountV1RegisterAnonResponse, unknown, void, AccountV1RegisterAnonRequest, void>,
  'path' | 'verb'
>;

/**
 * This method will register a user in the ProbeResearch Database
 */
export const useAccountRegisterAnonV1 = (props: UseAccountRegisterAnonV1Props) =>
  useMutate<AccountV1RegisterAnonResponse, unknown, void, AccountV1RegisterAnonRequest, void>(
    'POST',
    `/api/v1/Account/Register/Anon`,
    props
  );

export interface AccountTermsV1QueryParams {
  /**
   * Country
   */
  countryId?: number;
  /**
   * Language
   */
  languageId?: number;
}

export type AccountTermsV1Props = Omit<
  GetProps<AccountV1TermsResponse, unknown, AccountTermsV1QueryParams, void>,
  'path'
>;

/**
 * This method returns the text of the Terms and Conditions agreement as Html
 */
export const AccountTermsV1 = (props: AccountTermsV1Props) => (
  <Get<AccountV1TermsResponse, unknown, AccountTermsV1QueryParams, void> path={`/api/v1/Account/Terms`} {...props} />
);

export type UseAccountTermsV1Props = Omit<
  UseGetProps<AccountV1TermsResponse, unknown, AccountTermsV1QueryParams, void>,
  'path'
>;

/**
 * This method returns the text of the Terms and Conditions agreement as Html
 */
export const useAccountTermsV1 = (props: UseAccountTermsV1Props) =>
  useGet<AccountV1TermsResponse, unknown, AccountTermsV1QueryParams, void>(`/api/v1/Account/Terms`, props);

export type AccountUserInfoV1Props = Omit<GetProps<AccountV1UserInfoResponse, unknown, void, void>, 'path'>;

/**
 * This method returns information about the user
 */
export const AccountUserInfoV1 = (props: AccountUserInfoV1Props) => (
  <Get<AccountV1UserInfoResponse, unknown, void, void> path={`/api/v1/Account/User/Info`} {...props} />
);

export type UseAccountUserInfoV1Props = Omit<UseGetProps<AccountV1UserInfoResponse, unknown, void, void>, 'path'>;

/**
 * This method returns information about the user
 */
export const useAccountUserInfoV1 = (props: UseAccountUserInfoV1Props) =>
  useGet<AccountV1UserInfoResponse, unknown, void, void>(`/api/v1/Account/User/Info`, props);

export type AccountRegisterOAuthV1Props = Omit<
  MutateProps<AccountV1RegisterOAuthResponse, unknown, void, AccountV1RegisterOAuthRequest, void>,
  'path' | 'verb'
>;

/**
 * This method will authenticate a user using oAuth credentials; if a GUID is passed, the corresponding study participant will be linked to the newly created user.
 */
export const AccountRegisterOAuthV1 = (props: AccountRegisterOAuthV1Props) => (
  <Mutate<AccountV1RegisterOAuthResponse, unknown, void, AccountV1RegisterOAuthRequest, void>
    verb="POST"
    path={`/api/v1/Account/Register/oAuth`}
    {...props}
  />
);

export type UseAccountRegisterOAuthV1Props = Omit<
  UseMutateProps<AccountV1RegisterOAuthResponse, unknown, void, AccountV1RegisterOAuthRequest, void>,
  'path' | 'verb'
>;

/**
 * This method will authenticate a user using oAuth credentials; if a GUID is passed, the corresponding study participant will be linked to the newly created user.
 */
export const useAccountRegisterOAuthV1 = (props: UseAccountRegisterOAuthV1Props) =>
  useMutate<AccountV1RegisterOAuthResponse, unknown, void, AccountV1RegisterOAuthRequest, void>(
    'POST',
    `/api/v1/Account/Register/oAuth`,
    props
  );

export interface AccountChangePasswordV1Response {
  [key: string]: any;
}

export type AccountChangePasswordV1Props = Omit<
  MutateProps<AccountChangePasswordV1Response, unknown, void, AccountV1ChangePasswordRequest, void>,
  'path' | 'verb'
>;

/**
 * This method will update a user in the ProbeResearch Database
 *
 * Available update fields: CountryId, Email, LanguageId, Password
 */
export const AccountChangePasswordV1 = (props: AccountChangePasswordV1Props) => (
  <Mutate<AccountChangePasswordV1Response, unknown, void, AccountV1ChangePasswordRequest, void>
    verb="POST"
    path={`/api/v1/Account/ChangePassword`}
    {...props}
  />
);

export type UseAccountChangePasswordV1Props = Omit<
  UseMutateProps<AccountChangePasswordV1Response, unknown, void, AccountV1ChangePasswordRequest, void>,
  'path' | 'verb'
>;

/**
 * This method will update a user in the ProbeResearch Database
 *
 * Available update fields: CountryId, Email, LanguageId, Password
 */
export const useAccountChangePasswordV1 = (props: UseAccountChangePasswordV1Props) =>
  useMutate<AccountChangePasswordV1Response, unknown, void, AccountV1ChangePasswordRequest, void>(
    'POST',
    `/api/v1/Account/ChangePassword`,
    props
  );

export interface AccountUpdateV1Response {
  [key: string]: any;
}

export type AccountUpdateV1Props = Omit<
  MutateProps<AccountUpdateV1Response, unknown, void, CompositeLibViewModelsUserUpdateRequest, void>,
  'path' | 'verb'
>;

/**
 * This method will update a user in the ProbeResearch Database
 *
 * Available update fields: CountryId, Email, LanguageId, Password
 */
export const AccountUpdateV1 = (props: AccountUpdateV1Props) => (
  <Mutate<AccountUpdateV1Response, unknown, void, CompositeLibViewModelsUserUpdateRequest, void>
    verb="PUT"
    path={`/api/v1/Account/Update`}
    {...props}
  />
);

export type UseAccountUpdateV1Props = Omit<
  UseMutateProps<AccountUpdateV1Response, unknown, void, CompositeLibViewModelsUserUpdateRequest, void>,
  'path' | 'verb'
>;

/**
 * This method will update a user in the ProbeResearch Database
 *
 * Available update fields: CountryId, Email, LanguageId, Password
 */
export const useAccountUpdateV1 = (props: UseAccountUpdateV1Props) =>
  useMutate<AccountUpdateV1Response, unknown, void, CompositeLibViewModelsUserUpdateRequest, void>(
    'PUT',
    `/api/v1/Account/Update`,
    props
  );

export type AccountRegisterV1Props = Omit<
  MutateProps<AccountV1RegisterResponse, unknown, void, AccountV1RegisterRequest, void>,
  'path' | 'verb'
>;

/**
 * This method will register a user in the ProbeResearch Database; if a GUID is passed, the corresponding study participant will be linked to the newly created user.
 */
export const AccountRegisterV1 = (props: AccountRegisterV1Props) => (
  <Mutate<AccountV1RegisterResponse, unknown, void, AccountV1RegisterRequest, void>
    verb="POST"
    path={`/api/v1/Account/Register`}
    {...props}
  />
);

export type UseAccountRegisterV1Props = Omit<
  UseMutateProps<AccountV1RegisterResponse, unknown, void, AccountV1RegisterRequest, void>,
  'path' | 'verb'
>;

/**
 * This method will register a user in the ProbeResearch Database; if a GUID is passed, the corresponding study participant will be linked to the newly created user.
 */
export const useAccountRegisterV1 = (props: UseAccountRegisterV1Props) =>
  useMutate<AccountV1RegisterResponse, unknown, void, AccountV1RegisterRequest, void>(
    'POST',
    `/api/v1/Account/Register`,
    props
  );

export interface AdminKeepAliveV1Response {
  [key: string]: any;
}

export type AdminKeepAliveV1Props = Omit<GetProps<AdminKeepAliveV1Response, unknown, void, void>, 'path'>;

/**
 * An endpoint to hit the database to keep the API from going to sleep in the application pool
 */
export const AdminKeepAliveV1 = (props: AdminKeepAliveV1Props) => (
  <Get<AdminKeepAliveV1Response, unknown, void, void> path={`/api/v1/Admin/KeepAlive`} {...props} />
);

export type UseAdminKeepAliveV1Props = Omit<UseGetProps<AdminKeepAliveV1Response, unknown, void, void>, 'path'>;

/**
 * An endpoint to hit the database to keep the API from going to sleep in the application pool
 */
export const useAdminKeepAliveV1 = (props: UseAdminKeepAliveV1Props) =>
  useGet<AdminKeepAliveV1Response, unknown, void, void>(`/api/v1/Admin/KeepAlive`, props);

export type AdminUpdateInstanceAsyncProps = Omit<
  MutateProps<
    CompositeSurveysViewModelsAPIV1SaveResponse,
    unknown,
    void,
    CompositeSurveysViewModelsAPIV1SaveRequestRequestBody,
    void
  >,
  'path' | 'verb'
>;

/**
 * Retrieves a Composite Survey
 */
export const AdminUpdateInstanceAsync = (props: AdminUpdateInstanceAsyncProps) => (
  <Mutate<
    CompositeSurveysViewModelsAPIV1SaveResponse,
    unknown,
    void,
    CompositeSurveysViewModelsAPIV1SaveRequestRequestBody,
    void
  >
    verb="PUT"
    path={`/api/v1/Admin/Update/Instance`}
    {...props}
  />
);

export type UseAdminUpdateInstanceAsyncProps = Omit<
  UseMutateProps<
    CompositeSurveysViewModelsAPIV1SaveResponse,
    unknown,
    void,
    CompositeSurveysViewModelsAPIV1SaveRequestRequestBody,
    void
  >,
  'path' | 'verb'
>;

/**
 * Retrieves a Composite Survey
 */
export const useAdminUpdateInstanceAsync = (props: UseAdminUpdateInstanceAsyncProps) =>
  useMutate<
    CompositeSurveysViewModelsAPIV1SaveResponse,
    unknown,
    void,
    CompositeSurveysViewModelsAPIV1SaveRequestRequestBody,
    void
  >('PUT', `/api/v1/Admin/Update/Instance`, props);

export interface CompositeGetTempDataQueryParams {
  instanceId: number;
}

export type CompositeGetTempDataProps = Omit<GetProps<string, unknown, CompositeGetTempDataQueryParams, void>, 'path'>;

/**
 * Retrieves any stored TempData attached to an instance
 */
export const CompositeGetTempData = (props: CompositeGetTempDataProps) => (
  <Get<string, unknown, CompositeGetTempDataQueryParams, void>
    path={`/api/v1/Composite/Instance/TempData`}
    {...props}
  />
);

export type UseCompositeGetTempDataProps = Omit<
  UseGetProps<string, unknown, CompositeGetTempDataQueryParams, void>,
  'path'
>;

/**
 * Retrieves any stored TempData attached to an instance
 */
export const useCompositeGetTempData = (props: UseCompositeGetTempDataProps) =>
  useGet<string, unknown, CompositeGetTempDataQueryParams, void>(`/api/v1/Composite/Instance/TempData`, props);

export interface CompositePutTempDataResponse {
  [key: string]: any;
}

export interface CompositePutTempDataQueryParams {
  /**
   * The InstanceId in the CompositeDbContext
   */
  instanceId: number;
}

export type CompositePutTempDataProps = Omit<
  MutateProps<CompositePutTempDataResponse, unknown, CompositePutTempDataQueryParams, void, void>,
  'path' | 'verb'
>;

/**
 * Stores provided TempData for an instance
 */
export const CompositePutTempData = (props: CompositePutTempDataProps) => (
  <Mutate<CompositePutTempDataResponse, unknown, CompositePutTempDataQueryParams, void, void>
    verb="PUT"
    path={`/api/v1/Composite/Instance/TempData`}
    {...props}
  />
);

export type UseCompositePutTempDataProps = Omit<
  UseMutateProps<CompositePutTempDataResponse, unknown, CompositePutTempDataQueryParams, void, void>,
  'path' | 'verb'
>;

/**
 * Stores provided TempData for an instance
 */
export const useCompositePutTempData = (props: UseCompositePutTempDataProps) =>
  useMutate<CompositePutTempDataResponse, unknown, CompositePutTempDataQueryParams, void, void>(
    'PUT',
    `/api/v1/Composite/Instance/TempData`,
    props
  );

export interface CompositeMetricsQueryParams {
  /**
   * the instance to query
   */
  instanceId?: number;
}

export type CompositeMetricsProps = Omit<
  GetProps<CompositeV1MetricsResponse, unknown, CompositeMetricsQueryParams, void>,
  'path'
>;

/**
 * Retrieves a Composite Survey
 */
export const CompositeMetrics = (props: CompositeMetricsProps) => (
  <Get<CompositeV1MetricsResponse, unknown, CompositeMetricsQueryParams, void>
    path={`/api/v1/Composite/Instance/Metrics`}
    {...props}
  />
);

export type UseCompositeMetricsProps = Omit<
  UseGetProps<CompositeV1MetricsResponse, unknown, CompositeMetricsQueryParams, void>,
  'path'
>;

/**
 * Retrieves a Composite Survey
 */
export const useCompositeMetrics = (props: UseCompositeMetricsProps) =>
  useGet<CompositeV1MetricsResponse, unknown, CompositeMetricsQueryParams, void>(
    `/api/v1/Composite/Instance/Metrics`,
    props
  );

export interface CompositeSharedInstanceAsyncQueryParams {
  instanceId?: number;
  readOnlyGuid?: string;
  userIdentifier?: string;
}

export type CompositeSharedInstanceAsyncProps = Omit<
  GetProps<CompositeV1SharedInstanceResponse, unknown, CompositeSharedInstanceAsyncQueryParams, void>,
  'path'
>;

/**
 * Retrieves the instance object of a read only GUID (used for sharing info with the physician).
 */
export const CompositeSharedInstanceAsync = (props: CompositeSharedInstanceAsyncProps) => (
  <Get<CompositeV1SharedInstanceResponse, unknown, CompositeSharedInstanceAsyncQueryParams, void>
    path={`/api/v1/Composite/SharedInstance`}
    {...props}
  />
);

export type UseCompositeSharedInstanceAsyncProps = Omit<
  UseGetProps<CompositeV1SharedInstanceResponse, unknown, CompositeSharedInstanceAsyncQueryParams, void>,
  'path'
>;

/**
 * Retrieves the instance object of a read only GUID (used for sharing info with the physician).
 */
export const useCompositeSharedInstanceAsync = (props: UseCompositeSharedInstanceAsyncProps) =>
  useGet<CompositeV1SharedInstanceResponse, unknown, CompositeSharedInstanceAsyncQueryParams, void>(
    `/api/v1/Composite/SharedInstance`,
    props
  );

export type CompositeAddSharedInstanceAsyncProps = Omit<
  MutateProps<string, unknown, void, StudyV1ReadOnlyLinkRequestRequestBody, void>,
  'path' | 'verb'
>;

/**
 * Generate a read only GUID (used for sharing info with the physician)
 */
export const CompositeAddSharedInstanceAsync = (props: CompositeAddSharedInstanceAsyncProps) => (
  <Mutate<string, unknown, void, StudyV1ReadOnlyLinkRequestRequestBody, void>
    verb="POST"
    path={`/api/v1/Composite/SharedInstance`}
    {...props}
  />
);

export type UseCompositeAddSharedInstanceAsyncProps = Omit<
  UseMutateProps<string, unknown, void, StudyV1ReadOnlyLinkRequestRequestBody, void>,
  'path' | 'verb'
>;

/**
 * Generate a read only GUID (used for sharing info with the physician)
 */
export const useCompositeAddSharedInstanceAsync = (props: UseCompositeAddSharedInstanceAsyncProps) =>
  useMutate<string, unknown, void, StudyV1ReadOnlyLinkRequestRequestBody, void>(
    'POST',
    `/api/v1/Composite/SharedInstance`,
    props
  );

export type CompositeSendEmailProps = Omit<
  MutateProps<string, unknown, void, StudyV1ReadOnlyLinkRequestRequestBody, void>,
  'path' | 'verb'
>;

/**
 * Generate a read only GUID (used for sharing info with the physician)
 */
export const CompositeSendEmail = (props: CompositeSendEmailProps) => (
  <Mutate<string, unknown, void, StudyV1ReadOnlyLinkRequestRequestBody, void>
    verb="POST"
    path={`/api/v1/Composite/SharedInstance/SendEmail`}
    {...props}
  />
);

export type UseCompositeSendEmailProps = Omit<
  UseMutateProps<string, unknown, void, StudyV1ReadOnlyLinkRequestRequestBody, void>,
  'path' | 'verb'
>;

/**
 * Generate a read only GUID (used for sharing info with the physician)
 */
export const useCompositeSendEmail = (props: UseCompositeSendEmailProps) =>
  useMutate<string, unknown, void, StudyV1ReadOnlyLinkRequestRequestBody, void>(
    'POST',
    `/api/v1/Composite/SharedInstance/SendEmail`,
    props
  );

export type CompositeSubmitAsyncProps = Omit<
  MutateProps<
    CompositeSurveysViewModelsAPIV1SubmitResponse,
    unknown,
    void,
    CompositeSurveysViewModelsAPIV1SubmitRequestRequestBody,
    void
  >,
  'path' | 'verb'
>;

/**
 * Retrieves a Composite Survey
 */
export const CompositeSubmitAsync = (props: CompositeSubmitAsyncProps) => (
  <Mutate<
    CompositeSurveysViewModelsAPIV1SubmitResponse,
    unknown,
    void,
    CompositeSurveysViewModelsAPIV1SubmitRequestRequestBody,
    void
  >
    verb="POST"
    path={`/api/v1/Composite/Submit`}
    {...props}
  />
);

export type UseCompositeSubmitAsyncProps = Omit<
  UseMutateProps<
    CompositeSurveysViewModelsAPIV1SubmitResponse,
    unknown,
    void,
    CompositeSurveysViewModelsAPIV1SubmitRequestRequestBody,
    void
  >,
  'path' | 'verb'
>;

/**
 * Retrieves a Composite Survey
 */
export const useCompositeSubmitAsync = (props: UseCompositeSubmitAsyncProps) =>
  useMutate<
    CompositeSurveysViewModelsAPIV1SubmitResponse,
    unknown,
    void,
    CompositeSurveysViewModelsAPIV1SubmitRequestRequestBody,
    void
  >('POST', `/api/v1/Composite/Submit`, props);

export type CompositeQuestionAsyncProps = Omit<
  MutateProps<
    CompositeSurveysViewModelsAPIV1SaveResponse,
    unknown,
    void,
    CompositeSurveysViewModelsAPIV1SaveRequestRequestBody,
    void
  >,
  'path' | 'verb'
>;

/**
 * Retrieves a Composite Survey
 */
export const CompositeQuestionAsync = (props: CompositeQuestionAsyncProps) => (
  <Mutate<
    CompositeSurveysViewModelsAPIV1SaveResponse,
    unknown,
    void,
    CompositeSurveysViewModelsAPIV1SaveRequestRequestBody,
    void
  >
    verb="PUT"
    path={`/api/v1/Composite/Questions/Save`}
    {...props}
  />
);

export type UseCompositeQuestionAsyncProps = Omit<
  UseMutateProps<
    CompositeSurveysViewModelsAPIV1SaveResponse,
    unknown,
    void,
    CompositeSurveysViewModelsAPIV1SaveRequestRequestBody,
    void
  >,
  'path' | 'verb'
>;

/**
 * Retrieves a Composite Survey
 */
export const useCompositeQuestionAsync = (props: UseCompositeQuestionAsyncProps) =>
  useMutate<
    CompositeSurveysViewModelsAPIV1SaveResponse,
    unknown,
    void,
    CompositeSurveysViewModelsAPIV1SaveRequestRequestBody,
    void
  >('PUT', `/api/v1/Composite/Questions/Save`, props);

export interface CompositeHistoryAsyncQueryParams {
  compositeId?: number;
  projectId?: number;
  userId?: number;
}

export type CompositeHistoryAsyncProps = Omit<
  GetProps<CompositeSurveysViewModelsAPIV1HistoryResponse, unknown, CompositeHistoryAsyncQueryParams, void>,
  'path'
>;

/**
 * Retrieves a Composite Survey
 */
export const CompositeHistoryAsync = (props: CompositeHistoryAsyncProps) => (
  <Get<CompositeSurveysViewModelsAPIV1HistoryResponse, unknown, CompositeHistoryAsyncQueryParams, void>
    path={`/api/v1/Composite/History`}
    {...props}
  />
);

export type UseCompositeHistoryAsyncProps = Omit<
  UseGetProps<CompositeSurveysViewModelsAPIV1HistoryResponse, unknown, CompositeHistoryAsyncQueryParams, void>,
  'path'
>;

/**
 * Retrieves a Composite Survey
 */
export const useCompositeHistoryAsync = (props: UseCompositeHistoryAsyncProps) =>
  useGet<CompositeSurveysViewModelsAPIV1HistoryResponse, unknown, CompositeHistoryAsyncQueryParams, void>(
    `/api/v1/Composite/History`,
    props
  );

export interface CompositeInstanceAsyncQueryParams {
  alternateText?: number[];
  compositeId?: number;
  instanceId?: number;
  languageCode?: string;
  projectId?: number;
  userId?: number;
}

export type CompositeInstanceAsyncProps = Omit<
  GetProps<CompositeSurveysClassesAPICompositeSurvey, unknown, CompositeInstanceAsyncQueryParams, void>,
  'path'
>;

/**
 * Retrieves a Composite Survey
 */
export const CompositeInstanceAsync = (props: CompositeInstanceAsyncProps) => (
  <Get<CompositeSurveysClassesAPICompositeSurvey, unknown, CompositeInstanceAsyncQueryParams, void>
    path={`/api/v1/Composite/Instance`}
    {...props}
  />
);

export type UseCompositeInstanceAsyncProps = Omit<
  UseGetProps<CompositeSurveysClassesAPICompositeSurvey, unknown, CompositeInstanceAsyncQueryParams, void>,
  'path'
>;

/**
 * Retrieves a Composite Survey
 */
export const useCompositeInstanceAsync = (props: UseCompositeInstanceAsyncProps) =>
  useGet<CompositeSurveysClassesAPICompositeSurvey, unknown, CompositeInstanceAsyncQueryParams, void>(
    `/api/v1/Composite/Instance`,
    props
  );

export interface CompositeSurveyAsyncQueryParams {
  /**
   * An array indicating the order of preference for AlternateText that may exist
   */
  alternateText?: number[];
  /**
   * The Id of the composite survey to retrieve
   */
  compositeId?: number;
  /**
   * The User to which the survey belongs
   */
  userId?: number;
}

export type CompositeSurveyAsyncProps = Omit<
  GetProps<CompositeSurveysClassesAPICompositeSurvey, unknown, CompositeSurveyAsyncQueryParams, void>,
  'path'
>;

/**
 * Retrieves a Composite Survey
 */
export const CompositeSurveyAsync = (props: CompositeSurveyAsyncProps) => (
  <Get<CompositeSurveysClassesAPICompositeSurvey, unknown, CompositeSurveyAsyncQueryParams, void>
    path={`/api/v1/Composite/Survey`}
    {...props}
  />
);

export type UseCompositeSurveyAsyncProps = Omit<
  UseGetProps<CompositeSurveysClassesAPICompositeSurvey, unknown, CompositeSurveyAsyncQueryParams, void>,
  'path'
>;

/**
 * Retrieves a Composite Survey
 */
export const useCompositeSurveyAsync = (props: UseCompositeSurveyAsyncProps) =>
  useGet<CompositeSurveysClassesAPICompositeSurvey, unknown, CompositeSurveyAsyncQueryParams, void>(
    `/api/v1/Composite/Survey`,
    props
  );

export interface CompositeSurveyNewAsyncQueryParams {
  /**
   * An array indicating the order of preference for AlternateText that may exist
   */
  alternateText?: number[];
  /**
   * The Id of the composite survey to retrieve
   */
  compositeId?: number;
  /**
   * The User to which the survey belongs
   */
  userId?: number;
}

export type CompositeSurveyNewAsyncProps = Omit<
  GetProps<CompositeSurveysClassesAPICompositeSurvey, unknown, CompositeSurveyNewAsyncQueryParams, void>,
  'path'
>;

/**
 * Retrieves a Composite Survey
 */
export const CompositeSurveyNewAsync = (props: CompositeSurveyNewAsyncProps) => (
  <Get<CompositeSurveysClassesAPICompositeSurvey, unknown, CompositeSurveyNewAsyncQueryParams, void>
    path={`/api/v1/Composite/Survey/New`}
    {...props}
  />
);

export type UseCompositeSurveyNewAsyncProps = Omit<
  UseGetProps<CompositeSurveysClassesAPICompositeSurvey, unknown, CompositeSurveyNewAsyncQueryParams, void>,
  'path'
>;

/**
 * Retrieves a Composite Survey
 */
export const useCompositeSurveyNewAsync = (props: UseCompositeSurveyNewAsyncProps) =>
  useGet<CompositeSurveysClassesAPICompositeSurvey, unknown, CompositeSurveyNewAsyncQueryParams, void>(
    `/api/v1/Composite/Survey/New`,
    props
  );

export interface CompositeUserRegisterExternalV1Response {
  [key: string]: any;
}

export type CompositeUserRegisterExternalV1Props = Omit<
  MutateProps<CompositeUserRegisterExternalV1Response, unknown, void, CompositeV1UserRegisterExternalRequest, void>,
  'path' | 'verb'
>;

/**
 * Adds a user to the external source database(s) indicated by looking up the compositeId
 */
export const CompositeUserRegisterExternalV1 = (props: CompositeUserRegisterExternalV1Props) => (
  <Mutate<CompositeUserRegisterExternalV1Response, unknown, void, CompositeV1UserRegisterExternalRequest, void>
    verb="PUT"
    path={`/api/v1/Composite/User/RegisterExternal`}
    {...props}
  />
);

export type UseCompositeUserRegisterExternalV1Props = Omit<
  UseMutateProps<CompositeUserRegisterExternalV1Response, unknown, void, CompositeV1UserRegisterExternalRequest, void>,
  'path' | 'verb'
>;

/**
 * Adds a user to the external source database(s) indicated by looking up the compositeId
 */
export const useCompositeUserRegisterExternalV1 = (props: UseCompositeUserRegisterExternalV1Props) =>
  useMutate<CompositeUserRegisterExternalV1Response, unknown, void, CompositeV1UserRegisterExternalRequest, void>(
    'PUT',
    `/api/v1/Composite/User/RegisterExternal`,
    props
  );

export interface CompositeUserIsRegisteredV1QueryParams {
  compositeId?: number;
}

export type CompositeUserIsRegisteredV1Props = Omit<
  GetProps<CompositeV1IsRegisteredResponse, unknown, CompositeUserIsRegisteredV1QueryParams, void>,
  'path'
>;

/**
 * Looks within the CompositeDbContext to see if the user is registered in all sources associated with the given CompositeId
 */
export const CompositeUserIsRegisteredV1 = (props: CompositeUserIsRegisteredV1Props) => (
  <Get<CompositeV1IsRegisteredResponse, unknown, CompositeUserIsRegisteredV1QueryParams, void>
    path={`/api/v1/Composite/User/IsRegistered`}
    {...props}
  />
);

export type UseCompositeUserIsRegisteredV1Props = Omit<
  UseGetProps<CompositeV1IsRegisteredResponse, unknown, CompositeUserIsRegisteredV1QueryParams, void>,
  'path'
>;

/**
 * Looks within the CompositeDbContext to see if the user is registered in all sources associated with the given CompositeId
 */
export const useCompositeUserIsRegisteredV1 = (props: UseCompositeUserIsRegisteredV1Props) =>
  useGet<CompositeV1IsRegisteredResponse, unknown, CompositeUserIsRegisteredV1QueryParams, void>(
    `/api/v1/Composite/User/IsRegistered`,
    props
  );

export type StudiesOnGoingSurveysV1Props = Omit<GetProps<StudyV1OnGoingSurveyResponse[], unknown, void, void>, 'path'>;

/**
 * Retrieves data about all not completed (Open/Abandoned) surveys for a specific participant
 */
export const StudiesOnGoingSurveysV1 = (props: StudiesOnGoingSurveysV1Props) => (
  <Get<StudyV1OnGoingSurveyResponse[], unknown, void, void> path={`/api/v1/Studies/OnGoingSurveys`} {...props} />
);

export type UseStudiesOnGoingSurveysV1Props = Omit<
  UseGetProps<StudyV1OnGoingSurveyResponse[], unknown, void, void>,
  'path'
>;

/**
 * Retrieves data about all not completed (Open/Abandoned) surveys for a specific participant
 */
export const useStudiesOnGoingSurveysV1 = (props: UseStudiesOnGoingSurveysV1Props) =>
  useGet<StudyV1OnGoingSurveyResponse[], unknown, void, void>(`/api/v1/Studies/OnGoingSurveys`, props);

export interface StudiesPastSurveysV1QueryParams {
  instanceId?: number;
}

export type StudiesPastSurveysV1Props = Omit<
  GetProps<StudyV1PastSurveyResponse[], unknown, StudiesPastSurveysV1QueryParams, void>,
  'path'
>;

/**
 * Retrieves data about all completed surveys for a specific participant
 */
export const StudiesPastSurveysV1 = (props: StudiesPastSurveysV1Props) => (
  <Get<StudyV1PastSurveyResponse[], unknown, StudiesPastSurveysV1QueryParams, void>
    path={`/api/v1/Studies/PastSurveys`}
    {...props}
  />
);

export type UseStudiesPastSurveysV1Props = Omit<
  UseGetProps<StudyV1PastSurveyResponse[], unknown, StudiesPastSurveysV1QueryParams, void>,
  'path'
>;

/**
 * Retrieves data about all completed surveys for a specific participant
 */
export const useStudiesPastSurveysV1 = (props: UseStudiesPastSurveysV1Props) =>
  useGet<StudyV1PastSurveyResponse[], unknown, StudiesPastSurveysV1QueryParams, void>(
    `/api/v1/Studies/PastSurveys`,
    props
  );

export type StudiesAllStudiesMetaDataProps = Omit<GetProps<StudyV1MetaDataResponse[], unknown, void, void>, 'path'>;

/**
 * Retrieves metadata for all the studies a participant is part of
 */
export const StudiesAllStudiesMetaData = (props: StudiesAllStudiesMetaDataProps) => (
  <Get<StudyV1MetaDataResponse[], unknown, void, void> path={`/api/v1/Studies/MetaData`} {...props} />
);

export type UseStudiesAllStudiesMetaDataProps = Omit<
  UseGetProps<StudyV1MetaDataResponse[], unknown, void, void>,
  'path'
>;

/**
 * Retrieves metadata for all the studies a participant is part of
 */
export const useStudiesAllStudiesMetaData = (props: UseStudiesAllStudiesMetaDataProps) =>
  useGet<StudyV1MetaDataResponse[], unknown, void, void>(`/api/v1/Studies/MetaData`, props);

export interface StudyGuidLoginResponse {
  [key: string]: string;
}

export type StudyGuidLoginProps = Omit<
  MutateProps<StudyGuidLoginResponse, unknown, void, AccountControllerGuidLoginRequest, void>,
  'path' | 'verb'
>;

/**
 * Log a user in via a unique identifier, returns an access token
 */
export const StudyGuidLogin = (props: StudyGuidLoginProps) => (
  <Mutate<StudyGuidLoginResponse, unknown, void, AccountControllerGuidLoginRequest, void>
    verb="POST"
    path={`/api/v1/Study/GuidLogin`}
    {...props}
  />
);

export type UseStudyGuidLoginProps = Omit<
  UseMutateProps<StudyGuidLoginResponse, unknown, void, AccountControllerGuidLoginRequest, void>,
  'path' | 'verb'
>;

/**
 * Log a user in via a unique identifier, returns an access token
 */
export const useStudyGuidLogin = (props: UseStudyGuidLoginProps) =>
  useMutate<StudyGuidLoginResponse, unknown, void, AccountControllerGuidLoginRequest, void>(
    'POST',
    `/api/v1/Study/GuidLogin`,
    props
  );

export interface StudyStudyCandidateQueryParams {
  externalId?: string;
  studyId?: number;
}

export type StudyStudyCandidateProps = Omit<
  GetProps<StudyV1CandidateResponse, unknown, StudyStudyCandidateQueryParams, void>,
  'path'
>;

/**
 * Creates a new study candidate and add them to the system. A candidate can later join a study as a participant.
 */
export const StudyStudyCandidate = (props: StudyStudyCandidateProps) => (
  <Get<StudyV1CandidateResponse, unknown, StudyStudyCandidateQueryParams, void>
    path={`/api/v1/Study/Candidate`}
    {...props}
  />
);

export type UseStudyStudyCandidateProps = Omit<
  UseGetProps<StudyV1CandidateResponse, unknown, StudyStudyCandidateQueryParams, void>,
  'path'
>;

/**
 * Creates a new study candidate and add them to the system. A candidate can later join a study as a participant.
 */
export const useStudyStudyCandidate = (props: UseStudyStudyCandidateProps) =>
  useGet<StudyV1CandidateResponse, unknown, StudyStudyCandidateQueryParams, void>(`/api/v1/Study/Candidate`, props);

export interface StudyEnrollResponse {
  [key: string]: any;
}

export type StudyEnrollProps = Omit<
  MutateProps<StudyEnrollResponse, unknown, void, StudyV1EnrollmentRequest, void>,
  'path' | 'verb'
>;

/**
 * Link a study participant to a registered user; after successful login
 *
 * Currently for "participant specific" links
 */
export const StudyEnroll = (props: StudyEnrollProps) => (
  <Mutate<StudyEnrollResponse, unknown, void, StudyV1EnrollmentRequest, void>
    verb="PUT"
    path={`/api/v1/Study/Enroll`}
    {...props}
  />
);

export type UseStudyEnrollProps = Omit<
  UseMutateProps<StudyEnrollResponse, unknown, void, StudyV1EnrollmentRequest, void>,
  'path' | 'verb'
>;

/**
 * Link a study participant to a registered user; after successful login
 *
 * Currently for "participant specific" links
 */
export const useStudyEnroll = (props: UseStudyEnrollProps) =>
  useMutate<StudyEnrollResponse, unknown, void, StudyV1EnrollmentRequest, void>('PUT', `/api/v1/Study/Enroll`, props);

export type StudySubmitAsyncProps = Omit<
  MutateProps<
    CompositeSurveysViewModelsAPIV1SubmitResponse,
    unknown,
    void,
    CompositeSurveysViewModelsAPIV1SubmitRequestRequestBody,
    void
  >,
  'path' | 'verb'
>;

/**
 * Retrieves a Composite Survey
 */
export const StudySubmitAsync = (props: StudySubmitAsyncProps) => (
  <Mutate<
    CompositeSurveysViewModelsAPIV1SubmitResponse,
    unknown,
    void,
    CompositeSurveysViewModelsAPIV1SubmitRequestRequestBody,
    void
  >
    verb="POST"
    path={`/api/v1/Study/Submit`}
    {...props}
  />
);

export type UseStudySubmitAsyncProps = Omit<
  UseMutateProps<
    CompositeSurveysViewModelsAPIV1SubmitResponse,
    unknown,
    void,
    CompositeSurveysViewModelsAPIV1SubmitRequestRequestBody,
    void
  >,
  'path' | 'verb'
>;

/**
 * Retrieves a Composite Survey
 */
export const useStudySubmitAsync = (props: UseStudySubmitAsyncProps) =>
  useMutate<
    CompositeSurveysViewModelsAPIV1SubmitResponse,
    unknown,
    void,
    CompositeSurveysViewModelsAPIV1SubmitRequestRequestBody,
    void
  >('POST', `/api/v1/Study/Submit`, props);

export interface StudySurveyAsyncQueryParams {
  /**
   * The Study Id to which the composite belongs
   */
  studyId?: number;
  /**
   * An array indicating the order of preference for AlternateText that may exist
   */
  alternateText?: number[];
  /**
   * The Id of the composite survey to retrieve
   */
  compositeId?: number;
  /**
   * The User to which the survey belongs
   */
  userId?: number;
}

export type StudySurveyAsyncProps = Omit<
  GetProps<CompositeSurveysClassesAPICompositeSurvey, unknown, StudySurveyAsyncQueryParams, void>,
  'path'
>;

/**
 * Retrieves a Composite Survey for a study
 */
export const StudySurveyAsync = (props: StudySurveyAsyncProps) => (
  <Get<CompositeSurveysClassesAPICompositeSurvey, unknown, StudySurveyAsyncQueryParams, void>
    path={`/api/v1/Study/Survey`}
    {...props}
  />
);

export type UseStudySurveyAsyncProps = Omit<
  UseGetProps<CompositeSurveysClassesAPICompositeSurvey, unknown, StudySurveyAsyncQueryParams, void>,
  'path'
>;

/**
 * Retrieves a Composite Survey for a study
 */
export const useStudySurveyAsync = (props: UseStudySurveyAsyncProps) =>
  useGet<CompositeSurveysClassesAPICompositeSurvey, unknown, StudySurveyAsyncQueryParams, void>(
    `/api/v1/Study/Survey`,
    props
  );

export interface StudySurveyNewAsyncQueryParams {
  /**
   * The Study Id to which the composite belongs
   */
  studyId?: number;
  /**
   * An array indicating the order of preference for AlternateText that may exist
   */
  alternateText?: number[];
  /**
   * The Id of the composite survey to retrieve
   */
  compositeId?: number;
  /**
   * The User to which the survey belongs
   */
  userId?: number;
}

export type StudySurveyNewAsyncProps = Omit<
  GetProps<CompositeSurveysClassesAPICompositeSurvey, unknown, StudySurveyNewAsyncQueryParams, void>,
  'path'
>;

/**
 * Retrieves a Composite Survey for a study
 */
export const StudySurveyNewAsync = (props: StudySurveyNewAsyncProps) => (
  <Get<CompositeSurveysClassesAPICompositeSurvey, unknown, StudySurveyNewAsyncQueryParams, void>
    path={`/api/v1/Study/Survey/New`}
    {...props}
  />
);

export type UseStudySurveyNewAsyncProps = Omit<
  UseGetProps<CompositeSurveysClassesAPICompositeSurvey, unknown, StudySurveyNewAsyncQueryParams, void>,
  'path'
>;

/**
 * Retrieves a Composite Survey for a study
 */
export const useStudySurveyNewAsync = (props: UseStudySurveyNewAsyncProps) =>
  useGet<CompositeSurveysClassesAPICompositeSurvey, unknown, StudySurveyNewAsyncQueryParams, void>(
    `/api/v1/Study/Survey/New`,
    props
  );

export interface StudyStudyConfigV1QueryParams {
  /**
   * Participant GUID
   */
  token?: string;
}

export type StudyStudyConfigV1Props = Omit<
  GetProps<StudyV1ConfigurationResponse, unknown, StudyStudyConfigV1QueryParams, void>,
  'path'
>;

/**
 * Retrieves a Study configuration based on participant guid
 */
export const StudyStudyConfigV1 = (props: StudyStudyConfigV1Props) => (
  <Get<StudyV1ConfigurationResponse, unknown, StudyStudyConfigV1QueryParams, void>
    path={`/api/v1/Study/Config`}
    {...props}
  />
);

export type UseStudyStudyConfigV1Props = Omit<
  UseGetProps<StudyV1ConfigurationResponse, unknown, StudyStudyConfigV1QueryParams, void>,
  'path'
>;

/**
 * Retrieves a Study configuration based on participant guid
 */
export const useStudyStudyConfigV1 = (props: UseStudyStudyConfigV1Props) =>
  useGet<StudyV1ConfigurationResponse, unknown, StudyStudyConfigV1QueryParams, void>(`/api/v1/Study/Config`, props);

export interface StudyParticipantIsLinkedQueryParams {
  participantGuid?: string;
}

export type StudyParticipantIsLinkedProps = Omit<
  GetProps<StudyV1ParticipantIsLinkedResponse, unknown, StudyParticipantIsLinkedQueryParams, void>,
  'path'
>;

/**
 * Looks within the CompositeDbContext to see if the participant has already been linked to a registered user
 *
 * Currently for "participant specific" links
 */
export const StudyParticipantIsLinked = (props: StudyParticipantIsLinkedProps) => (
  <Get<StudyV1ParticipantIsLinkedResponse, unknown, StudyParticipantIsLinkedQueryParams, void>
    path={`/api/v1/Study/Participant/IsLinked`}
    {...props}
  />
);

export type UseStudyParticipantIsLinkedProps = Omit<
  UseGetProps<StudyV1ParticipantIsLinkedResponse, unknown, StudyParticipantIsLinkedQueryParams, void>,
  'path'
>;

/**
 * Looks within the CompositeDbContext to see if the participant has already been linked to a registered user
 *
 * Currently for "participant specific" links
 */
export const useStudyParticipantIsLinked = (props: UseStudyParticipantIsLinkedProps) =>
  useGet<StudyV1ParticipantIsLinkedResponse, unknown, StudyParticipantIsLinkedQueryParams, void>(
    `/api/v1/Study/Participant/IsLinked`,
    props
  );

export interface StudyParticipantStudyCurrentStepQueryParams {
  /**
   * Id of the user in question
   */
  userId?: number;
  /**
   * The study in question
   */
  studyId?: number;
}

export type StudyParticipantStudyCurrentStepProps = Omit<
  GetProps<StudyV1ParticipantCurrentStepResponse, unknown, StudyParticipantStudyCurrentStepQueryParams, void>,
  'path'
>;

/**
 * Retrieves the compositeId for a study participant current step (survey)
 */
export const StudyParticipantStudyCurrentStep = (props: StudyParticipantStudyCurrentStepProps) => (
  <Get<StudyV1ParticipantCurrentStepResponse, unknown, StudyParticipantStudyCurrentStepQueryParams, void>
    path={`/api/v1/Study/ParticipantCurrentStep`}
    {...props}
  />
);

export type UseStudyParticipantStudyCurrentStepProps = Omit<
  UseGetProps<StudyV1ParticipantCurrentStepResponse, unknown, StudyParticipantStudyCurrentStepQueryParams, void>,
  'path'
>;

/**
 * Retrieves the compositeId for a study participant current step (survey)
 */
export const useStudyParticipantStudyCurrentStep = (props: UseStudyParticipantStudyCurrentStepProps) =>
  useGet<StudyV1ParticipantCurrentStepResponse, unknown, StudyParticipantStudyCurrentStepQueryParams, void>(
    `/api/v1/Study/ParticipantCurrentStep`,
    props
  );

export interface StudyStudyMetaDataQueryParams {
  /**
   * the study to query
   */
  studyId?: number;
}

export type StudyStudyMetaDataProps = Omit<
  GetProps<StudyV1MetaDataResponse, unknown, StudyStudyMetaDataQueryParams, void>,
  'path'
>;

/**
 * Retrieves information about a Study
 */
export const StudyStudyMetaData = (props: StudyStudyMetaDataProps) => (
  <Get<StudyV1MetaDataResponse, unknown, StudyStudyMetaDataQueryParams, void>
    path={`/api/v1/Study/MetaData`}
    {...props}
  />
);

export type UseStudyStudyMetaDataProps = Omit<
  UseGetProps<StudyV1MetaDataResponse, unknown, StudyStudyMetaDataQueryParams, void>,
  'path'
>;

/**
 * Retrieves information about a Study
 */
export const useStudyStudyMetaData = (props: UseStudyStudyMetaDataProps) =>
  useGet<StudyV1MetaDataResponse, unknown, StudyStudyMetaDataQueryParams, void>(`/api/v1/Study/MetaData`, props);

export interface TokenTokenResponse {
  [key: string]: any;
}

export type TokenTokenProps = Omit<
  MutateProps<TokenTokenResponse, unknown, void, ProbeResearchApiControllersTokenControllerTokenRequest, void>,
  'path' | 'verb'
>;

/**
 * This method implemented the OAuth2 Token endpoint supporting the password and refresh_token grants
 */
export const TokenToken = (props: TokenTokenProps) => (
  <Mutate<TokenTokenResponse, unknown, void, ProbeResearchApiControllersTokenControllerTokenRequest, void>
    verb="POST"
    path={`/Token`}
    {...props}
  />
);

export type UseTokenTokenProps = Omit<
  UseMutateProps<TokenTokenResponse, unknown, void, ProbeResearchApiControllersTokenControllerTokenRequest, void>,
  'path' | 'verb'
>;

/**
 * This method implemented the OAuth2 Token endpoint supporting the password and refresh_token grants
 */
export const useTokenToken = (props: UseTokenTokenProps) =>
  useMutate<TokenTokenResponse, unknown, void, ProbeResearchApiControllersTokenControllerTokenRequest, void>(
    'POST',
    `/Token`,
    props
  );

export type UtilityLanguagesV1Props = Omit<GetProps<UtilityV1Language, unknown, void, void>, 'path'>;

/**
 * Get a list of countries
 */
export const UtilityLanguagesV1 = (props: UtilityLanguagesV1Props) => (
  <Get<UtilityV1Language, unknown, void, void> path={`/api/v1/Utility/Languages`} {...props} />
);

export type UseUtilityLanguagesV1Props = Omit<UseGetProps<UtilityV1Language, unknown, void, void>, 'path'>;

/**
 * Get a list of countries
 */
export const useUtilityLanguagesV1 = (props: UseUtilityLanguagesV1Props) =>
  useGet<UtilityV1Language, unknown, void, void>(`/api/v1/Utility/Languages`, props);

export type UtilityCountriesV1Props = Omit<GetProps<UtilityV1Country, unknown, void, void>, 'path'>;

/**
 * Get a list of countries
 */
export const UtilityCountriesV1 = (props: UtilityCountriesV1Props) => (
  <Get<UtilityV1Country, unknown, void, void> path={`/api/v1/Utility/Countries`} {...props} />
);

export type UseUtilityCountriesV1Props = Omit<UseGetProps<UtilityV1Country, unknown, void, void>, 'path'>;

/**
 * Get a list of countries
 */
export const useUtilityCountriesV1 = (props: UseUtilityCountriesV1Props) =>
  useGet<UtilityV1Country, unknown, void, void>(`/api/v1/Utility/Countries`, props);
