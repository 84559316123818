import * as React from 'react';
import ReactDOM from 'react-dom';
import { App } from './app/App';
import reportWebVitals from './reportWebVitals';

import { LoadingBar, NotificationProvider, HelmetProvider } from 'components';
import { light } from 'theme';
import { localizationInit, ProbeRestfulProvider, store, languageById, useStateSelector } from 'services';
import { CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import jssPreset from '@mui/styles/jssPreset';
import StylesProvider from '@mui/styles/StylesProvider';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { Provider as ReduxStoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
declare module '@mui/material/styles/withStyles' {
  // Augment the BaseCSSProperties so that we can control jss-rtl
  interface BaseCSSProperties {
    /**
     * Used to control if the rule-set should be affected by rtl transformation
     */
    flip?: boolean;
  }
}

localizationInit();

export const ThemedApp: React.FC = ({ children }) => {
  const { languageId } = useStateSelector('system');
  const language = languageById(languageId);
  const { isRTL } = language;

  if (isRTL) {
    document.body.setAttribute('dir', 'rtl');
  } else {
    document.body.removeAttribute('dir');
  }

  const rtlTheme = createTheme({
    ...light,
    direction: isRTL ? 'rtl' : 'ltr'
  });

  return (
    //@ts-ignore
    <StylesProvider jss={jss}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={rtlTheme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </StylesProvider>
  );
};
export const AppProviders: React.FC = ({ children }) => (
  <React.Suspense fallback={<LoadingBar show />}>
    <ReduxStoreProvider store={store.default}>
      <HelmetProvider>
        <NotificationProvider>{children}</NotificationProvider>
      </HelmetProvider>
    </ReduxStoreProvider>
  </React.Suspense>
);

const ProvidedApp = () => (
  <React.StrictMode>
    <AppProviders>
      <BrowserRouter>
        <ThemedApp>
          <ProbeRestfulProvider>
            <App />
          </ProbeRestfulProvider>
        </ThemedApp>
      </BrowserRouter>
    </AppProviders>
  </React.StrictMode>
);

ReactDOM.render(<ProvidedApp />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
