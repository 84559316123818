import { FC, HTMLProps } from 'react';
import { Theme, CircularProgress, Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { green, teal } from '@mui/material/colors';

import Done from '@mui/icons-material/Done';

import { Stack } from '../Stack/Stack';
import {
  CurrentSurvey,
  questions,
  indexOfSection,
  sectionContaining,
  visibleQuestionsInSection,
  actions,
  useStateSelector,
  NormalizedSection
} from 'services';
import { useResponsive } from '../hooks';
import { useDispatch } from 'react-redux';
import MobileSurveyProgressBar from './MobileSurveyProgressBar';

const smallProgressRadius = 60;

const useStyle = makeStyles<Theme, { totalSection: number }>(theme =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateColumns: ({ totalSection }) => `repeat(${totalSection}, 1fr)`,
      gridGap: theme.spacing(2),
      [theme.breakpoints.down('lg')]: {
        gridGap: theme.spacing(1)
      }
    },
    focusBorder: {
      borderRadius: 110,
      border: `2px solid transparent`,
      '&:focus-within': {
        border: `2px solid ${theme.palette.secondary.main}`
      }
    },
    dial: {
      width: 110,
      height: 110,
      borderRadius: 110,
      backgroundColor: teal[50],
      position: 'relative',
      cursor: 'pointer',
      outline: 0,
      '& > *': {
        position: 'absolute',
        margin: 'auto',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: 'max-content',
        width: 'max-content'
      },
      [theme.breakpoints.down('lg')]: {
        width: smallProgressRadius,
        height: smallProgressRadius
      }
    },
    dialText: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    whiteCircular: {
      color: theme.palette.grey[50]
    },
    inactiveDial: {
      backgroundColor: theme.palette.grey[300]
    },
    inactiveCircular: {
      color: theme.palette.grey[100]
    },
    inactiveCircularDone: {
      color: theme.palette.grey[500]
    },
    greenDial: {
      backgroundColor: green[100]
    },
    greenCircular: {
      color: green[500]
    },
    checkMark: {
      color: green[500],
      height: 40,
      width: 40,
      [theme.breakpoints.down('lg')]: {
        width: 20,
        height: 20
      }
    },
    activeTypography: {
      color: theme.palette.secondary.main
    }
  })
);

const isSectionAnsweredAllQuestion = (section: NormalizedSection, survey: CurrentSurvey) => {
  const visibleQuestions = visibleQuestionsInSection(section, survey);
  return (
    visibleQuestions.length === visibleQuestions.filter(x => questions.isQuestionAnswered(x) || !x.Required).length
  );
};

const SectionProgress: FC<{ section: NormalizedSection; index: number }> = ({ section, index }) => {
  const { isTablet } = useResponsive();
  const progressSize = isTablet ? smallProgressRadius : 90;
  const progressThickness = isTablet ? 4 : 7;

  const survey = useStateSelector('survey');
  const question = questions.questionById(survey.Questions, survey.currentQuestionId);
  const activeSection = sectionContaining(question, survey);
  const activeSectionIndex = indexOfSection(activeSection, survey);
  const classes = useStyle({ totalSection: survey.Sections.length });
  const dispatch = useDispatch();

  const handleQuestionChange = () => {
    const questionId = questions.getFirstQuestionIDofSection(survey, index);
    dispatch(actions.updateCurrentQuestion(questionId));
  };

  let sectionDialProps: HTMLProps<HTMLDivElement> = {
    role: 'button',
    onMouseDown: handleQuestionChange,
    tabIndex: 0,
    onKeyPress: handleQuestionChange
  };

  if (activeSectionIndex < index) {
    if (!isSectionAnsweredAllQuestion(activeSection, survey)) {
      sectionDialProps = {};
    }
    const previousSection = survey.Sections[index - 1];
    if (previousSection.Id !== activeSection.Id && !isSectionAnsweredAllQuestion(previousSection, survey)) {
      sectionDialProps = {};
    }
  }

  const visibleQuestions = visibleQuestionsInSection(section, survey);
  const visibleQuestionsLength = visibleQuestions.length;
  const answeredVisibleQuestions = visibleQuestions.filter(x => questions.isQuestionAnswered(x));

  const isSectionActive = index === activeSectionIndex;

  const sectionLabel = `${answeredVisibleQuestions.length} / ${visibleQuestionsLength}`;

  const totalPercentage = Math.round((answeredVisibleQuestions.length / visibleQuestionsLength) * 100);

  const currentSectionStatus =
    answeredVisibleQuestions.length === visibleQuestions.length || index < activeSectionIndex
      ? 'done'
      : isSectionActive
      ? 'active'
      : 'inactive';

  const currentProgress = {
    done: (
      <div className={`${classes.dial} ${classes.greenDial}`} {...sectionDialProps}>
        <CircularProgress
          variant="determinate"
          value={totalPercentage}
          className={classes.greenCircular}
          thickness={progressThickness}
          size={progressSize}
        />
        <Done className={classes.checkMark} />
      </div>
    ),
    inactive: (
      <div className={`${classes.dial} ${classes.inactiveDial}`} {...sectionDialProps}>
        <CircularProgress
          variant="determinate"
          value={100}
          className={classes.inactiveCircular}
          thickness={progressThickness}
          size={progressSize}
        />
        <CircularProgress
          variant="determinate"
          value={totalPercentage}
          thickness={progressThickness}
          className={classes.inactiveCircularDone}
          size={progressSize}
        />
      </div>
    ),
    active: (
      <div className={classes.dial} {...sectionDialProps}>
        <CircularProgress
          variant="determinate"
          value={100}
          className={classes.whiteCircular}
          thickness={progressThickness}
          size={progressSize}
        />
        <CircularProgress
          variant="determinate"
          value={totalPercentage}
          thickness={progressThickness}
          color="secondary"
          size={progressSize}
        />
        <Typography className={classes.dialText} variant={isTablet ? 'body2' : 'h6'} color="primary">
          {totalPercentage}%
        </Typography>
      </div>
    )
  }[currentSectionStatus];

  return (
    <Stack gap={2} justifyItems="center">
      <Box display="flex" className={classes.focusBorder}>
        {currentProgress}
      </Box>
      <Box display="flex" flexDirection="column" textAlign="center" justifyContent="space-between">
        <Typography variant={isTablet ? 'subtitle2' : 'h6'} className={isSectionActive ? classes.activeTypography : ''}>
          {section.Title}
        </Typography>
        <Typography variant={isTablet ? 'caption' : 'subtitle2'}>{sectionLabel}</Typography>
      </Box>
    </Stack>
  );
};

export const SurveyProgressBar: FC = () => {
  const { Sections } = useStateSelector('survey');
  const classes = useStyle({ totalSection: Sections.length });
  const { isMobilePortrait } = useResponsive();

  if (isMobilePortrait) {
    return <MobileSurveyProgressBar />;
  }
  return (
    <div className={classes.root}>
      {Sections.map((section, index) => (
        <SectionProgress section={section} index={index} key={section.Id} />
      ))}
    </div>
  );
};
