import { LinearProgress, LinearProgressProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';

const useStyles = makeStyles({
  progressBar: {
    backgroundColor: 'rgba(0,0,0,0)',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 10000,
    width: '100%'
  },
  blurOverly: {
    filter: 'blur(10px)',
    backgroundColor: 'rgba(0,0,0,0)',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 10000,
    width: '100%'
  }
});

interface Props extends LinearProgressProps {
  show: boolean;
}

export const LoadingBar: FC<Props> = props => {
  const { show, ...linearBarProps } = props;
  const classes = useStyles(props);
  return (
    <>
      {show && (
        <>
          <LinearProgress className={classes.blurOverly} color="primary" {...linearBarProps} />
          <LinearProgress className={classes.progressBar} color="primary" {...linearBarProps} />
        </>
      )}
    </>
  );
};
