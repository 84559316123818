import { Country, LoginType, SSO_PROVIDER } from '../types';
import { countryBy } from './countries';

const supportsProbeLogin = (loginType: LoginType) => (loginType & LoginType.Probe) === LoginType.Probe;

const supportsParticipantLinkLogin = (loginType: LoginType) =>
  (loginType & LoginType.ParticipantLink) === LoginType.ParticipantLink;

export const supportsRegistration = (countryId: Country['id'], loginType: LoginType) => {
  const country = countryBy(countryId);
  return country.loginEnabled && loginType !== LoginType.Guest && loginType !== LoginType.ParticipantLink;
};

export const getSignInOptions = (countryId: Country['id'], loginType: LoginType) => {
  const options: ('probeAccount' | 'participantLink')[] = [];
  if (countryBy(countryId).loginEnabled && supportsProbeLogin(loginType)) {
    options.push('probeAccount');
  }
  if (supportsParticipantLinkLogin(loginType)) {
    options.push('participantLink');
  }
  return options;
};

type HemophiliaAppOptions = {
  icon: string;
  provider: SSO_PROVIDER;
  providerDisplay: string;
};

export const getHemophiliaAppOptions = (countryId: Country['id']): HemophiliaAppOptions => {
  return {
    icon: '',
    provider: 'PROBEResearch',
    providerDisplay: 'PROBE'
  };
};
