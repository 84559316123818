import { useState, useEffect } from 'react';
import { Stack, useResponsive } from 'components';
import { PastSurvey } from 'services';
import { Card, Collapse, Divider, IconButton, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

import { useTranslation } from 'react-i18next';
import { useHomeStyles } from '../../styles/Home.style';
import { PastSurveysLoading } from './../HomeLoading';
import PastSurveyTable from './PastSurveyTable';
import PastSurveyChart from './PastSurveyChart';
import { useDashboardContext } from '../../hooks/useDashboardContext';

export const usePastSurveyStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: '50% 1fr',
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '100%',
        gridGap: theme.spacing(2)
      }
    }
  })
);

export const PastSurveys: React.FC = () => {
  const classes = useHomeStyles();
  const pastSurveyClasses = usePastSurveyStyles();
  const { isMobilePortrait } = useResponsive();
  const { pastSurveys, setSelectedSurvey, selectedSurvey, loading } = useDashboardContext();

  const [showScoreInfo, setShowScoreInfo] = useState(false);

  const { t } = useTranslation('pastSurveys');

  useEffect(() => {
    if (selectedSurvey) {
      return;
    }

    if (pastSurveys.length === 0) {
      return;
    }

    setSelectedSurvey(pastSurveys[0]);
  }, [selectedSurvey, pastSurveys, setSelectedSurvey]);

  if (loading) {
    return <PastSurveysLoading />;
  }

  if (pastSurveys.length === 0) {
    return null;
  }

  const handlePastSurveySelected = (pastSurvey: PastSurvey) => {
    setSelectedSurvey(pastSurvey);
  };

  return (
    <Card className={classes.model}>
      <Stack variant="horizontal" gap={1} alignItems="center">
        <EventNoteIcon fontSize={isMobilePortrait ? 'small' : 'large'} color="action" />
        <Typography className={classes.cardTitle}>{t('pastSurveys')}</Typography>
        <IconButton aria-label={t('infoAriaLabel')} onClick={() => setShowScoreInfo(!showScoreInfo)} size="large">
          {showScoreInfo ? (
            <ExpandLessOutlinedIcon fontSize="small" color="primary" />
          ) : (
            <ExpandMoreOutlinedIcon fontSize="small" color="primary" />
          )}
        </IconButton>
      </Stack>
      <Collapse in={showScoreInfo} timeout={200} unmountOnExit>
        <Stack variant="vertical" gap={1}>
          <Typography variant="caption">{t('probeScoreInfo')}</Typography>
          <Typography variant="caption">{t('eq5dScoreInfo')}</Typography>
          <Typography variant="caption">{t('eq5dVasScoreInfo')}</Typography>
          <Typography variant="caption">{t('scoreInfoNA')}</Typography>
        </Stack>
      </Collapse>
      <Divider />
      <div className={pastSurveyClasses.root}>
        <PastSurveyChart pastSurveys={pastSurveys} />
        <PastSurveyTable
          selectedInstanceId={selectedSurvey?.InstanceId}
          onSelected={handlePastSurveySelected}
          pastSurveys={pastSurveys}
        />
      </div>
    </Card>
  );
};
