import * as React from 'react';
import { LineChart, PointType } from 'components';
import { hasScore, PastSurvey } from 'services';

interface PastSurveyChartProps {
  pastSurveys: PastSurvey[];
}

const chartDataMap = (pastSurvey: PastSurvey[]): PointType[] =>
  pastSurvey
    .filter(hasScore)
    .map(({ DateCompleted, ProbeScore, EQ5DScore, EQ5DVas, InstanceId, SurveyName }) => ({
      x: new Date(DateCompleted).getTime(),
      probe: ProbeScore,
      eq5d: EQ5DScore,
      vas: EQ5DVas,
      id: InstanceId,
      surveyName: SurveyName
    }))
    .reverse();

const PastSurveyChart: React.FC<PastSurveyChartProps> = ({ pastSurveys }) => {
  const chartData = React.useMemo(() => chartDataMap(pastSurveys), [pastSurveys]);

  const studies = React.useMemo(
    () =>
      pastSurveys.map(({ SurveyName, DateCompleted, InstanceId }) => ({
        surveyName: SurveyName,
        dateCompleted: DateCompleted,
        instanceId: InstanceId
      })),
    [pastSurveys]
  );

  return <LineChart studies={studies} data={chartData} />;
};

export default PastSurveyChart;
