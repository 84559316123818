import { hasExpired, useStateSelector } from 'services';
import { useResponsive } from 'components';
import { Card, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Skeleton from '@mui/material/Skeleton';
import { useHomeStyles } from '../styles/Home.style';
import { usePastSurveyStyles } from './DashboardWidgets/PastSurveys';
import Content from './Content';
import { WidgetCard } from 'components';

const WelcomeSurvey = () => {
  return (
    <WidgetCard>
      <Skeleton animation="wave" variant="rectangular" width={415} height={56} style={{ marginBottom: 12 }} />
      <Skeleton animation="wave" variant="text" />
      <Skeleton animation="wave" variant="text" />
      <Skeleton animation="wave" variant="text" />
      <Skeleton
        animation="wave"
        variant="rectangular"
        width={210}
        height={42}
        style={{ marginTop: 12, borderRadius: 40 }}
      />
    </WidgetCard>
  );
};

export const ShareSurveyLoading = () => {
  return (
    <WidgetCard>
      <Skeleton animation="wave" variant="rectangular" width={415} height={56} style={{ marginBottom: 12 }} />
      <Skeleton animation="wave" variant="text" />
      <Skeleton animation="wave" variant="text" />
      <Skeleton animation="wave" variant="text" />
      <Skeleton
        animation="wave"
        variant="rectangular"
        width={210}
        height={42}
        style={{ marginTop: 12, borderRadius: 40 }}
      />
    </WidgetCard>
  );
};

const ContinueSurvey = () => {
  const classes = useHomeStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Card className={classes.model}>
        <Skeleton animation="wave" variant="rectangular" width={415} height={56} style={{ marginBottom: 12 }} />
        <Skeleton animation="wave" variant="text" width={375} height={28} />
        <div className={classes.progress} style={{ overflow: 'hidden', background: 'white' }}>
          <Skeleton animation="wave" variant="rectangular" width="100%" height="100%" />
        </div>
        <Divider />
        <div className={classes.stats}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className={classes.icon}>
              <Skeleton animation="wave" variant="circular" width={66} height={66} style={{ marginRight: 16 }} />
            </div>
            <div style={{ width: 200 }}>
              <Skeleton animation="wave" variant="text" />
              <Skeleton animation="wave" variant="text" />
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className={classes.icon}>
              <Skeleton animation="wave" variant="circular" width={66} height={66} style={{ marginRight: 16 }} />
            </div>
            <div style={{ width: 200 }}>
              <Skeleton animation="wave" variant="text" />
              <Skeleton animation="wave" variant="text" />
            </div>
          </div>
        </div>
        <div className={classes.buttonsWrapper}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={isMobile ? '100%' : 210}
            height={42}
            style={{ marginTop: 12, borderRadius: 40 }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={isMobile ? '100%' : 210}
            height={42}
            style={{ marginTop: 12, borderRadius: 40 }}
          />
        </div>
      </Card>
    </div>
  );
};

export const UpcomingSurveysLoading = () => {
  const classes = useHomeStyles();
  return (
    <Card className={classes.model}>
      <Skeleton animation="wave" variant="rectangular" width="100%" height={35} />
      <Divider />
      <Skeleton animation="wave" variant="rectangular" width="100%" height={300} />
    </Card>
  );
};

export const ComparisonChartLoading = () => {
  const isMobile = useResponsive();
  return <Skeleton animation="wave" variant="rectangular" width="100%" height={isMobile ? 544 : 440} />;
};

export const PastSurveysLoading = () => {
  const classes = useHomeStyles();
  const pastSurveyClasses = usePastSurveyStyles();

  return (
    <Card className={classes.model}>
      <Skeleton animation="wave" variant="rectangular" width="100%" height={35} />
      <Divider />
      <div className={`${pastSurveyClasses.root}`}>
        <Skeleton animation="wave" variant="rectangular" height={400} style={{ margin: 8 }} />
        <Skeleton animation="wave" variant="rectangular" height={400} style={{ margin: 8 }} />
      </div>
    </Card>
  );
};

export const HomeLoading: React.FC = () => {
  const classes = useHomeStyles();
  const { isGuest } = useStateSelector('user');
  const survey = useStateSelector('survey');
  const isExpired = hasExpired(survey);

  const SurveyComponent = isExpired || survey.submitted || !survey.instructionsRead ? WelcomeSurvey : ContinueSurvey;

  if (isGuest) {
    return (
      <Content>
        <SurveyComponent />
      </Content>
    );
  }

  return (
    <Content rootClass={classes.rootDashboard}>
      {survey.isDefined && <SurveyComponent />}
      <UpcomingSurveysLoading />
      <PastSurveysLoading />
    </Content>
  );
};
