import { actions, auth, OnGoingSurveyResponse, useStateSelector, useStudiesOnGoingSurveys } from 'services';
import { useState, useEffect, useMemo } from 'react';
import { useAsyncDispatch } from './useAsyncDispatch';
import { useNotification } from 'components';

export const useFetchSurvey = () => {
  const [isFetching, setLoadingSurvey] = useState(true);
  const { token, isGuest } = useStateSelector('user');
  const { countryId, languageId, studyId } = useStateSelector('system');
  const survey = useStateSelector('survey');
  const { notify } = useNotification();
  const { data: surveyInstances, refetch: fetchSurvey } = useStudiesOnGoingSurveys({ lazy: true });
  const dispatch = useAsyncDispatch();

  useEffect(() => {
    // Fetch ongoing surveys for non guest user
    if (!isGuest) {
      fetchSurvey();
    }
    // eslint-disable-next-line
  }, [isGuest]);

  // last valid ongoing survey
  const currentSurveyInstance = useMemo(
    () =>
      surveyInstances &&
      (surveyInstances
        .sort((a, b) => (a?.InstanceId || 0) - (b?.InstanceId || 0))
        .reverse()[0] as OnGoingSurveyResponse),
    [surveyInstances]
  );

  const currentSurveyInstanceId = currentSurveyInstance?.InstanceId;
  const currentSurveyStudyId = currentSurveyInstance?.StudyId;
  const guestLocalSurveyInstanceId = isGuest && survey.isDefined ? survey.InstanceId : undefined;

  useEffect(() => {
    let mounted = true;

    const loadSurveyAsync = async () => {
      if (!token || !mounted) {
        return;
      }

      setLoadingSurvey(true);

      try {
        // For a guest user and the survey was just submitted, we do not want to create a new survey.
        if (isGuest && !guestLocalSurveyInstanceId && !survey.submitted) {
          await dispatch(actions.createNewSurvey(studyId, true));
          return;
        }
        if (!isGuest) {
          await auth.updateProbeUserAsync(
            {
              CountryId: countryId,
              LanguageId: languageId
            },
            token
          );
        }
        const InstanceId = guestLocalSurveyInstanceId || currentSurveyInstanceId;
        if (InstanceId && currentSurveyStudyId) {
          await dispatch(
            actions.retrieveSurveyForInstanceId(InstanceId, currentSurveyStudyId, token, error => {
              notify({ type: 'error', message: error?.message });
            })
          );
        }
      } finally {
        setLoadingSurvey(false);
      }
    };

    loadSurveyAsync();

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, [token, isGuest, currentSurveyInstanceId, guestLocalSurveyInstanceId]);

  return { isFetching, survey };
};
