import { createTheme } from '@mui/material/styles';

export const whiteText = '#fcfcfc';
// export const backText = '#1F1F1F';

export default createTheme({
  palette: {
    primary: {
      dark: '#1f7f95',
      light: '#36d6e7',
      main: '#1f7f95',
      contrastText: whiteText
    },
    secondary: {
      main: '#feb06a',
      light: '#36d6e7',
      contrastText: whiteText
    },
    warning: {
      main: '#feb06a'
    },
    error: {
      main: '#ff0000'
    }
  }
}).palette;
