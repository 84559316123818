import { Helmet as HelmetComp, HelmetProvider as HelmetProviderComp } from 'react-helmet-async';

export const Helmet: React.FC = ({ children }) => {
  //@ts-ignore
  return <HelmetComp>{children}</HelmetComp>;
};

export const HelmetProvider: React.FC = ({ children }) => {
  //@ts-ignore
  return <HelmetProviderComp>{children}</HelmetProviderComp>;
};
