import { Config, ProviderConfig } from './types';

const getEnvOrDefault = <T>(envKey: string, defaultValue: T) => {
  return process.env[envKey] ?? defaultValue;
};

const getEnvOrFail = (envKey: string) => {
  const value = process.env[envKey];
  if (!value) {
    throw new Error(`Missing environment key: ${envKey}, ${process.env.NODE_ENV}`);
  }

  return value;
};

const getEnvNumOrFail = (envKey: string) => {
  const value = parseInt(getEnvOrFail(envKey));
  if (isNaN(value)) {
    throw new Error(`Numeric value required for ${envKey} but received ${value}`);
  }

  return value;
};

const getEnvBooleanOrFail = (envKey: string) => {
  const value = getEnvOrFail(envKey).toLowerCase();

  if (value !== 'true' && value !== 'false') {
    throw new Error(`Boolean value required for ${envKey} but received ${value}`);
  }

  return value === 'false' ? false : true;
};

const createProvider = (
  endPointBaseEnvKey: string,
  authorization: string,
  token: string,
  clientId: string,
  scope?: string
): ProviderConfig => {
  const endPointBase = getEnvOrFail(endPointBaseEnvKey);
  return {
    endPointBase,
    authorizationEndPoint: `${endPointBase}${authorization}/`,
    tokenEndPoint: `${endPointBase}${token}/`,
    clientId,
    scope
  };
};

export const PROBE_RESEARCH_CLIENT_ID = '65755343-0b09-47e6-9057-a838d6aa966b';

const ENV_CONFIG: Config = {
  probeEndPointBase: getEnvOrFail('REACT_APP_PROBE_ENDPOINT_BASE'),
  oAuthEndPoint: getEnvOrFail('REACT_APP_OAUTH_ENDPOINT'),
  studyGuid: getEnvOrFail('REACT_APP_STUDY_GUID'),
  studyId: getEnvNumOrFail('REACT_APP_STUDY_ID'),
  isProd: getEnvBooleanOrFail('REACT_APP_IS_PROD'),
  appVersion: getEnvOrFail('REACT_APP_VERSION'),
  trackingId: getEnvOrDefault('REACT_APP_TRACKING_ID', ''),
  providers: {
    PROBEResearch: createProvider('REACT_APP_OAUTH_ENDPOINT', 'authorize', 'token', PROBE_RESEARCH_CLIENT_ID)
  }
};

export const CONFIG = ENV_CONFIG;

export const {
  oAuthEndPoint: OAUTH_ENDPOINT_BASE,
  studyGuid: STUDY_GUID,
  studyId: STUDY_ID,
  probeEndPointBase: PROBE_ENDPOINT_BASE,
  providers: PROVIDERS
} = CONFIG;

export const ALTERNATE_TEXT_ID = 1;

// Any delta bigger than 60 will be considered 0
export const TIME_DELTA_THRESHOLD_IN_SECONDS = 60;

export const EQ5D_VAS_QUESTION_EXTERNAL_ID = 180;

export const REGIMEN_HISTORY_ID = 706;

// How much time before real expiry time to we consider the token expired? This is to avoid having a token that we believe is valid but has indeed expired
export const REFRESH_TOKEN_DELTA_IN_MINUTES = 10;

export const TIME_TO_NEW_SURVEY_NOTIFICATION_IN_MONTHS = 6;

export const MIN_PASSWORD_LENGTH = 6;

export const EQ5D_QUESTION_EXTERNAL_IDS: number[] = [154, 155, 156, 157, 158, EQ5D_VAS_QUESTION_EXTERNAL_ID];

export const EQ5D_SOURCE_SURVEY_ID = 1;

export const MAX_HEALTH_VALUE = 100;
export const MIN_HEALTH_VALUE = 0;

export const APP_VERSION = CONFIG.appVersion;
export const APP_FULL_NAME = `webEPOCH v${APP_VERSION}`;

export const REDIRECT_URL = window.location.origin + '/';
