import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import includes from 'lodash/includes';
import every from 'lodash/every';
import some from 'lodash/some';
import { findById } from './common';
import { getValidatorFor } from './config/validators';
import { EQ5D_QUESTION_EXTERNAL_IDS, EQ5D_SOURCE_SURVEY_ID, EQ5D_VAS_QUESTION_EXTERNAL_ID } from './constants';
import { QuestionTypes, TableTypes } from './models';
import {
  Answer,
  Column,
  IAnswerable,
  ISurveyEntry,
  Question,
  Row,
  Table,
  ValidationOutcome,
  CurrentSurvey
} from './types';
import { invalidValidationOutcome, numericValidator, validValidationOutcome } from './validator';
import { visibleQuestionsInSurvey } from '.';
import { answeredQuestionsInSurvey } from './survey-manager';

export const questionById = (questions: Question[], questionId: number): Question => findById(questions, questionId);

export const indexOfQuestionById = (questions: Question[], questionId: number): number =>
  findIndex(questions, { Id: questionId });

export const containsQuestion = (questions: Question[], questionId: number): boolean => {
  try {
    return !!questionById(questions, questionId);
  } catch (error) {
    return false;
  }
};

export const isSingleAnswer = (answerable: IAnswerable): boolean => {
  const singleQuestionTypes = [QuestionTypes.DROP_DOWN, QuestionTypes.RADIO, QuestionTypes.TRUE_FALSE];
  return questionTypeIsOneOf(answerable, singleQuestionTypes);
};

export const canAutoNavigateToNextQuestion = (question: Question): boolean =>
  isSingleAnswer(question) && !isEmpty(question.Result);

export const isSingleAnswerTableQuestion = (question: Question, answerable: IAnswerable): boolean => {
  const table = question.Table;
  return !!table && isSingleAnswer(answerable) && tableTypeIsOneOf(table, [TableTypes.RADIO]);
};

export const tableFrom = (question: Question): Table => {
  if (question.Table === null) {
    throw new Error(`Trying to access table in a non table question (Id: ${question.Id})`);
  }
  return question.Table;
};

export const isNumeric = (answerable: IAnswerable): boolean => questionTypeIs(answerable, QuestionTypes.TEXT_NUMERIC);

export const isMultiline = (answerable: IAnswerable): boolean => questionTypeIs(answerable, QuestionTypes.TEXT_AREA);

export const isTable = (question: Question): boolean => questionTypeIs(question, QuestionTypes.TABLE);

export const isEQ5DVAS = (question: Question): boolean =>
  question.ExternalId === EQ5D_VAS_QUESTION_EXTERNAL_ID && question.SourceSurveyId === EQ5D_SOURCE_SURVEY_ID;

export const isEQ5D = (question: Question): boolean =>
  EQ5D_QUESTION_EXTERNAL_IDS.indexOf(question.ExternalId) >= 0 && question.SourceSurveyId === EQ5D_SOURCE_SURVEY_ID;

export const answerRowForEQ5D = (question: Question): Row => {
  const table = tableFrom(question);
  return table.Rows[0];
};

export const answerColumnForEQ5D = (question: Question): Column => {
  const row = answerRowForEQ5D(question);
  const answerColumn = find(row.Columns, x => questionTypeIs(x, QuestionTypes.TEXT_NUMERIC));
  if (!answerColumn) {
    throw new Error(`Trying to access EQ5D answer column for a non EQ5D question (Id: ${question.Id})`);
  }
  return answerColumn;
};

export const answerTextFor = (answerable: IAnswerable): string | undefined => {
  const { Result } = answerable;
  if (Result.length !== 1) {
    return undefined;
  }
  return Result[0].InputText || undefined;
};

export const answerFor = (answerable: IAnswerable): Answer | undefined => {
  const { Result } = answerable;
  if (Result.length !== 1) {
    return undefined;
  }
  const answerId = Result[0].AnswerId;
  return findById(answerable.Answers, answerId);
};

export const isText = (answerable: IAnswerable): boolean => {
  const textQuestionTypes = [QuestionTypes.TEXT, QuestionTypes.TEXT_AREA, QuestionTypes.TEXT_NUMERIC];
  return questionTypeIsOneOf(answerable, textQuestionTypes);
};

const requiredAnswerMissing = (question: Question): number | null => {
  if (!isTable(question)) {
    return null;
  }

  const table = tableFrom(question);
  const firstRequiredRowWithoutAnswer = table.Rows.filter(row => row.Required).find(row => !isRowAnswered(row));

  if (!firstRequiredRowWithoutAnswer) {
    return null;
  }

  return firstRequiredRowWithoutAnswer.Id;
};

export const validateQuestion = (question: Question): ValidationOutcome => {
  const allRequiredFieldsAreAnswered = isTable(question)
    ? isTableComplete(tableFrom(question))
    : !question.Required || isQuestionAnswered(question);

  if (!allRequiredFieldsAreAnswered) {
    const questionRowId = requiredAnswerMissing(question);
    return invalidValidationOutcome('messages:questionRequired', questionRowId);
  }

  // Default validation outcome is valid
  let validationOutcome = validValidationOutcome();

  const validator = getValidatorFor(question.Id);
  if (validator) {
    validationOutcome = validator(question);
  }

  const { isValid } = validationOutcome;
  if (!isValid) {
    return validationOutcome;
  }

  // Validate validator first to have the best message possible
  const shouldCheckForNumeric = isNumeric(question) || isTable(question);

  return shouldCheckForNumeric ? numericValidator(question) : validationOutcome;
};

/**
 * A question is valid when
 * 1- Is is required and all its answers pass validation
 * 2- It is not required and has no answer
 * 3- it is not required and all its answers pass validation
 */
export const isQuestionValid = (question: Question): boolean => {
  const { isValid } = validateQuestion(question);
  return isValid;
};

/**
 * A question is answered when
 * 1- all required fields are answered
 * 2- some non required fields are answered
 */
export const isQuestionAnswered = (question: Question): boolean => {
  if (!isTable(question)) {
    return hasAnswer(question);
  }

  const table = tableFrom(question);

  const nonRequiredRows = filter(table.Rows, row => !row.Required);
  const requiredRows = filter(table.Rows, row => row.Required);

  return (requiredRows.length > 0 && allRowsAnswered(requiredRows)) || some(nonRequiredRows, row => isRowAnswered(row));
};

/**
 * Returns the next available Id and DisplayOrder
 */
export const nextAvailableDynamicProps = (rows: ISurveyEntry[]): [number, number] => {
  if (rows.length === 0) {
    return [-1, 0];
  }

  return [Math.min(...rows.map(x => x.Id)) - 1, Math.max(...rows.map(x => x.DisplayOrder)) + 1];
};

const hasAnswer = (answerable: IAnswerable): boolean => {
  const { Result: result } = answerable;
  if (result.length === 0) {
    return false;
  }
  if (isText(answerable)) {
    const inputText = result[0].InputText;
    return !!(inputText && inputText.trim());
  }

  return true;
};

const isTableComplete = (table: Table): boolean => {
  const requiredRows = filter(table.Rows, row => row.Required);
  return allRowsAnswered(requiredRows);
};

const allRowsAnswered = (rows: Row[]): boolean => {
  // at least one answer for each row. Returns true if the rows array is empty
  return every(rows, row => isRowAnswered(row));
};

export const isRowAnswered = (row: Row): boolean => {
  if (row.GroupColumns && row.Required) {
    return some(row.Columns, hasAnswer);
  }
  return every(row.Columns, hasAnswer);
};

const questionTypeIs = (question: IAnswerable, questionType: number): boolean =>
  questionTypeIsOneOf(question, [questionType]);

const questionTypeIsOneOf = (question: IAnswerable, questionTypes: number[]): boolean =>
  includes(questionTypes, question.QuestionType.Id);

const tableTypeIsOneOf = (table: Table, tableTypes: number[]): boolean => includes(tableTypes, table.TableType.Id);

export const currentQuestionsStats = (survey: CurrentSurvey) => ({
  done: answeredQuestionsInSurvey(survey).length,
  outOf: visibleQuestionsInSurvey(survey).length
});

export const rowQuestionId = (id: number) => `row-question-${id}`;

export const scrollToRowQuestion = (rowId: number, align?: ScrollIntoViewOptions['block']) => {
  const nextQuestion = document.getElementById(rowQuestionId(rowId));
  const nextQuestionAnswer = document.getElementsByName(`${rowId}`);
  if (nextQuestionAnswer[0]) {
    nextQuestionAnswer[0].focus();
  }
  if (nextQuestion) {
    nextQuestion.scrollIntoView({
      behavior: 'smooth',
      block: align || 'center'
    });
  }
};

export const getFirstQuestionIDofSection = (survey: CurrentSurvey, sectionIndex: number) =>
  survey.Sections[sectionIndex].Questions[0];

export { QuestionTypes } from './models';
