import find from 'lodash/find';
import some from 'lodash/some';
import { Requisite } from '.';
import { CurrentSurvey, Question } from './types';

export const isVisible = (question: Question, currentSurvey: CurrentSurvey): boolean =>
  evaluateExpression(question.DependencyExpression, currentSurvey);

export const evaluateExpression = (postfixExpression: string | null, currentSurvey: CurrentSurvey): boolean => {
  // no postfix expression defined: There are no condition to check
  if (!postfixExpression) {
    return true;
  }

  const resultStack: any[] = [];
  const postfix = postfixExpression.split(' ');
  postfix.forEach(entry => {
    if (isNumeric(entry)) {
      resultStack.push(evaluateRequisite(Number(entry), currentSurvey));
    } else {
      const a = resultStack.pop();
      if (entry === '!') {
        resultStack.push(!a);
      } else {
        const b = resultStack.pop();
        if (entry === '|') {
          resultStack.push(a || b);
        } else if (entry === '&') {
          resultStack.push(a && b);
        } else if (entry === '*') {
          resultStack.push(xor(a, b));
        }
      }
    }
  });

  if (resultStack.length === 1) {
    return resultStack.pop();
  }

  // something is wrong with the expression
  throw new Error(`Could not evaluate postfix expression: ${postfixExpression}`);
};

const xor = (a: any, b: any) => (a || b) && !(a && b);

const evaluateRequisite = (requisiteId: number, currentSurvey: CurrentSurvey): boolean => {
  const requisite: Requisite = currentSurvey.Requisites[requisiteId];
  if (!requisite) {
    throw new Error(`Cannot find requisite with id '${requisiteId}'`);
  }
  const { QuestionId, AnswerId } = requisite;

  const question = find(currentSurvey.Questions, {
    Id: QuestionId
  });
  if (!question) {
    throw new Error(`Cannot find question with with Id='${QuestionId}' used in requisite with '${requisiteId}'`);
  }
  return some(question.Result, { AnswerId }) && isVisible(question, currentSurvey);
};

const isNumeric = (expression: string): boolean => !Number.isNaN(Number(expression));
