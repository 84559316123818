import { gradients } from 'theme';
import { Fab } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import LanguageOutlined from '@mui/icons-material/LanguageOutlined';
import { useTranslation } from 'react-i18next';
import { BackButton } from '../BackButton';
import { FC } from 'react';

export const cardBoxShadow = '0px 10px 20px rgba(0, 0, 0, 0.14)';

const useStyle = makeStyles<Theme, {}>(theme =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateColumns: '.4fr .6fr',
      gridTemplateRows: '850px',
      boxShadow: cardBoxShadow,
      maxWidth: 1200,
      width: '100%',
      borderRadius: `${theme.shape.borderRadius}px`,
      overflow: 'hidden',
      height: 'max-content',
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '100vh'
      },
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'max-content max-content auto',
        borderRadius: 0,
        margin: 0,
        maxWidth: '100%',
        minHeight: '100vh'
      }
    },
    leftSide: {
      gridArea: [1, 1, 2, 2].join(' / '),
      background: theme.palette.background.paper,
      display: 'grid',
      justifyContent: 'center',
      alignItems: 'center',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto 1fr',
      gridRowGap: theme.spacing(2),
      overflow: 'auto',
      [theme.breakpoints.down('md')]: {
        alignItems: 'center'
      },
      padding: theme.spacing(3)
    },
    rightSide: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'auto',
      gridArea: [1, 2, 2, 3].join(' / '),
      ...gradients.TealLinear,
      [theme.breakpoints.down('md')]: {
        gridArea: [3, 1, 4, 2].join(' / ')
      }
    },
    content: {
      padding: theme.spacing(3, 5),
      alignSelf: 'center',
      justifySelf: 'center',
      maxWidth: 450,
      overflow: 'hidden',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2, 3),
        background: theme.palette.background.paper
      },
      [theme.breakpoints.down('sm')]: {
        padding: 0
      }
    },
    infoArea: {
      padding: theme.spacing(5),
      '& *': {
        textShadow: '0px 1px 3px rgba(0, 0, 0, 0.35)',
        color: 'white'
      },
      margin: 'auto',
      [theme.breakpoints.down('md')]: {
        paddingBottom: 100
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3),
        paddingBottom: 100,
        alignSelf: 'start'
      }
    },
    logo: {
      maxWidth: 250,
      [theme.breakpoints.down('md')]: {
        maxWidth: 175
      },
      width: '100%',
      justifySelf: 'center'
    },
    corner: {
      gridArea: [-2, -2, -1, -1].join(' / '),
      justifySelf: 'end',
      alignSelf: 'end',
      padding: theme.spacing(3)
    }
  })
);

interface SignInModalProps {
  logoSrc: string;
  onClickLocalization?: () => void;
  onClickBack?: () => void;
  informationContent?: React.ReactNode;
}

export const SignInModal: FC<SignInModalProps> = props => {
  const { children, logoSrc, onClickBack, onClickLocalization, informationContent } = props;
  const { t } = useTranslation('account');
  const classes = useStyle({});
  return (
    <div className={classes.root}>
      <div className={classes.leftSide}>
        <img src={logoSrc} alt="logo" className={classes.logo} />
        <div className={classes.content}>{children}</div>
      </div>
      <div className={classes.rightSide}>
        <div className={classes.infoArea}>{informationContent}</div>
      </div>
      {onClickLocalization && (
        <div className={classes.corner}>
          <Fab variant="extended" className={classes.fab} onClick={onClickLocalization}>
            <LanguageOutlined color="primary" />
            {t('localization')}
          </Fab>
        </div>
      )}
      <BackButton onClickBack={onClickBack}></BackButton>
    </div>
  );
};
