import * as React from 'react';
import { formatter, PastSurvey, PastSurveyResponse, scaleScores, useStudiesPastSurveys } from 'services';
import last from 'lodash/last';
import first from 'lodash/first';
import findIndex from 'lodash/findIndex';
import { store } from 'services';
import { useHandler, useNotification } from 'components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { PATH } from 'app/routes/routes';

type DashboardContextState = {
  pastSurveys: PastSurvey[];
  selectedSurvey: PastSurvey | undefined;
  setSelectedSurvey: (pastSurvey: PastSurvey | undefined) => void;
  selectNextSurvey: () => void;
  selectPreviousSurvey: () => void;
  loading: boolean;
  refresh: () => void;
};

const DashboardContextContext = React.createContext<DashboardContextState | undefined>(undefined);

export const mapPastSurvey = (survey: PastSurveyResponse): PastSurvey => {
  const scores = scaleScores(survey);
  const studyName = survey.StudyName || '';
  const surveyName = survey.SurveyName || '--';
  return {
    ProbeScore: formatter.displayScoreValue(scores.ProbeScore),
    EQ5DScore: formatter.displayScoreValue(scores.EQ5DScore),
    EQ5DVas: formatter.displayScoreValue(scores.EQ5DVas),
    InstanceId: survey.InstanceId || -1,
    SurveyName: `${studyName}: ${surveyName}`,
    Contacts: survey.Contacts ?? [],
    ContactName: survey.ContactName ?? '',
    DateCompleted: (survey.DateCompleted ? new Date(survey.DateCompleted) : new Date()).toISOString()
  };
};

const pastSurveyDateMap = (pastSurveys: PastSurveyResponse[]): PastSurvey[] =>
  pastSurveys
    .map(mapPastSurvey)
    .sort((a, b) => (new Date(a.DateCompleted).getTime() < new Date(b.DateCompleted).getTime() ? 1 : -1));

const DashboardContextProvider: React.FC = ({ children }) => {
  const { data, loading, refetch, error } = useStudiesPastSurveys({ lazy: true });
  const [selectedSurvey, setSelectedSurvey] = React.useState<PastSurvey | undefined>();
  const { notify } = useNotification();
  const dispatch = useDispatch();
  const { push } = useHistory();

  const gotToSignIn = useHandler((actualError: typeof error) => {
    notify({ message: actualError?.message || 'Error', type: 'error' });
    dispatch(store.actions.user.signOut());
    push(PATH.INDEX.SIGN_IN_OPTIONS);
  });

  React.useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (error) {
      gotToSignIn(error);
    }
  }, [error, gotToSignIn]);

  const value = React.useMemo(() => {
    //past surveys are sorted from most recent to last.
    const pastSurveys = data ? pastSurveyDateMap(data) : [];

    const selectNextSurvey = () => {
      if (!selectedSurvey) {
        setSelectedSurvey(first(pastSurveys));
        return;
      }
      const index = findIndex(pastSurveys, selectedSurvey);
      setSelectedSurvey(pastSurveys[index - 1] ?? selectedSurvey);
    };

    const selectPreviousSurvey = () => {
      if (!selectedSurvey) {
        setSelectedSurvey(last(pastSurveys));
        return;
      }
      const index = findIndex(pastSurveys, selectedSurvey);
      setSelectedSurvey(pastSurveys[index + 1] ?? selectedSurvey);
    };

    return {
      pastSurveys,
      selectedSurvey,
      setSelectedSurvey,
      loading,
      selectNextSurvey,
      selectPreviousSurvey,
      refresh: refetch,
      error: error?.message
    };
  }, [data, loading, selectedSurvey, refetch, error]);

  return <DashboardContextContext.Provider value={value}>{children}</DashboardContextContext.Provider>;
};

function useDashboardContext() {
  const context = React.useContext(DashboardContextContext);
  if (context === undefined) {
    throw new Error('useDashboardContext must be used within a DashboardContextProvider');
  }
  return context;
}

export { DashboardContextProvider, useDashboardContext };
