import find from 'lodash/find';

import { Country } from '../types';
import CountryId from './countryId';

const country = (display: string, id: number, code: string, loginEnabled = true): Country => ({
  id,
  code,
  display,
  loginEnabled
});

const supportedCountries: Country[] = [country('中国', CountryId.CHINA, 'CN')];

const countryById = (id: number) => {
  const found = find(supportedCountries, { id });
  if (found) {
    return found;
  }
  throw new Error(`Country with Id ${id} not found`);
};

const DEFAULT_COUNTRY = countryById(CountryId.CHINA);

export function countryBy(id: number | undefined): Country;
export function countryBy(code: string): Country;
export function countryBy(x: any): Country {
  if (x === undefined) {
    return DEFAULT_COUNTRY;
  }
  //Country id
  if (typeof x == 'number') {
    return find(supportedCountries, { id: x }) || DEFAULT_COUNTRY;
  }
  //country code
  else if (typeof x == 'string') {
    const countryCode = x.toUpperCase();
    return find(supportedCountries, { code: countryCode }) || DEFAULT_COUNTRY;
  }

  return DEFAULT_COUNTRY;
}

export const isCanada = (id: number | undefined): boolean => id === CountryId.CANADA;

export const countryByLanguageCode = (code: string): Country => {
  const countryCode = code.split('-')[1];
  if (countryCode) {
    return countryBy(countryCode);
  }
  return DEFAULT_COUNTRY;
};

export default supportedCountries;
