import { Reducer } from 'redux';
import { UserInfo } from 'services/api-hooks';
import { cleanUserLocalStoreSurvey } from '..';
import { Token } from '../../types';
import { UserActions, SIGN_OUT } from './User.actions';

export interface UserState {
  token?: Token;
  isGuest?: boolean;
  userInfo?: UserInfo;
  providerToken?: Token;
  provider?: string;
}

export const initialState: UserState = {};

export const UserReducer: Reducer<UserState, UserActions> = (state = initialState, action): UserState => {
  switch (action.type) {
    case 'SET_USER_STATE':
      return {
        ...state,
        ...action.newUserState
      };

    case SIGN_OUT:
      cleanUserLocalStoreSurvey();
      return { ...initialState };

    default:
      return state;
  }
};
