import { useCallback, useState } from 'react';
import { Button } from '@mui/material';
import { generatePath, useHistory } from 'react-router';
import { PATH } from '../../routes/routes';
import { useTranslation } from 'react-i18next';
import ShareSurveyModal from './ShareSurveyModal';
import { PastSurvey, useGenerateSharedLink } from 'services';

// type ActionMenuItemsProps = {
//   pastSurvey: ValidPastSurvey;
// };
// export const ActionMenuItems: React.FC<ActionMenuItemsProps> = ({ pastSurvey }) => {
//   const { t } = useTranslation('buttons');
//   const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
//   const open = Boolean(anchorEl);
//   const { push } = useHistory();
//   const { loading, mutate: generateShareLink } = useGenerateSharedLink({});

//   const { InstanceId } = pastSurvey;

//   const handlePreview = useCallback(async () => {
//     const guid = await generateShareLink({ InstanceId });
//     push(generatePath(PATH.INDEX.SHARED_SURVEY_PREVIEW, { guid }));
//   }, [generateShareLink, push, InstanceId]);

//   const id = open ? 'simple-popover' : undefined;

//   const handleClick = useCallback(
//     (event: MouseEvent<HTMLButtonElement>) => {
//       setAnchorEl(event.currentTarget);
//     },
//     [setAnchorEl]
//   );

//   const handleClose = useCallback(() => {
//     setAnchorEl(null);
//   }, [setAnchorEl]);

//   return (
//     <>
//       <IconButton aria-label="view-more" color="primary" onClick={handleClick}>
//         <MoreHorizIcon />
//       </IconButton>
//       <Popover
//         id={id}
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClose}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
//         transformOrigin={{ vertical: 'top', horizontal: 'center' }}
//       >
//         <MenuList>
//           <MenuItem onClick={handlePreview}>{!loading ? t('preview') : '...'}</MenuItem>
//           <ShareSurveyMenuItem pastSurvey={pastSurvey} />
//         </MenuList>
//       </Popover>
//     </>
//   );
// };

// type ShareSurveyMenuItemProps = {
//   pastSurvey: ValidPastSurvey;
// };
// const ShareSurveyMenuItem: React.FC<ShareSurveyMenuItemProps> = ({ pastSurvey }) => {
//   const [open, setOpen] = useState(false);
//   const { t: tButtons } = useTranslation('buttons');

//   return (
//     <>
//       <MenuItem onClick={() => setOpen(true)}>{tButtons('share')}</MenuItem>
//       <ShareSurveyModal open={open} setOpen={setOpen} pastSurvey={pastSurvey} />
//     </>
//   );
// };

type ShareSurveyButtonProps = {
  pastSurvey: PastSurvey;
};
export const ShareSurveyButton: React.FC<ShareSurveyButtonProps> = ({ pastSurvey }) => {
  const [open, setOpen] = useState(false);
  const { t: tButtons } = useTranslation('buttons');

  return (
    <>
      <Button variant="outlined" color="secondary" size="small" onClick={() => setOpen(true)}>
        {tButtons('share')}
      </Button>
      <ShareSurveyModal open={open} setOpen={setOpen} pastSurvey={pastSurvey} />
    </>
  );
};

type PreviewSurveyButtonProps = {
  pastSurvey: PastSurvey;
};

export const PreviewSurveyButton: React.FC<PreviewSurveyButtonProps> = ({ pastSurvey }) => {
  const { t: tButtons } = useTranslation('buttons');
  const { push } = useHistory();
  const { mutate: generateShareLink } = useGenerateSharedLink({});

  const { InstanceId } = pastSurvey;

  const handlePreview = useCallback(async () => {
    const guid = await generateShareLink({ InstanceId });
    push(generatePath(PATH.INDEX.SHARED_SURVEY_PREVIEW, { guid }));
  }, [generateShareLink, push, InstanceId]);

  return (
    <Button variant="outlined" color="primary" size="small" onClick={handlePreview}>
      {tButtons('preview')}
    </Button>
  );
};
