import { useCallback, useState, useMemo, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  ClickAwayListener
} from '@mui/material';
import { useGenerateSharedLink, PastSurvey, useCompositeSendEmail, useStateSelector } from 'services';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import CopyIcon from '@mui/icons-material/FileCopy';
import Email from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import Skeleton from '@mui/material/Skeleton';
import { PATH } from '../../routes/routes';

type ShareSurveyModalProps = {
  open: boolean;
  setOpen(value: boolean): void;
  pastSurvey: PastSurvey;
  onExecute?(): void;
};

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    },
    title: {
      marginRight: theme.spacing(4)
    }
  })
);
const ShareSurveyModal = ({ open, setOpen, pastSurvey, onExecute = () => {} }: ShareSurveyModalProps) => {
  const { loading, mutate: generateShareLink } = useGenerateSharedLink({});
  const { mutate: sendEmail } = useCompositeSendEmail({});
  const [link, setLink] = useState<string | undefined>(undefined);
  const { t } = useTranslation('pastSurveys');
  const { t: tButtons } = useTranslation('buttons');
  const { t: tAccount } = useTranslation('account');

  const { closeButton, title } = useStyles();
  const [copied, setCopied] = useState(false);
  const { InstanceId, Contacts, SurveyName, DateCompleted, ContactName } = pastSurvey;
  const { userInfo } = useStateSelector('user');
  const dateCompleted = new Date(DateCompleted).toLocaleDateString();
  const hasContacts = Contacts.length > 0;
  const [email, setEmail] = useState(() => userInfo?.Email ?? userInfo?.UserName ?? '');
  const copyToClipboard = useCallback(() => {
    if (link) {
      navigator.clipboard.writeText(link);
      setCopied(true);
    }
  }, [link]);

  const handleCloseTooltip = useCallback(() => {
    setCopied(false);
  }, []);

  const sendServerSideEmail = useCallback(() => {
    sendEmail({ InstanceId, UserIdentifier: email });
    setOpen(false);
    onExecute();
    // we need to not add sendEmail because the ref is not stable
    // eslint-disable-next-line
  }, [InstanceId]);

  useEffect(() => {
    if (!open) {
      return;
    }
    const generateLink = async () => {
      const guid = await generateShareLink({ InstanceId });
      setLink(window.origin + generatePath(PATH.INDEX.SHARED_SURVEY, { guid }));
    };
    generateLink();
    // we need to not add generateShareLink because the ref is not stable
    // eslint-disable-next-line
  }, [InstanceId, open]);
  const mailLink = useMemo(
    () =>
      encodeURI(
        `mailto:${Contacts.join(',')}?subject=${t('sharedSurvey', {
          survey: SurveyName,
          date: dateCompleted
        })}&body=${t('shareableLink')} : ${link}`
      ),
    [link, t, Contacts, SurveyName, dateCompleted]
  );
  return (
    <Dialog
      open={open}
      keepMounted
      aria-labelledby="share-survey-title"
      maxWidth="md"
      aria-describedby="share-survey-description"
    >
      <DialogTitle id="share-survey-title">
        <div className={title}>{t('sharedSurvey', { survey: SurveyName, date: dateCompleted })}</div>
        <IconButton
          aria-label="close"
          className={closeButton}
          onClick={() => {
            setOpen(false);
            onExecute();
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="share-survey-description">{t('shareableLink')}:</DialogContentText>
        {loading ? (
          <Skeleton animation="wave" variant="rectangular" width="100%" height={56} />
        ) : (
          <TextField
            fullWidth
            variant="outlined"
            value={link || '-'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ClickAwayListener onClickAway={handleCloseTooltip}>
                    <div>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true
                        }}
                        open={copied}
                        onClose={handleCloseTooltip}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={`${t('copyToClipBoard')}`}
                      >
                        <IconButton disabled={!link} onClick={copyToClipboard} size="large">
                          <CopyIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </ClickAwayListener>
                </InputAdornment>
              )
            }}
          />
        )}
        {link && !loading && (
          <DialogActions>
            <Button onClick={() => onExecute()} href={mailLink} target="_blank">
              {tButtons('composeEmail')}
            </Button>
          </DialogActions>
        )}
        {loading && (
          <DialogActions>
            <Skeleton animation="wave" variant="rectangular" width={115} height={36} />
          </DialogActions>
        )}
        {hasContacts && (
          <>
            <DialogContentText id="share-with-clinic-title">{tButtons('shareWithClinic')}:</DialogContentText>
            <DialogContentText id="share-with-clinic-description" variant="body2">
              {t('shareWithClinicDescription', { contactName: ContactName })}
            </DialogContentText>
            <TextField
              fullWidth
              variant="outlined"
              placeholder={tAccount('usernameOrEmail')}
              value={email}
              onChange={e => setEmail(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="large">
                      <Email />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <DialogActions>
              <Button color="primary" onClick={() => sendServerSideEmail()} disabled={!Boolean(email)}>
                {tButtons('shareWithClinic')}
              </Button>
            </DialogActions>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ShareSurveyModal;
