import orderBy from 'lodash/orderBy';
import { languageById, supportedLanguages } from './locals';
import { Language } from '../types';
import { CONFIG } from '../constants';
import uniq from 'lodash/uniq';
export { default as LanguageId } from './languageId';

export { languageFor, dialectFor, dialectFromLanguageCode, languageById } from './locals';

const displayFor = (language: Language): string => {
  if (CONFIG.isProd) {
    return language.display;
  }

  return `${language.code} - ${language.display}`;
};

const orderedLanguages = (languages: Language[]) =>
  orderBy(
    languages.map(language => ({
      value: language.code,
      label: displayFor(language)
    })),
    'label'
  );

const availableLanguagesSorted = orderedLanguages(supportedLanguages);

export const availableLanguages = (availableLanguagesId: number[]) => {
  if (availableLanguagesId.length === 0) {
    return availableLanguagesSorted;
  }

  return orderedLanguages(uniq(availableLanguagesId.map(id => languageById(id))));
};
