import last from 'lodash/last';
import first from 'lodash/first';
import includes from 'lodash/includes';
import findIndex from 'lodash/findIndex';
import dayjs from 'dayjs';
import { sortByDisplayOrder } from './common';
import { isVisible } from './postfix';
import { indexOfQuestionById, isQuestionAnswered, isQuestionValid, questionById } from './questions';
import { now } from './time-helpers';
import { CurrentSurvey, NormalizedSection, Question } from './types';

export const isSurveyInvalid = (currentSurvey: CurrentSurvey): boolean => {
  return (
    currentSurvey.Id === -1 ||
    !currentSurvey.currentQuestionId ||
    currentSurvey.currentQuestionId === -1 ||
    currentSurvey.Questions.length < 1 ||
    hasExpired(currentSurvey) ||
    currentSurvey.submitted
  );
};
export const nextVisibleQuestion = (currentSurvey: CurrentSurvey): Question => {
  const { Questions: questions, currentQuestionId } = currentSurvey;
  const currentIndex = indexOfQuestionById(questions, currentQuestionId);
  const nextQuestionIndex = nextVisibleQuestionIndex(currentSurvey, currentIndex, currentIndex + 1);
  return questions[nextQuestionIndex];
};

export const previousVisibleQuestion = (currentSurvey: CurrentSurvey): Question => {
  const { Questions: questions, currentQuestionId } = currentSurvey;
  const currentIndex = indexOfQuestionById(questions, currentQuestionId);
  const nextQuestionIndex = previousVisibleQuestionIndex(currentSurvey, currentIndex, currentIndex - 1);
  return questions[nextQuestionIndex];
};

export const sectionContaining = (question: Question, currentSurvey: CurrentSurvey): NormalizedSection => {
  const section = currentSurvey.Sections.find(x => includes(x.Questions, question.Id));
  if (section) {
    return section;
  }
  throw new Error(`Cannot find section containing question ${question.Id}`);
};

export const indexOfSection = (section: NormalizedSection, currentSurvey: CurrentSurvey): number =>
  findIndex(currentSurvey.Sections, section);

export const visibleQuestionsInSection = (section: NormalizedSection, currentSurvey: CurrentSurvey): Question[] => {
  const questions = currentSurvey.Questions;
  const questionsInSection = section.Questions.filter(questionId => {
    const question = questionById(questions, questionId);
    return isVisible(question, currentSurvey);
  }).map(id => questionById(questions, id));

  return sortByDisplayOrder(questionsInSection);
};

export const answeredQuestionsInSurvey = (currentSurvey: CurrentSurvey): Question[] =>
  visibleQuestionsInSurvey(currentSurvey).filter(isQuestionAnswered);

export const visibleQuestionsInSurvey = (currentSurvey: CurrentSurvey): Question[] =>
  currentSurvey.Questions.filter(question => isVisible(question, currentSurvey));

export const validQuestionsInSurvey = (currentSurvey: CurrentSurvey): Question[] =>
  visibleQuestionsInSurvey(currentSurvey).filter(isQuestionValid);

export const isFirstQuestion = (question: Question, currentSurvey: CurrentSurvey): boolean =>
  first(currentSurvey.Questions) === question;

export const isLastQuestion = (question: Question, currentSurvey: CurrentSurvey): boolean => {
  const allVisibleQuestions = currentSurvey.Questions.filter(x => isVisible(x, currentSurvey));
  return last(allVisibleQuestions) === question;
};

export const firstUnansweredQuestionInSurvey = (currentSurvey: CurrentSurvey): Question | undefined =>
  visibleQuestionsInSurvey(currentSurvey).find(x => !isQuestionAnswered(x));

export const numberOfDaysLeftToCompleteSurvey = (currentSurvey: CurrentSurvey): number =>
  numberOfDaysUntilExpiryFrom(currentSurvey, now());

export const numberOfDaysToCompleteSurvey = (currentSurvey: CurrentSurvey): number =>
  numberOfDaysUntilExpiryFrom(currentSurvey, currentSurvey.CreatedOn);

const numberOfDaysUntilExpiryFrom = (currentSurvey: CurrentSurvey, fromDate: string | Date) => {
  if (!currentSurvey.isDefined) {
    return -1;
  }
  const expiresOn = dayjs(currentSurvey.ExpiresOn);
  const startDate = dayjs(fromDate);
  return Math.round(expiresOn.diff(startDate, 'day', true));
};

export const hasExpired = (currentSurvey: CurrentSurvey): boolean => {
  return numberOfDaysLeftToCompleteSurvey(currentSurvey) < 0;
};

const nextVisibleQuestionIndex = (
  currentSurvey: CurrentSurvey,
  currentQuestionIndex: number,
  nextQuestionIndex: number
): number => {
  const { Questions: questions } = currentSurvey;
  if (nextQuestionIndex >= questions.length) {
    return currentQuestionIndex;
  }

  if (isVisible(questions[nextQuestionIndex], currentSurvey)) {
    return nextQuestionIndex;
  }

  return nextVisibleQuestionIndex(currentSurvey, currentQuestionIndex, nextQuestionIndex + 1);
};

const previousVisibleQuestionIndex = (
  currentSurvey: CurrentSurvey,
  currentQuestionIndex: number,
  previousQuestionIndex: number
): number => {
  const { Questions: questions } = currentSurvey;
  if (previousQuestionIndex < 0) {
    return currentQuestionIndex;
  }

  if (isVisible(questions[previousQuestionIndex], currentSurvey)) {
    return previousQuestionIndex;
  }

  return previousVisibleQuestionIndex(currentSurvey, currentQuestionIndex, previousQuestionIndex - 1);
};
