import { Question, questions, useStateSelector } from 'services';
import { useState, useEffect } from 'react';

export const useCurrentQuestionLocalizations = () => {
  const question = useCurrentQuestion();
  return question.Supplements?.Localizations;
};

export const useCurrentQuestion = () => {
  const { currentQuestionId, Questions } = useStateSelector('survey');
  return questions.questionById(Questions, currentQuestionId);
};

export const useEffectOnQuestionChanged = (effect: (question: Question, previousQuestion: Question) => void) => {
  const currentQuestion = useCurrentQuestion();
  const [previousQuestion, setPreviousQuestion] = useState(currentQuestion);

  useEffect(() => {
    if (currentQuestion.Id !== previousQuestion.Id) {
      effect(currentQuestion, previousQuestion);
      setPreviousQuestion(currentQuestion);
    }
  }, [currentQuestion]);
};

export const useEffectOnQuestionAnswerChanged = (effect: (question: Question, previousQuestion: Question) => void) => {
  const currentQuestion = useCurrentQuestion();
  const [previousQuestion, setPreviousQuestion] = useState(currentQuestion);

  useEffect(() => {
    if (
      currentQuestion.Id === previousQuestion.Id &&
      (currentQuestion.Result !== previousQuestion.Result || currentQuestion.Table !== previousQuestion.Table)
    ) {
      effect(currentQuestion, previousQuestion);
    }
    setPreviousQuestion(currentQuestion);
  }, [currentQuestion]);
};
