import { Paper } from '@mui/material';
import { PaperProps } from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';

interface PaddedProps extends PaperProps {
  spacing?: number;
  bordered?: boolean;
}

const useStyle = makeStyles<Theme, PaddedProps>(theme => ({
  root: {
    padding: p => theme.spacing(p.spacing || 3),
    border: p => (p.bordered ? `1px solid ${theme.palette.divider}` : 'none')
  }
}));

export const PaddedPaper: FC<PaddedProps> = props => {
  const classes = useStyle(props);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { bordered, spacing, ...paperProps } = props;
  return (
    <Paper className={classes.root} {...paperProps}>
      {props.children}
    </Paper>
  );
};
