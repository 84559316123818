import { gradients } from 'theme';
import { LinearProgress } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Footer } from '../components/Footer';
import { Suspense } from 'react';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    background: {
      display: 'grid',
      justifyItems: 'center',
      gridTemplateColumns: 'auto',
      gridTemplateRows: '1fr auto',
      alignItems: 'center',
      width: '100%',
      minHeight: '100vh',
      ...gradients.TealTransparentBackground,
      [theme.breakpoints.down('md')]: {
        alignItems: 'start',
        background: theme.palette.background.paper
      }
    }
  })
);

export const Layout: React.FC = ({ children }) => {
  const classes = useStyles({});

  return (
    <div className={classes.background}>
      <Suspense fallback={<LinearProgress />}>{children}</Suspense>
      <Footer />
    </div>
  );
};
