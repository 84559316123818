import { useEffect, useMemo, FC } from 'react';
import { availableLanguages, dialectFor, languageById, store, useStateSelector } from 'services';
import { SelectionBox } from '../SelectionBox/SelectionBox';
import { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHandler } from 'components/hooks/useHandler';

export const LanguageSelection: FC<SelectProps> = props => {
  const { onChange, ...otherProps } = props;

  const { countryId, languageId, availableLanguageIds } = useStateSelector('system');
  const { t } = useTranslation('account');
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const languages = useMemo(() => availableLanguages(availableLanguageIds), [availableLanguageIds]);
  const languageCode = languageById(languageId).code;

  const changeLanguage = useHandler((languageCode: string | undefined, countryId: number) => {
    //We are trying to update top the same language
    if (languageCode === i18n.language) {
      return;
    }

    //language code might be undefined if we are just updating country
    const languageCodeToUse = languageCode ?? i18n.language;
    const dialect = dialectFor(languageCodeToUse, countryId);
    const {
      code: dialectCode,
      language: { languageId }
    } = dialect;
    i18n.changeLanguage(dialectCode);
    dispatch(store.actions.system.setLanguage(languageId));
  });

  useEffect(() => {
    const language = languageById(languageId);
    changeLanguage(language.code, countryId);
  }, [languageId, countryId, changeLanguage]);

  useEffect(() => {
    //Upon country update, we need to ensure that we hare using the matching
    //dialect if required.
    changeLanguage(undefined, countryId);
  }, [countryId, changeLanguage]);

  const handleChange = (e: SelectChangeEvent<any>) => {
    const lang = e.target.value;
    if (lang) {
      changeLanguage(lang, countryId);
    }
    return onChange;
  };

  return (
    <SelectionBox
      label={t('languageSelection')}
      onChange={handleChange}
      value={languageCode}
      options={languages}
      {...otherProps}
    />
  );
};
