import { FC } from 'react';

import { questions } from 'services';
import { Theme, Card } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { QuestionInfo } from '../QuestionInfo';
import { QuestionTitle } from '../QuestionTitle';
import { QuestionExampleTable } from '../QuestionExampleTable';
import { QuestionComponentFactory } from '../QuestionComponentFactory';
import { QuestionFooter } from '../QuestionFooter';
import { useCurrentQuestion, useResponsive } from '../../hooks';
import { EQ5DVASQuestionRange } from '../TableQuestion/EQ5DVASQuestion';

const useStyle = makeStyles<Theme, { isEQ5DQuestion: boolean }>(theme =>
  createStyles({
    root: ({ isEQ5DQuestion }) => ({
      display: 'flex',
      height: isEQ5DQuestion ? '100%' : 'auto',
      maxWidth: theme.breakpoints.values.md,
      width: '100%',
      maxHeight: '100%',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch'
    }),
    questionWrapper: {
      position: 'relative',
      display: 'grid',
      width: '100%',
      maxHeight: '100%',
      gridTemplateColumns: 'auto',
      gridTemplateRows: 'auto 1fr auto',
      [theme.breakpoints.down('lg')]: {
        gridTemplateRows: ({ isEQ5DQuestion }) => (isEQ5DQuestion ? 'auto 1fr auto' : 'auto max-content auto'),
        overflowY: 'auto'
      }
    },
    questionTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(2, 3)
    },
    questionNumber: {
      backgroundColor: theme.palette.primary.main,
      width: theme.spacing(4),
      height: theme.spacing(4)
    },
    questionAnswer: {
      padding: theme.spacing(0, 3),
      paddingBottom: theme.spacing(3),
      height: '100%',
      overflow: 'auto',
      width: '100%',
      [theme.breakpoints.down('lg')]: {
        overflow: 'unset',
        overflowX: 'auto'
      }
    }
  })
);

export const QuestionRenderer: FC<{ error?: string }> = ({ error }) => {
  const question = useCurrentQuestion();

  const isEQ5DQuestion = questions.isEQ5DVAS(question);
  const classes = useStyle({ isEQ5DQuestion });
  const { isMobile } = useResponsive();
  return (
    <Card className={classes.root} elevation={3}>
      <div className={classes.questionWrapper}>
        <div className={classes.questionTitle}>
          <QuestionTitle error={error} />
          <QuestionInfo />
        </div>
        <div className={classes.questionAnswer}>
          <QuestionExampleTable />
          {/* use key={question.Id} to ensure that a new component is rendered
             for two consecutive questions having the same type  */}
          <QuestionComponentFactory key={question.Id} />
        </div>
        {isEQ5DQuestion ? !isMobile && <QuestionFooter /> : <QuestionFooter />}
      </div>
      {isEQ5DQuestion && <EQ5DVASQuestionRange />}
    </Card>
  );
};
