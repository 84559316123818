import { Drawer, List, ListItem, ListItemIcon, ListItemText, AppBar, Toolbar, IconButton, Box } from '@mui/material';

import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import Menu from '@mui/icons-material/Menu';
import ArrowBack from '@mui/icons-material/ArrowBack';

import { useState } from 'react';
import { useResponsive } from 'components';

export interface SideMenuItemProps {
  label: string;
  icon: any;
  link: string;
  onClick?: () => void;
  hidden?: boolean;
}

interface SideNavProps {
  logoSrc?: string;
  headerLogoSrc?: string;
  width?: number;
  topMenuItems: SideMenuItemProps[];
  bottomMenuItems?: SideMenuItemProps[];
  onMenuItemClick: (path: string) => void;
  activeLinkChecker: (item: SideMenuItemProps) => boolean;
}
const boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)';

const useStyle = makeStyles<Theme, SideNavProps>(theme =>
  createStyles({
    appBar: {
      boxShadow
    },
    activeListItem: {
      background: theme.palette.primary.main + '88'
    },
    logo: {
      width: 140,
      padding: 0
    },
    drawerLogo: {
      width: 140,
      padding: 0
    },
    drawer: {
      width: props => props.width || 280
    },
    coloredBackground: {
      textAlign: 'center',
      background: theme.palette.primary.main
    }
  })
);

const useMenuStyle = makeStyles<Theme, {}>(theme =>
  createStyles({
    activeListItem: {
      backgroundColor: theme.palette.primary.main + '33 !important'
    }
  })
);

const SideMenuItems: React.FC<{
  items: SideMenuItemProps[];
  click: (path: string) => void;
  activeChecker: (item: SideMenuItemProps) => boolean;
}> = props => {
  const { click, items, activeChecker } = props;
  const classes = useMenuStyle({});
  return (
    <List component="div">
      {items.map(
        (MenuItem, i) =>
          !MenuItem.hidden && (
            <ListItem
              button
              key={i}
              onClick={MenuItem.onClick ? MenuItem.onClick : () => click(MenuItem.link)}
              selected={activeChecker(MenuItem)}
              className={activeChecker(MenuItem) ? classes.activeListItem : ''}
            >
              <ListItemIcon>
                <MenuItem.icon color={activeChecker(MenuItem) ? 'secondary' : 'primary'} />
              </ListItemIcon>
              <ListItemText primary={MenuItem.label} />
            </ListItem>
          )
      )}
    </List>
  );
};

export const SideNav: React.FC<SideNavProps> = props => {
  const { logoSrc, topMenuItems, bottomMenuItems, onMenuItemClick, activeLinkChecker, headerLogoSrc } = props;
  const classes = useStyle(props);
  const { isTablet } = useResponsive();
  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <>
      {isTablet && (
        <AppBar position="fixed" className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton onClick={() => setDrawerOpen(true)} edge="start" color="inherit" aria-label="menu" size="large">
              <Menu />
            </IconButton>
            {headerLogoSrc && <img src={headerLogoSrc} className={classes.logo} alt="logo" />}
          </Toolbar>
        </AppBar>
      )}
      <Drawer
        open={drawerOpen}
        variant={isTablet ? 'temporary' : 'permanent'}
        classes={{ paper: classes.drawer, root: classes.drawer }}
        onClick={() => setDrawerOpen(false)}
        BackdropProps={{
          onClick: () => setDrawerOpen(false)
        }}
      >
        <Box display="flex" flexDirection="column" style={{ height: 'inherit' }}>
          {logoSrc && !isTablet ? (
            <div className={classes.coloredBackground}>
              <img src={logoSrc} className={classes.drawerLogo} alt="logo" />
            </div>
          ) : (
            <AppBar position="static" className={classes.appBar} elevation={0}>
              <Toolbar>
                <IconButton
                  onClick={() => setDrawerOpen(false)}
                  edge="start"
                  color="inherit"
                  aria-label="menu-close"
                  size="large"
                >
                  <ArrowBack />
                </IconButton>
                {logoSrc && <img src={logoSrc} className={classes.logo} alt="logo" />}
              </Toolbar>
            </AppBar>
          )}
          <SideMenuItems items={topMenuItems} click={onMenuItemClick} activeChecker={activeLinkChecker} />
          <Box display="flex" flexGrow={1} />
          {bottomMenuItems && (
            <SideMenuItems items={bottomMenuItems} click={onMenuItemClick} activeChecker={activeLinkChecker} />
          )}
        </Box>
      </Drawer>
    </>
  );
};
