import { CSSProperties } from 'react';
import compact from 'lodash/compact';
import flattenDeep from 'lodash/flattenDeep';
import findIndex from 'lodash/findIndex';
import reduce from 'lodash/reduce';
import { Question, Row, Column } from './types';

const REMOVE_TOKEN = '&zwnj;';
const TOOL_TIP_TOKEN = '&#8203;';

const underlineStyle: CSSProperties = {
  textDecoration: 'underline'
};

const boldStyle: CSSProperties = {
  fontWeight: 'bolder'
};

const italicsStyle: CSSProperties = {
  fontStyle: 'italic'
};

const parser = new DOMParser();

export type StyledTextType = 'text' | 'tooltip' | 'newline';

export interface StyledText {
  text: string;
  style?: CSSProperties;
  type: StyledTextType;
  required?: boolean;
}

export const displayScoreValue = (value: number | undefined | null) =>
  value === undefined || value === null ? null : Math.round(value);

export const percentFrom = (value: number): string => `${Math.round(value * 100)}%`;

export const rowTitle = (row: Row): StyledText => answerableTitle(row, row.Required);

export const columnTitle = (column: Column, row: Row): StyledText =>
  answerableTitle(column, row.Required && column.Required);

const answerableTitle = (answerable: any, isRequired: boolean): StyledText =>
  asRequiredText(isRequired, titleFor(answerable));

const titleFor = (answerable: any): string => answerable.Text || answerable.Header || '';

const asRequiredText = (isRequired: boolean, text: string): StyledText => newText({ text, required: isRequired });

export const splitQuestionTitle = (question: Question): [StyledText[][], StyledText[][]] => {
  const [title, tooltip] = splitWithTooltip(question.Text);

  return [formattedLinesFor(title, question.Required), formattedLinesFor(tooltip)];
};

const formattedLinesFor = (text: StyledText, required = false): StyledText[][] => {
  if (!text) {
    return [];
  }
  const splitText = splitTextByStyle(purify(text.text));
  let styledText: StyledText[] = compact(flattenDeep(splitText));
  if (required) {
    styledText = [...styledText, newText({ text: '', required: true })];
  }

  return splitByNewLines(styledText);
};

const splitTextByStyle = (text: string, style?: CSSProperties): any[] | null => {
  if (!text) {
    return null;
  }

  return (
    splitTextByNewLine(text) ||
    splitTextBold(text, style) ||
    splitTextUnderline(text, style) ||
    splitTextItalic(text, style) || [newText({ text, style })]
  );
};

const splitByNewLines = (styledTexts: StyledText[]): StyledText[][] => {
  if (!styledTexts) {
    return [];
  }
  const newLineIndex = findIndex(styledTexts, x => x.type === 'newline');
  if (newLineIndex < 0) {
    return [styledTexts];
  }

  return [styledTexts.slice(0, newLineIndex), ...splitByNewLines(styledTexts.slice(newLineIndex + 1))];
};

const buildSplitTextWithStyle = (match: string[], newStyle: CSSProperties, currentStyle?: CSSProperties) => {
  return [
    splitTextByStyle(match[0], currentStyle),
    splitTextByStyle(match[1], { ...currentStyle, ...newStyle }),
    splitTextByStyle(match[2], currentStyle)
  ];
};

const matchRegexAndSplitTextWithStyle = (
  tag: string,
  text: string,
  newStyle: CSSProperties,
  currentStyle?: CSSProperties
) => {
  const htmlTagOpen = `<${tag}>`;
  const htmlTagEnd = `</${tag}>`;

  const indexStart = text.indexOf(htmlTagOpen);
  const indexEnd = text.indexOf(htmlTagEnd);
  if (indexStart < 0 || indexEnd < 0) {
    return null;
  }

  const before = text.substring(0, indexStart);
  const match = text.substring(indexStart + htmlTagOpen.length, indexEnd);
  const after = text.substring(indexEnd + htmlTagEnd.length);

  return buildSplitTextWithStyle([before, match, after], newStyle, currentStyle);
};

const splitTextByNewLine = (text: string) => {
  const arrayOfLines = text.match(/[^\r\n]+/g);
  if (!arrayOfLines || arrayOfLines.length <= 1) {
    return null;
  }

  return reduce(
    arrayOfLines,
    (acc: any, current: string, index) => {
      const newLine = index < arrayOfLines.length - 1 ? newText({ text: '', type: 'newline' }) : null;
      return [...acc, splitTextByStyle(current), newLine];
    },
    []
  );
};

const splitTextUnderline = (text: string, currentStyle?: CSSProperties) => {
  return matchRegexAndSplitTextWithStyle('u', text, underlineStyle, currentStyle);
};

const splitTextBold = (text: string, currentStyle?: CSSProperties) => {
  return matchRegexAndSplitTextWithStyle('b', text, boldStyle, currentStyle);
};

const splitTextItalic = (text: string, currentStyle?: CSSProperties) => {
  return matchRegexAndSplitTextWithStyle('i', text, italicsStyle, currentStyle);
};

const newText = ({
  text,
  type,
  style,
  required
}: {
  text: string;
  type?: StyledTextType;
  style?: CSSProperties;
  required?: boolean;
}): StyledText => ({
  text,
  style,
  type: type || 'text',
  required
});

const splitWithTooltip = (text: string) => {
  // EQ5D Question tooltip handler:
  const doc = parser.parseFromString(text, 'text/html');
  const eq5dList = doc.querySelector('.eq-5d-list');
  const copyright = doc.querySelector('.eq-5d-copyright');
  if (eq5dList) {
    if (copyright) {
      copyright.parentElement?.removeChild(copyright);
    }
    const tooltipText = doc.body.innerHTML;
    eq5dList.parentElement?.removeChild(eq5dList);
    const question = doc.body.innerHTML;
    return [newText({ text: question }), newText({ text: tooltipText, type: 'tooltip' })];
  }

  const regex = new RegExp(`${TOOL_TIP_TOKEN}(.*)${TOOL_TIP_TOKEN}(.*)`, 'gi');
  const match = regex.exec(text);

  // We have 2 block that we are capturing => total of 3 entries
  if (!match || match.length !== 3) {
    return [newText({ text })];
  }

  return [newText({ text: match[2].trim() }), newText({ text: match[1], type: 'tooltip' })];
};

// Remove all html tags that are not supported
export const purify = (text: string): string => {
  const htmlRemoverRegex = new RegExp(`${REMOVE_TOKEN}(?:.|\r|\n)*${REMOVE_TOKEN}`, 'gi');
  return text
    .replace(htmlRemoverRegex, '')
    .replace(/<p>/gi, '')
    .replace(/<\/p>/gi, '\n')
    .replace(/<br\/><br\/>/gi, '\n \n')
    .replace(/<br>/gi, '\n')
    .replace(/<br\/>/gi, '\n')
    .replace(/<br \/>/gi, '\n');
};

export const splitTableFromQuestion = (question: Question) => {
  const doc = parser.parseFromString(question.Text, 'text/html');
  const tableHeading = doc.querySelector('.panel-heading')?.innerHTML || '';
  const table: string[][] = [];
  doc.querySelectorAll('tr').forEach(doc => {
    const row: string[] = [];
    doc.querySelectorAll('td').forEach(doc => {
      row.push(doc.innerText);
    });
    table.push(row);
  });
  return { tableHeading, table };
};

export const displayDate = (date: Date | string | undefined, defaultValue = ''): string => {
  if (date === undefined) {
    return defaultValue;
  }
  const d = new Date(date);
  return `${d.toLocaleString('default', { month: 'short' })} ${d.getDate()} ${d.getFullYear()}`;
};
