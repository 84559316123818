import SignInRegisterWrapper from './SignInRegisterWrapper';
import { Switch, Route } from 'react-router';
import { PATH } from '../routes/routes';
import CountryAndLanguageSelection from '../components/CountryAndLanguageSelection';
import SignIn from '../components/SignIn';
import SignInOptions from '../components/SignInOptions';
import { lazy } from 'react';

const SSOLogin = lazy(() => import(/* webpackChunkName: "SSOLogin" */ '../components/SSOLogin'));
const Page404 = lazy(() => import(/* webpackChunkName: "Page404" */ '../components/404'));
const GuestForm = lazy(() => import(/* webpackChunkName: "GuestForm" */ '../components/GuestForm'));
const ParticipantForm = lazy(() => import(/* webpackChunkName: "ParticipantForm" */ '../components/ParticipantForm'));
const RegisterForm = lazy(() => import(/* webpackChunkName: "RegisterForm" */ '../components/RegisterForm'));

export const SignInLayout = () => (
  <SignInRegisterWrapper>
    <Switch>
      <Route exact path={PATH.INDEX.OAUTH_CALLBACK} component={SSOLogin} />
      <Route exact path={[PATH.INDEX.ROOT, PATH.INDEX.LOCALIZATION]} component={CountryAndLanguageSelection} />
      <Route exact path={PATH.INDEX.SIGN_IN_OPTIONS} component={SignInOptions} />
      <Route exact path={PATH.INDEX.SIGN_IN} component={SignIn} />
      <Route exact path={PATH.INDEX.GUEST} component={GuestForm} />
      <Route exact path={PATH.INDEX.PARTICIPANT} component={ParticipantForm} />
      <Route exact path={PATH.INDEX.REGISTER} component={RegisterForm} />
      <Route component={Page404} />
    </Switch>
  </SignInRegisterWrapper>
);
