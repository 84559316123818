import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { CacheToken, store, useAccountTokenCache } from 'services';
import { PATH } from '../routes/routes';
import { LoadingBar, useHandler, useNotification } from 'components';
import { useTranslation } from 'react-i18next';
import { useAsyncDispatch } from '../hooks/useAsyncDispatch';

export const AppDashboardHandler = () => {
  const { guid } = useParams() as { guid: string };
  const dispatch = useAsyncDispatch();
  const { push } = useHistory();
  const { data, loading, cancel, error } = useAccountTokenCache({ guid });

  const { notify } = useNotification();
  const { t: tError } = useTranslation('errors');

  const navigateToDashboardView = useHandler(async (token: CacheToken) => {
    const { startAsEmbeddedDashboard } = store.actions.system;
    try {
      await dispatch(startAsEmbeddedDashboard(token as CacheToken));
      push(PATH.INDEX.EMBEDDED_DASHBOARD);
    } catch (error) {
      notify({ message: tError(error.message), type: 'error' });
      push(PATH.INDEX.ROOT);
    }
  });

  useEffect(() => {
    if (loading) {
      return;
    }

    if (error) {
      push(PATH.INDEX.ROOT);
      return;
    }

    navigateToDashboardView(data as CacheToken);

    return () => {
      cancel();
    };
  }, [loading, data, error, cancel, navigateToDashboardView, push]);

  return <LoadingBar show={loading} />;
};
