import { useState, useEffect, FC } from 'react';
import { Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Slider from '@mui/material/Slider';
import { useTranslation } from 'react-i18next';
import { Question } from 'services';

const onSmallHeight = '@media (max-height:800px)';
const onSmallestHeight = '@media (max-height:650px)';
const useStyles = makeStyles<Theme, { value: null | number }>(
  theme =>
    createStyles({
      rangeSelectorWrapper: {
        display: 'grid',
        width: 'max-conent',
        justifyContent: 'center',
        height: '100%',
        gridGap: 10,
        gridRowGap: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          gridRowGap: theme.spacing(1)
        },
        gridTemplateRows: `auto 1fr auto`,
        padding: theme.spacing(1),
        border: `2px solid transparent`,
        '&:focus-within': {
          borderRadius: theme.shape.borderRadius,
          border: `2px solid ${theme.palette.primary.main}`
        }
      },
      sliderScale: {
        display: 'grid',
        gridTemplateColumns: `1fr 50px 1fr`,
        justifyItems: 'center',
        alignItems: 'strech',
        gridGap: 5
      },
      sliderNumbers: {
        display: 'flex',
        flexDirection: 'column-reverse',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        justifySelf: 'end',
        alignSelf: 'center',
        height: `calc(100% + 16px)`,
        [onSmallHeight]: {
          '& > *:nth-child(2n)': {
            display: 'none'
          }
        },
        [onSmallestHeight]: {
          '& > *': {
            display: 'none'
          },
          '& > *:nth-child(5n-4)': {
            display: 'block'
          }
        }
      },
      sliderTicks: {
        gridArea: '1 / 2 / 2 / 3',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'center',
        justifySelf: 'center',
        width: '90%',
        height: `100%`,
        position: 'relative',
        [onSmallHeight]: {
          '& > *:nth-child(2n)': {
            display: 'none'
          }
        }
      },
      sliderStyle: ({ value }) => ({
        gridArea: '1 / 2 / 2 / 3',
        height: '100%',
        '& > .MuiSlider-track': {
          display: 'none'
        },
        '& > .MuiSlider-rail': {
          display: 'none'
        },
        '& > .MuiSlider-thumb': {
          opacity: value === null ? 0 : 1,
          width: 20,
          height: 20,
          marginBottom: -10
        }
      }),
      tick: {
        backgroundColor: 'black',
        display: 'block',
        width: '40%',
        height: 2,
        '&:nth-child(10n-9)': {
          width: '100%'
        },
        [onSmallHeight]: {
          height: 1,
          '&:nth-child(10n-9)': {
            width: '40%'
          },
          '&:nth-child(20n-19)': {
            width: '100%'
          }
        },
        [onSmallestHeight]: {
          height: 1,
          '&:nth-child(20n-19)': {
            width: '40%'
          },
          '&:nth-child(50n-49)': {
            width: '100%'
          }
        }
      },
      text: {
        alignSelf: 'center',
        justifySelf: 'center',
        textAlign: 'center',
        fontWeight: 600,
        maxWidth: 100
      }
    }),
  { link: true }
);

interface RangeSelectorProps {
  question: Question;
  scaleFontSize?: number;
  defaultValue?: number | null;
  onChange?: (value: number) => void;
}

export const RangeSelector: FC<RangeSelectorProps> = ({ defaultValue, onChange, question }) => {
  const [value, setValue] = useState<number | null>(defaultValue || null);
  const classes = useStyles({ value });
  const { Supplements } = question;
  const localizations = Supplements?.Localizations;
  const { t } = useTranslation('eq5d');
  const labels = {
    bestHealth: localizations?.theBestHealth ?? t('theBestHealth'),
    worstHealth: localizations?.theWorstHealth ?? t('theWorstHealth')
  };

  const onRangeChange = (e: any, value: number | number[]) => {
    const currentValue = Array.isArray(value) ? value[0] : value;
    setValue(currentValue);
  };

  useEffect(() => {
    if (onChange && value !== null) {
      onChange(value);
    }
  }, [value, onChange]);

  return (
    <div className={classes.rangeSelectorWrapper}>
      <Typography variant="body2" className={classes.text}>
        {labels.bestHealth}
      </Typography>
      <div className={classes.sliderScale}>
        <div className={classes.sliderNumbers}>
          {Array.from(Array(11).keys()).map(i => (
            <Typography variant="caption" key={i}>
              {i * 10}
            </Typography>
          ))}
        </div>
        <div className={classes.sliderTicks}>
          {Array.from(Array(101).keys()).map(i => (
            <div className={classes.tick} key={i} />
          ))}
        </div>
        <Slider
          orientation="vertical"
          onChange={onRangeChange}
          color="secondary"
          value={value === null ? 0 : value}
          aria-labelledby="vertical-slider"
          className={classes.sliderStyle}
        />
      </div>
      <Typography variant="body2" className={classes.text}>
        {labels.worstHealth}
      </Typography>
    </div>
  );
};
