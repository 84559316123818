import { useStateSelector, supportsRegistration } from 'services';
import { Link, Typography, FormControlLabel, Checkbox, Button, CircularProgress } from '@mui/material';
import ArrowForward from '@mui/icons-material/ArrowForward';
import PersonAddOutlined from '@mui/icons-material/PersonAddOutlined';
import PeopleOutlined from '@mui/icons-material/PeopleOutlined';
import CheckBoxOutlineBlankOutlined from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlined from '@mui/icons-material/CheckBoxOutlined';

import { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '../Stack/Stack';

interface ParticipantFormProps {
  onParticipantRegister: () => Promise<void>;
  onClickRegister?: () => void;
  gotoSignIn: () => void;
}

export const ParticipantForm: FC<ParticipantFormProps> = ({ onParticipantRegister, onClickRegister, gotoSignIn }) => {
  const [agreed, setAgreed] = useState(false);
  const { t: tAccount } = useTranslation('account');
  const { t } = useTranslation('account');
  const { countryId, loginType } = useStateSelector('system');
  const allowsLogin = supportsRegistration(countryId, loginType);
  const [registering, setRegistering] = useState(false);
  const handleStartStudyAnonymously = async () => {
    try {
      setRegistering(true);
      await onParticipantRegister();
    } finally {
      setRegistering(false);
    }
  };

  const handleClickRegisterUser = () => {
    if (onClickRegister) {
      onClickRegister();
    }
  };

  return (
    <Stack p={3} data-testid="guest-form">
      <Stack variant="horizontal" alignItems="center" gap={2}>
        <PeopleOutlined color="primary" fontSize="large" />
        <Typography variant="h5">{tAccount('startStudyAnonymously')}</Typography>
      </Stack>
      {allowsLogin && (
        <>
          <Typography variant="body2">
            {tAccount('alreadyHaveAnAccount')} <Link onClick={gotoSignIn}>{tAccount('signIn')}</Link>
          </Typography>
          <Typography variant="body2">{tAccount('startStudyAnonymouslyExplanation')}</Typography>
        </>
      )}

      <FormControlLabel
        control={
          <Checkbox
            icon={<CheckBoxOutlineBlankOutlined color="secondary" />}
            checkedIcon={<CheckBoxOutlined color="secondary" />}
            checked={agreed}
            onChange={e => setAgreed(e.target.checked)}
          />
        }
        label={<Typography variant="body2">{tAccount('probeAcceptTermOfUseAgreement')}</Typography>}
      />
      <Stack gap={2} alignItems="center" justifyContent="stretch">
        <Button
          color="secondary"
          style={{ width: '100%' }}
          onClick={handleStartStudyAnonymously}
          size="large"
          startIcon={registering ? <CircularProgress size={20} /> : <ArrowForward />}
          disabled={!agreed || registering}
        >
          {t('startStudyAnonymously')}
        </Button>

        {allowsLogin && (
          <Button
            type="button"
            color="primary"
            size="large"
            aria-label={tAccount('signUp')}
            startIcon={<PersonAddOutlined />}
            onClick={handleClickRegisterUser}
            fullWidth
            disabled={registering}
          >
            {tAccount('signUp')}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
