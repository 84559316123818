import { Typography } from '@mui/material';
import { Stack, Fade } from 'components';
import { InstitutionInfo, useStateSelector } from 'services';
import { useState } from 'react';
import { useEffect } from 'react';
import { styled } from '@mui/system';

interface WelcomeTextProps {
  info: InstitutionInfo | null;
}

const Logo = styled('img')(({ theme }) => ({
  width: '100%',
  maxWidth: 500,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'white',
  justifySelf: 'center'
}));

const imageSourceForCountryId = (countryId: number) => `/assets/InstitutionLogos/${countryId}.jpg`;

const defaultLogo = 'assets/images/logo.png';

export const WelcomeText: React.FC<WelcomeTextProps> = ({ info }) => {
  const { countryId } = useStateSelector('system');
  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    setImgSrc(imageSourceForCountryId(countryId));
  }, [countryId]);

  if (!info) {
    return null;
  }

  return (
    <Fade>
      <Stack gap={3} width="100%">
        {imgSrc && <Logo alt="logo" src={imgSrc} onError={() => setImgSrc(defaultLogo)} />}
        <Typography variant="h5">{info.Name}</Typography>
        <Typography variant="body2" dangerouslySetInnerHTML={{ __html: info.Description }} />
      </Stack>
    </Fade>
  );
};
