import { FC, CSSProperties } from 'react';
import { Typography, Theme, Divider, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { formatter, Question } from 'services';
import { useTranslation } from 'react-i18next';
import { useCurrentQuestion } from '../hooks';
import ErrorIcon from '@mui/icons-material/ErrorOutlineRounded';
import clsx from 'clsx';

const useStyle = makeStyles<Theme>(theme =>
  createStyles({
    questionText: {
      ...theme.typography.body1,
      outline: 0,
      [theme.breakpoints.down('sm')]: {
        ...theme.typography.body2
      }
    },
    questionTextWrapper: {
      marginRight: theme.spacing()
    },
    requiredText: {
      color: theme.palette.warning.main,
      fontWeight: 500
    },
    errorText: {
      marginLeft: theme.spacing(),
      color: theme.palette.error.main
    }
  })
);

export const textLinesStyles: CSSProperties = {
  marginBottom: 10
};

const renderStyledText = (styledTexts: formatter.StyledText[], baseStyle: CSSProperties): React.ReactNode => {
  if (!styledTexts.length) {
    return null;
  }
  return styledTexts.map(styledText => {
    const { text, style } = styledText;
    const textStyles = { ...baseStyle, ...style };
    return <span key={text} style={textStyles} dangerouslySetInnerHTML={{ __html: text }} />;
  });
};

export const renderLine = (
  styledTexts: formatter.StyledText[],
  lineNumber: number,
  baseStyle: CSSProperties
): React.ReactNode => {
  return (
    <p key={lineNumber} style={baseStyle}>
      {renderStyledText(styledTexts, {})}
    </p>
  );
};

export const QuestionText: FC<{ question: Question }> = ({ question }) => {
  const [styledTexts] = formatter.splitQuestionTitle(question);
  return (
    <>
      {styledTexts.map((line, index) =>
        renderLine(line, index, index === styledTexts.length - 1 ? { display: 'inline' } : {})
      )}
    </>
  );
};

export const QuestionTitle: FC<{ error?: string }> = ({ error }) => {
  const classes = useStyle();
  const { t } = useTranslation('common');
  const question = useCurrentQuestion();
  const { questionText, requiredText, errorText, questionTextWrapper } = classes;
  const requiredClass = clsx(questionText, requiredText);
  const errorClass = clsx(questionText, errorText);
  return (
    <Box width="100%">
      <Typography component="div" className={questionText} tabIndex={0} data-question-text>
        <span className={questionTextWrapper}>
          <QuestionText question={question} />
        </span>
        {question.Required && (
          <Typography component="i" className={requiredClass}>
            ({t('required')})
          </Typography>
        )}
      </Typography>
      {error && (
        <div>
          <Divider />
          <Box display="flex" alignItems="center">
            <ErrorIcon color="error" />
            <Typography className={errorClass}>{error}</Typography>
          </Box>
        </div>
      )}
    </Box>
  );
};
