import { Question } from 'services';
import { useEffect, useRef, createRef } from 'react';

export const useFocusNext = <E extends HTMLElement>(question: Question) => {
  const questionRef = useRef(question);
  const container = createRef<E>();
  useEffect(() => {
    if (question.Id !== questionRef.current.Id && container.current) {
      const nextQuestion: HTMLInputElement | null = container.current.querySelector('[data-question-text]');
      if (nextQuestion) {
        nextQuestion.focus();
      }
      questionRef.current = question;
    }
  }, [question, container]);
  return container;
};
