import { useState, FC } from 'react';
import { Dialog, Link, Typography, Divider, Button, Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import LockIcon from '@mui/icons-material/Lock';
import { useTranslation } from 'react-i18next';
import { Stack } from '../Stack/Stack';
import useForm from 'react-hook-form';
import { object, string } from 'yup';
import { TextInput, useNotification } from '..';
import { auth } from 'services';

const useStyles = makeStyles<Theme, {}>(theme =>
  createStyles({
    iconText: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      alignItems: 'center',
      gridGap: theme.spacing(2),
      padding: theme.spacing(3)
    }
  })
);

interface ForgotPassForm {
  email: string;
}

export const ForgotPassword: FC<{
  defaultEmail?: string;
}> = ({ defaultEmail }) => {
  const [open, setOpen] = useState(false);
  const [passwordResetEmail, setPasswordResetEmail] = useState('');
  const classes = useStyles({});
  const { t: tAccount } = useTranslation('account');
  const { t: tCommon } = useTranslation('common');
  const { t: tError } = useTranslation('errors');
  const { notify } = useNotification();

  const required = tCommon('required');
  const form = useForm<ForgotPassForm>({
    mode: 'onBlur',
    defaultValues: {
      email: defaultEmail || ''
    },
    validationSchema: object<ForgotPassForm>({
      email: string().required(required).email(tError('emailInvalid'))
    })
  });

  const formHandler = async ({ email }: ForgotPassForm) => {
    try {
      await auth.resetPasswordAsync(email);
      setPasswordResetEmail(email);
      form.reset();
    } catch (error: any) {
      notify({
        type: 'error',
        message: tError(error.message)
      });
    }
  };

  return (
    <>
      <Link color="primary" style={{ width: '100%', textAlign: 'right' }} onClick={() => setOpen(true)}>
        {tAccount('forgotPassword')}
      </Link>
      <Dialog onClose={() => setOpen(false)} aria-labelledby="forgot-password-dialog" open={open} fullWidth>
        <div className={classes.iconText}>
          <LockIcon color="primary" fontSize="large" />
          <Typography variant="h6">{tAccount('resetPassword')}</Typography>
        </div>
        <Divider />
        <form onSubmit={form.handleSubmit(formHandler)}>
          <Stack p={3} width="100%">
            <TextInput<ForgotPassForm> name="email" formRef={form} label={tAccount('email')} fullWidth />
            <Button color="secondary" type="submit" disabled={form.formState.isSubmitting}>
              {tAccount('resetPassword')}
            </Button>
          </Stack>
        </form>
        {!!passwordResetEmail && (
          <>
            <Divider />
            <Box p={2}>
              <Typography variant="body1">
                {tAccount('resetPasswordInstruction', {
                  email: passwordResetEmail
                })}
              </Typography>
            </Box>
          </>
        )}
      </Dialog>
    </>
  );
};
