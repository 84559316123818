import { FormControl, InputLabel, MenuItem } from '@mui/material';
import Select, { SelectProps } from '@mui/material/Select';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';

const useStyles = makeStyles(
  createStyles({
    formControl: {
      minWidth: 120,
      width: '100%'
    }
  })
);

interface Props extends SelectProps {
  options: { label: string; value: string | number }[];
  width?: number | string;
}

export const SelectionBox: FC<Props> = props => {
  const { options, label, width, ...selectProps } = props;
  const classes = useStyles({});
  return (
    <FormControl className={classes.formControl} style={{ width }}>
      <InputLabel htmlFor={`${label}-select`}>{label}</InputLabel>
      <Select labelId={`${label}-select`} defaultValue={options[0].value} {...selectProps}>
        {options.map(({ value, label }, index) => (
          <MenuItem value={value} key={index}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
