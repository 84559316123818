import { useState, FC } from 'react';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Dialog, Box } from '@mui/material';
import { formatter, questions } from 'services';
import { renderLine, textLinesStyles, QuestionText } from './QuestionTitle';
import { useEffectOnQuestionChanged, useCurrentQuestion } from '../hooks/useQuestionHooks';
import { useResponsive } from '../hooks';
import { QuestionFooter } from './QuestionFooter';

const clearHTMLTags = (styleTexts: formatter.StyledText[]) =>
  styleTexts.map(styleText => ({ ...styleText, text: styleText.text.replace(/<[^>]*>?/gm, '') }));

export const QuestionInfo: FC = () => {
  const question = useCurrentQuestion();
  const isEQ5DQuestion = questions.isEQ5D(question);
  const isVASQuestion = questions.isEQ5DVAS(question);
  const [open, setOpen] = useState(true);
  const tooltip = formatter.splitQuestionTitle(question)[1];
  const { isMobile } = useResponsive();

  useEffectOnQuestionChanged(() => {
    setOpen(true);
  });

  if (!tooltip.length) {
    return null;
  }

  return (
    <>
      <InfoIcon onClick={() => setOpen(true)} style={{ cursor: 'pointer' }} color="secondary" />
      <Dialog onClose={() => setOpen(false)} aria-labelledby="question-info" open={open}>
        <Box p={3}>
          {!isEQ5DQuestion && <QuestionText question={question} />}
          {tooltip.map((line, index) => renderLine(clearHTMLTags(line), index, textLinesStyles))}
        </Box>
        {isVASQuestion && isMobile && <QuestionFooter />}
      </Dialog>
    </>
  );
};
