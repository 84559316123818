import { auth } from 'services';

export const PATH = {
  INDEX: {
    ROOT: '/',
    SUBMIT: '/submit',
    SHARE: '/share',
    LOCALIZATION: '/localization',
    OAUTH_CALLBACK: auth.SSO().path,
    SIGN_IN: '/signin',
    SIGN_IN_OPTIONS: '/signin_options',
    GUEST: '/guest',
    PARTICIPANT: '/participant',
    REGISTER: '/register',
    SETTINGS: '/settings',
    LOGOUT: '/logout',
    TERMS: '/terms-of-use',
    PRIVACY: '/privacy',
    INSTRUCTIONS: '/instructions',
    STUDY: '/study/:guid',
    SHARED_SURVEY: '/shared_survey/:guid',
    SHARED_SURVEY_PREVIEW: '/shared_survey_preview/:guid',
    APP_DASHBOARD: '/dashboard/:guid',
    EMBEDDED_DASHBOARD: '/dashboard'
  },
  SURVEY: {
    ROOT: '/survey'
  }
} as const;
