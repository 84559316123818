import { FC, ReactNode } from 'react';
import { useTransition, animated as Animated } from '@react-spring/web';

type Direction = 'left' | 'right' | 'top' | 'bottom';

interface Props {
  children: ReactNode;
  direction: Direction;
}

type TransitionValue = {
  opacity: number;
  transform: string;
};

type Transition = {
  from: TransitionValue;
  enter: TransitionValue;
  leave: TransitionValue;
};

const transitionStyle: Record<Direction, Transition> = {
  top: {
    from: { opacity: 0, transform: 'translateY(-100%)' },
    enter: { opacity: 1, transform: 'translateY(0%)' },
    leave: { opacity: 0, transform: 'translateY(-100%)' }
  },
  left: {
    from: { opacity: 0, transform: 'translateX(-100%)' },
    enter: { opacity: 1, transform: 'translateX(0%)' },
    leave: { opacity: 0, transform: 'translateX(-100%)' }
  },
  right: {
    from: { opacity: 0, transform: 'translateX(100%)' },
    enter: { opacity: 1, transform: 'translateX(0%)' },
    leave: { opacity: 0, transform: 'translateX(100%)' }
  },
  bottom: {
    from: { opacity: 0, transform: 'translateY(100%)' },
    enter: { opacity: 1, transform: 'translateY(0%)' },
    leave: { opacity: 0, transform: 'translateY(100%)' }
  }
};

export const Swipe: FC<Props> = ({ children, direction }) => {
  const transitions = useTransition(true, transitionStyle[direction]);
  return <>{transitions((style, item) => item && <Animated.div style={style}>{children}</Animated.div>)}</>;
};
