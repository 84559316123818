import { createTheme } from '@mui/material/styles';
import palette from './palette';
import { gradients } from './gradients';

const buttonShadow = (color: string) => `0px 15px 20px -8px ${color}7f`;
const buttonShadowPressed = (color: string) => `0px 15px 15px -10px ${color}7f`;

export const light = createTheme({
  palette: palette,
  shape: {
    borderRadius: 10
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: 'secondary',
        variant: 'contained'
      },
      styleOverrides: {
        root: {
          borderRadius: 100,
          width: 'max-content'
        },
        contained: {
          boxShadow: 'none'
        },
        containedPrimary: {
          boxShadow: buttonShadow(palette.primary.main),
          '&:hover': {
            boxShadow: buttonShadowPressed(palette.primary.light)
          }
        },
        containedSecondary: {
          boxShadow: buttonShadow(palette.secondary.main),
          '&:hover': {
            boxShadow: buttonShadowPressed(palette.secondary.light)
          }
        }
      }
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: buttonShadow(palette.grey[500]),
          '&:hover': {
            boxShadow: buttonShadowPressed(palette.grey[500])
          }
        },
        extended: {
          '& svg': {
            marginRight: 8
          },
          '& .material-icons': {
            marginRight: 8
          }
        },
        primary: {
          boxShadow: buttonShadow(palette.primary.main),
          '&:hover': {
            boxShadow: buttonShadowPressed(palette.primary.light)
          }
        },
        secondary: {
          boxShadow: buttonShadow(palette.secondary.main),
          '&:hover': {
            boxShadow: buttonShadowPressed(palette.secondary.light)
          }
        }
      }
    },

    MuiTextField: {
      defaultProps: {
        variant: 'filled'
      }
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'filled'
      }
    },
    MuiIcon: {
      defaultProps: {
        sx: {
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          ...gradients.IconGradient
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:after': {
            height: '2px',
            borderBottom: 'none',
            ...gradients.TealBlueLinear
          },
          '&.Mui-error:after': {
            background: 'red'
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#333'
        },
        h3: {
          color: '#404040'
        },
        h6: {
          color: '#404040'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0.04)',
          borderTopRightRadius: 4,
          borderTopLeftRadius: 4
        }
      }
    }
  }
});

export * from './gradients';
