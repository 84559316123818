import find from 'lodash/find';
import filter from 'lodash/filter';
import { Language, Dialect } from '../types';

import LanguageId from './languageId';

import CountryId from '../countries-list/countryId';
import { countryByLanguageCode } from '../countries-list/countries';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const en = require('../assets/locals/en.json');

export const EN = 'en';

export const locals = {
  en
};

const createLanguage = (
  languageId: number,
  code: string,
  display: string,
  isReducedBold = false,
  isRTL = false
): Language => ({
  languageId,
  code,
  display,
  isRTL,
  isReducedBold: isReducedBold || isRTL
});

const createMainDialect = (language: Language): Dialect => createDialect(language, language.code);

const createDialect = (language: Language, code: string, countryId?: number): Dialect => ({
  language,
  code,
  countryId
});

const english = createLanguage(LanguageId.EN, EN, 'English');
const chinese = createLanguage(LanguageId.ZH, 'zh', '中文', true);

export const supportedLanguages: Language[] = [english, chinese];

export const supportedDialects: Dialect[] = [
  createMainDialect(english),
  createMainDialect(chinese),
  createDialect(chinese, 'zh_tw', CountryId.TAIWAN)
];

export const languageByCode = (code: string | undefined): Language => findLanguageByCode(code) || languageByCode(EN);

export const languageById = (id: number): Language =>
  find(supportedLanguages, { languageId: id }) || languageByCode(EN);

const dialectByCode = (code: string | undefined): Dialect => findDialectByCode(code) || dialectByCode(EN);

const defaultDialectFor = (countryId: number, languageCode: string | undefined): Dialect | undefined => {
  if (!languageCode) {
    return find(supportedDialects, { countryId });
  }

  return find(filter(supportedDialects, { countryId }), d => d.language.code === languageCode);
};

const findLanguageByCode = (code: string | undefined): Language | undefined => find(supportedLanguages, { code });

const findDialectByCode = (code: string | undefined): Dialect | undefined => find(supportedDialects, { code });

export const languageFor = (local: string): Language => {
  const lowerLocal = local.toLowerCase();

  // explicit
  return (
    findLanguageByCode(lowerLocal) ||
    // implicit
    find(supportedLanguages, x => lowerLocal.startsWith(x.code)) ||
    // default
    languageByCode(EN)
  );
};

export const dialectFor = (languageCode: string | undefined, countryId?: number | undefined): Dialect => {
  const defaultDialectForLanguage = dialectByCode(languageCode);

  // No language and country selected? Return the default dialect
  if (!countryId && !languageCode) {
    return defaultDialectForLanguage;
  }

  if (!countryId) {
    return defaultDialectForLanguage;
  }

  const bestDialectForCountryAndLanguage = defaultDialectFor(countryId, languageCode) || defaultDialectForLanguage;

  // no dialect selected? Return the default for the country
  if (!languageCode) {
    return bestDialectForCountryAndLanguage;
  }

  // Optimal dialect and current dialect do not have the same origin language? return the dialect
  if (defaultDialectForLanguage.language !== bestDialectForCountryAndLanguage.language) {
    return defaultDialectForLanguage;
  }

  return bestDialectForCountryAndLanguage;
};

export const dialectFromLanguageCode = (languageCode: string) => {
  const { code } = languageFor(languageCode);
  const { id } = countryByLanguageCode(languageCode);
  return dialectFor(code, id);
};
