import { Typography, Button } from '@mui/material';
import { useHomeStyles } from '../styles/Home.style';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { PATH } from '../routes/routes';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useStateSelector, actions, firstUnansweredQuestionInSurvey } from 'services';
import { useDispatch } from 'react-redux';
import { Stack, WidgetCard } from 'components';

export const WelcomeSurvey = () => {
  const classes = useHomeStyles();
  const { push } = useHistory();
  const { t: tDashboard } = useTranslation('dashboard');
  const { t: tSurveyInstructions } = useTranslation('surveyInstructions');
  const survey = useStateSelector('survey');
  const dispatch = useDispatch();

  const gotoSurvey = () => {
    const firstQuestion = firstUnansweredQuestionInSurvey(survey);
    if (firstQuestion) {
      dispatch(actions.updateCurrentQuestion(firstQuestion.Id));
    }
    dispatch(actions.surveyInstructionsRead());
    dispatch(actions.updateSurveyTemp());
    push(PATH.SURVEY.ROOT);
  };

  return (
    <div className={classes.root}>
      <div data-testid="home-page" className={classes.content}>
        <WidgetCard>
          <Stack gap={3}>
            <Typography variant="h3" className={classes.surveyTitle}>
              {tDashboard('welcomeToProbe', { studyName: survey.Title })}
            </Typography>
            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: survey.Instructions }} />
            <Button color="secondary" size="large" onClick={gotoSurvey} startIcon={<ArrowForward />}>
              {tSurveyInstructions('continue')}
            </Button>
          </Stack>
        </WidgetCard>
      </div>
    </div>
  );
};
