import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

interface Props {
  gap?: number;
  horizontal?: boolean;
}

export const Space: FC<Props> = ({ gap, horizontal }) => {
  const theme = useTheme();
  return (
    <div
      style={
        horizontal
          ? {
              display: 'inline-block',
              height: '100%',
              width: theme.spacing(gap || 5)
            }
          : {
              width: '100%',
              height: theme.spacing(gap || 5)
            }
      }
    />
  );
};
