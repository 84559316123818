import { FC } from 'react';
import { useCurrentQuestion } from '../../hooks';

import { FixedTableQuestion } from './FixedTableQuestion';
import { TableMultipleOptionQuestion } from './TableMultipleOptionQuestion';
import { RepeatRowQuestion } from './RepeatRowQuestion';
import { EQ5DVASQuestion } from './EQ5DVASQuestion';

import { models, questions } from 'services';

const { TableTypes } = models;

export const TableQuestion: FC = () => {
  const question = useCurrentQuestion();
  const table = questions.tableFrom(question);
  const tableType = table.TableType.Id;

  switch (tableType) {
    case TableTypes.RADIO:
    case TableTypes.CHECKBOX:
      return <TableMultipleOptionQuestion question={question} />;
    case TableTypes.REPEAT_ROW:
      return <RepeatRowQuestion question={question} />;
    case TableTypes.FIXED:
      return questions.isEQ5DVAS(question) ? (
        <EQ5DVASQuestion question={question} />
      ) : (
        <FixedTableQuestion question={question} />
      );
  }
  return <></>;
};
