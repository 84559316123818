const CountryId = {
  CANADA: 1,
  USA: 2,
  ARGENTINA: 4,
  AUSTRALIA: 5,
  BRAZIL: 6,
  COLOMBIA: 7,
  FRANCE: 8,
  GERMANY: 9,
  HUNGARY: 10,
  IRELAND: 11,
  ITALY: 12,
  JAPAN: 13,
  MEXICO: 14,
  NEW_ZEALAND: 15,
  SPAIN: 16,
  NETHERLAND: 17,
  UNITED_KINGDOM: 18,
  VENEZUELA: 19,
  NIGERIA: 20,
  POLAND: 21,
  VIETNAM: 22,
  DENMARK: 23,
  DOM_REPUBLIC: 24,
  RUSSIA: 25,
  SOUTH_AFRICA: 26,
  THAILAND: 27,
  TUNISIA: 28,
  BELGIUM: 29,
  CHINA: 30,
  ISRAEL: 31,
  BULGARIA: 32,
  SOUTH_KOREA: 33,
  TAIWAN: 34,
  ALBANIA: 35,
  ARMENIA: 36,
  AUSTRIA: 37,
  CHILE: 38,
  COSTA_RICA: 39,
  ECUADOR: 40,
  ESTONIA: 41,
  FINLAND: 42,
  HONDURAS: 43,
  IRAN: 44,
  KYRGYZSTAN: 45,
  LATVIA: 46,
  LITHUANIA: 47,
  PANAMA: 48,
  PORTUGAL: 49,
  SWEDEN: 50,
  UKRAINE: 51,
  URUGUAY: 52,
  AZERBAIJAN: 53,
  BARBADOS: 54,
  EL_SALVADOR: 55,
  TRINIDAD_AND_TOBAGO: 56,
  BELIZE: 57,
  GUATEMALA: 58,
  JAMAICA: 59,
  NICARAGUA: 60,
  BOLIVIA: 61,
  PERU: 62,
  CUBA: 63,
  PARAGUAY: 64,
  ALGERIA: 65,
  EGYPT: 66,
  JORDAN: 67,
  LEBANON: 68,
  MOROCCO: 69,
  OMAN: 70,
  QATAR: 71,
  CROATIA: 72,
  CZECH_REPUBLIC: 73,
  GHANA: 74,
  INDIA: 75,
  INDONESIA: 76,
  KENYA: 78,
  MALAYSIA: 79,
  MAURITIUS: 80,
  NEPAL: 81,
  PHILIPPINES: 82,
  SERBIA: 84,
  SINGAPORE: 85,
  SWITZERLAND: 87,
  TURKEY: 88,
  UNITED_ARAB_EMIRATES: 89,
  BAHRAIN: 90,
  KUWAIT: 91,
  LIBYA: 92,
  SAUDI_ARABIA: 93,
  UGANDA: 94,
  IRAQ: 95,
  SUDAN: 96,
  SYRIA: 97,
  PALESTINE: 98,
  PAKISTAN: 99,
  GREECE: 100,
  IVORY_COAST: 101,
  MADAGASCAR: 102,
  MALAWI: 103,
  SENEGAL: 104,
  CAMEROON: 105,
  ETHIOPIA: 106,
  GABON: 107,
  NORWAY: 108,
  BANGLADESH: 109,
  UZBEKISTAN: 110,
  ZAMBIA: 111
};

export default CountryId;
