import { Stack, useNotification } from 'components';
import { actions, formatter, useStateSelector, useStudiesMetaData, StudyMetaData, SurveyStatus } from 'services';
import { Card, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { PATH } from '../../routes/routes';
import { useHomeStyles } from '../../styles/Home.style';
import { UpcomingSurveysLoading } from './../HomeLoading';
import { useAsyncDispatch } from '../../hooks/useAsyncDispatch';
import { MobileUpcomingSurveyTable, UpcomingSurveyTable, UpcomingSurveyTableProps } from './UpcomingSurveyTable';

export const UpcomingSurveys: React.FC = () => {
  const { t } = useTranslation('dashboard');
  const classes = useHomeStyles();
  const theme = useTheme();
  const survey = useStateSelector('survey');
  const { token } = useStateSelector('user');
  const { isUsingStudyLink, studyId } = useStateSelector('system');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAsyncDispatch();
  const { data, loading } = useStudiesMetaData({});
  const { push } = useHistory();
  const { notify } = useNotification();

  const handleTakeSurvey = useCallback(
    (studyIdToStart: number) => async () => {
      if (token) {
        const success = await dispatch(
          actions.createNewSurvey(studyIdToStart, /*shouldCreateNewSurvey:*/ true, error => {
            notify({ type: 'error', message: error?.message });
          })
        );
        if (success) {
          push(PATH.SURVEY.ROOT);
        }
      }
    },
    [token, dispatch, push, notify]
  );

  const upcomingSurveys: UpcomingSurveyTableProps['rows'] = useMemo(() => {
    if (!data) {
      return [];
    }
    const defaultDisplay = '-';
    return (data as StudyMetaData[])
      .filter(x => x.CurrentStep)
      .map(({ Name, Description, CurrentStep, StudyId }) => {
        const { AvailabilityDate, Status } = CurrentStep;

        //This is the status from this step but even if it is available, we only authorize one survey at a time
        const status: SurveyStatus = survey.isDefined ? 'InProgress' : Status;
        const availableToTake = status === 'Available';

        const formattedDate =
          status !== 'Completed' ? formatter.displayDate(AvailabilityDate, defaultDisplay) : defaultDisplay;

        return {
          name: Name || defaultDisplay,
          status,
          description: Description || defaultDisplay,
          date: formattedDate,
          availableToTake,
          studyId: StudyId,
          triggerTakeSurvey: availableToTake ? handleTakeSurvey(StudyId) : () => {}
        };
      });
  }, [data, handleTakeSurvey, survey]);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!isUsingStudyLink) {
      return;
    }

    const upcomingSurveyForStudy = upcomingSurveys.find(x => x.studyId === studyId);

    if (!upcomingSurveyForStudy || upcomingSurveyForStudy.status !== 'Available') {
      return;
    }

    upcomingSurveyForStudy.triggerTakeSurvey();
  }, [upcomingSurveys, loading, isUsingStudyLink, studyId]);

  const UpcomingTableComponent = isMobile ? MobileUpcomingSurveyTable : UpcomingSurveyTable;

  if (loading) {
    return <UpcomingSurveysLoading />;
  }

  return (
    <Card className={classes.model}>
      <Stack variant="horizontal" gap={1} alignItems="center">
        <DateRangeIcon fontSize="large" color="action" />
        <Typography className={classes.cardTitle}>{t('upcomingSurveys')}</Typography>
      </Stack>
      <Divider />
      <UpcomingTableComponent rows={upcomingSurveys} />
    </Card>
  );
};
