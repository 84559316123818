import { Fab } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/system';

interface Props {
  onClickBack?: () => void;
  color?: string;
}

const Container = styled('div')(({ theme }) => ({
  gridArea: [1, 1, 1, 1].join(' / '),
  justifySelf: 'start',
  alignSelf: 'start',
  padding: theme.spacing(3)
}));

export const BackButton: React.FC<Props> = ({ onClickBack, color }) => {
  if (!onClickBack) {
    return null;
  }
  return (
    <Container>
      <Fab
        onClick={onClickBack}
        aria-label="go back"
        sx={{ backgroundColor: theme => color ?? theme.palette.background.paper, color: '#444' }}
      >
        <ArrowBack color="primary" />
      </Fab>
    </Container>
  );
};
