import { useState } from 'react';
import { IconButton, InputAdornment } from '@mui/material';

import { InputProps, TextInput } from './TextInput';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export const PasswordInput = <Form extends {}>(Props: InputProps<Form>) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const passwordProps = {
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="Toggle password visibility"
            onClick={() => setPasswordVisibility(!passwordVisibility)}
            size="large"
          >
            {passwordVisibility ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      )
    }
  };
  return <TextInput<Form> {...Props} type={passwordVisibility ? 'text' : 'password'} {...passwordProps} />;
};
