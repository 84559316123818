import { Fab, FabProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';

type FabOutlineProps = FabProps & {
  color: 'primary' | 'secondary';
};
const useStyle = makeStyles<Theme, FabOutlineProps>(theme =>
  createStyles({
    root: {
      background: 'rgba(0,0,0,0)',
      border: ({ color }) => `1px solid ${theme.palette[color].main}`,
      color: ({ color }) => theme.palette[color].main,
      boxShadow: 'none',
      '&:hover': {
        color: ({ color }) => theme.palette[color].contrastText
      }
    }
  })
);

export const FabOutlined: FC<FabOutlineProps> = props => {
  const classes = useStyle(props);
  return <Fab {...props} className={classes.root} />;
};
