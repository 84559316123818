import { Stack } from '../../Stack/Stack';
import { Box, Divider } from '@mui/material';
import { questions, Question, Row } from 'services';
import { ColumnAnswerRenderer } from './ColumnAnswerRenderer';
import { FC, ReactNode, Fragment } from 'react';

interface FixedTableRowsProps {
  question: Question;
  columnAppendRender?: (row: Row) => ReactNode;
  rowAppendRender?: () => ReactNode;
}

const gridTemplateFor = (row: Row, multipleRows: boolean) => {
  if (row.Header && multipleRows) {
    return 'minmax(200px, 2fr) repeat( auto-fit, minmax(200px, 2fr))';
  }
  return 'repeat( auto-fit, minmax(200px, 1fr))';
};
export const FixedTableRows: FC<FixedTableRowsProps> = ({ question, columnAppendRender, rowAppendRender }) => {
  const { Rows: rows } = questions.tableFrom(question);

  console.log({ rows });
  const multipleRows = rows.length > 1;
  return (
    <Stack gap={2} width="100%">
      {rows.map(row => (
        <Fragment key={row.Id}>
          <Divider />
          <Box display="flex" justifyContent="space-between">
            <Box
              display="grid"
              width="100%"
              style={{
                gridGap: 10,
                gridTemplateColumns: gridTemplateFor(row, multipleRows)
              }}
              mr={1}
            >
              {row.Header && multipleRows && <Box sx={{ display: 'flex', alignItems: 'center' }}>{row.Header}</Box>}
              {row.Columns.map(column => {
                return <ColumnAnswerRenderer key={column.Id} column={column} row={row} question={question} />;
              })}
            </Box>
            {columnAppendRender && columnAppendRender(row)}
          </Box>
        </Fragment>
      ))}
      {rowAppendRender && rowAppendRender()}
    </Stack>
  );
};

export const FixedTableQuestion: FC<{ question: Question }> = props => {
  const { question } = props;
  return <FixedTableRows question={question} />;
};
