import { useTranslation } from 'react-i18next';
import { useStateSelector, questions, useFormattedSurveySpentTime } from 'services';
import BarsIcon from '@mui/icons-material/BarChartOutlined';
import { InfoWithIcon } from '../InfoWithIcon';
import TimeIcon from '@mui/icons-material/Timer';
import { useHomeStyles } from '../../styles/Home.style';

export const SurveyStats = () => {
  const classes = useHomeStyles();
  const { t: tSurveyStats } = useTranslation('surveyStats');
  const survey = useStateSelector('survey');
  const surveyTime = useFormattedSurveySpentTime();

  const currentStats = questions.currentQuestionsStats(survey);
  return (
    <div className={classes.stats}>
      <InfoWithIcon
        icon={<BarsIcon />}
        title={tSurveyStats('onGoingStatsTitle')}
        info={tSurveyStats('numberOfAnsweredQuestions', {
          number: currentStats.done,
          total: currentStats.outOf
        })}
      />
      <InfoWithIcon
        icon={<TimeIcon />}
        title={tSurveyStats('onGoingTimeSpentTitle')}
        info={tSurveyStats('onGoingTimeSpent', {
          time: surveyTime
        })}
      />
    </div>
  );
};
