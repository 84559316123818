import { Token, CompositeSurvey } from '../types';
import { doNormalize } from '../normalizer';
import { createAction, createError } from './helpers';
import * as api from '../api';

import { TokenParams } from '../auth';

import { AppState } from '../store';
import { refreshTokenIfRequired, signOut } from '../store/User/User.actions';
import { setAppLoading } from '../store/System/System.actions';
import { setSurveyExpired } from './current-survey';
import { SurveyInstanceTemp } from '../api';

export const UPDATE_TEMP_DATA = 'UPDATE_TEMP_DATA';

export const LOADING_SURVEY = 'LOADING_SURVEY';
export const LOADING_SURVEY_SUCCESS = 'LOADING_SURVEY_SUCCESS';
export const LOADING_SURVEY_FAILED = 'LOADING_SURVEY_FAILED';
export const SUBMIT_SURVEY = 'SUBMIT_SURVEY';
export const SUBMIT_SURVEY_SUCCESS = 'SUBMIT_SURVEY_SUCCESS';
export const SUBMIT_SURVEY_FAILED = 'SUBMIT_SURVEY_FAILED';

export const QUESTIONS_UPLOADED_SUCCESS = 'QUESTIONS_UPLOADED_SUCCESS';
export const QUESTIONS_UPLOADED_FAILED = 'QUESTIONS_UPLOADED_FAILED';

const submitSurveySuccess = () => createAction(SUBMIT_SURVEY_SUCCESS, {});

export type SurveyActions = ReturnType<typeof submitSurveySuccess>;

const doInLoading = async <T>(actionToPerform: () => Promise<T>, dispatch: any): Promise<T> => {
  try {
    dispatch(setAppLoading(true));
    return await actionToPerform();
  } finally {
    dispatch(setAppLoading(false));
  }
};

export const submitSurvey =
  (errorHandler?: (error: Error) => void) =>
  async (dispatch: any, getState: () => AppState): Promise<boolean> => {
    await dispatch(refreshTokenIfRequired());

    const { survey, user, stats } = getState();
    const { token, isGuest } = user;
    if (!token) {
      return false;
    }

    const { studyId } = survey;
    dispatch(createAction(SUBMIT_SURVEY, {}));
    try {
      await doInLoading(() => api.submitSurveyAsync(survey, stats, studyId, token), dispatch);
      dispatch(submitSurveySuccess());
      if (isGuest) {
        dispatch(signOut());
      }
      return true;
    } catch (error: any) {
      errorHandler?.(error);
      dispatch(createError(SUBMIT_SURVEY_FAILED, error));
    }
    return false;
  };

export const createNewSurvey =
  (studyId: number, shouldCreateNewSurvey?: boolean, errorHandler?: (error: Error) => void) =>
  async (dispatch: any, getState: () => AppState): Promise<boolean> => {
    const { user } = getState();
    const { token, providerToken, provider: providerName } = user;

    if (!token) {
      return false;
    }

    const tokenParams: TokenParams = {
      token,
      providerToken,
      providerName
    };

    dispatch(createAction(LOADING_SURVEY, {}));
    try {
      const { survey, tempData } = await doInLoading(
        () => retrieveNewNormalizedSurveyAsync(studyId, tokenParams, shouldCreateNewSurvey),
        dispatch
      );
      dispatch(createAction(LOADING_SURVEY_SUCCESS, { normalizedSurvey: survey }));
      dispatch(createAction(UPDATE_TEMP_DATA, { tempData: { ...tempData, studyId } }));
      return true;
    } catch (error: any) {
      const errorName = error?.data?.Error?.Name;
      // Error with authentication server. We log the user out
      if (errorName === 'o_auth_error') {
        dispatch(signOut());
        return false;
      }
      const surveyExpiryDate = error?.data?.Info?.Expired;
      if (surveyExpiryDate && errorName === 'survey_expired') {
        dispatch(setSurveyExpired(surveyExpiryDate));
      } else {
        errorHandler?.(error);
        dispatch(createError(LOADING_SURVEY_FAILED, error));
      }
      return false;
    }
  };

const retrieveNewNormalizedSurveyAsync = async (
  studyId: number,
  tokenParams: TokenParams,
  shouldCreateNewSurvey: boolean | undefined
) => {
  let survey: CompositeSurvey;
  const { token } = tokenParams;
  if (shouldCreateNewSurvey) {
    survey = await api.createNewSurveyAsync(studyId, token);
  } else {
    survey = await api.loadSurveyAsync(studyId, token);
  }
  const data = await api.getSurveyInstanceTempAsync(survey.InstanceId, token);
  return { survey: doNormalize(survey), tempData: data };
};

export const retrieveSurveyForInstanceId =
  (instanceId: number, studyId: number, token: Token, errorHandler?: (error: Error) => void) =>
  async (dispatch: any) => {
    let survey: CompositeSurvey | undefined = undefined;
    let tempData: Partial<SurveyInstanceTemp> = {};
    try {
      survey = await api.loadSurveyUsingInstanceIdAsync(instanceId, token);
      dispatch(createAction(LOADING_SURVEY_SUCCESS, { normalizedSurvey: doNormalize(survey), studyId }));
      tempData = await api.getSurveyInstanceTempAsync(instanceId, token);
      tempData.studyId = studyId;
      dispatch(createAction(UPDATE_TEMP_DATA, { tempData }));
    } catch (error: any) {
      errorHandler?.(error);
    }
  };

export const updateSurveyTemp =
  () =>
  async (dispatch: any, getState: () => AppState): Promise<void> => {
    const {
      survey,
      user: { token },
      stats: { timeSpent }
    } = getState();
    const { InstanceId, currentQuestionId, instructionsRead, submitted, studyId } = survey;

    if (!token) {
      return;
    }
    await api.updateSurveyInstanceTempAsync(
      { currentQuestionId, instructionsRead, submitted, timeSpent, studyId },
      InstanceId,
      token
    );
  };
