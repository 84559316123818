import { useState } from 'react';
import { actions, useStateSelector } from 'services';
import { Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { useAsyncDispatch } from '../../hooks/useAsyncDispatch';

interface CreateSurveyButtonProps {
  studyId: number;
  isCurrentSurveyExpired?: boolean;
}

export const CreateSurveyButton: React.FC<CreateSurveyButtonProps> = ({ studyId, isCurrentSurveyExpired }) => {
  const { t: tDashboard } = useTranslation('dashboard');
  const { token } = useStateSelector('user');
  const dispatch = useAsyncDispatch();
  const [creatingNewSurvey, setSurveyCreated] = useState(false);

  const handleNewSurvey = async () => {
    try {
      setSurveyCreated(true);
      if (token) {
        await dispatch(actions.createNewSurvey(studyId, isCurrentSurveyExpired));
      }
    } catch {
      //Only reset if there is an error. Otherwise creation of survey will navigate to another screen and component will be unmounted
      setSurveyCreated(false);
    }
  };

  return (
    <Button
      color="secondary"
      size="large"
      onClick={handleNewSurvey}
      startIcon={creatingNewSurvey ? <CircularProgress size={20} /> : <ArrowForward />}
      disabled={creatingNewSurvey}
    >
      {tDashboard('startNewSurvey')}
    </Button>
  );
};
