import { FC } from 'react';

import { Divider, Typography, Box } from '@mui/material';
import { useCurrentQuestionLocalizations } from '../hooks';

export const QuestionFooter: FC = () => {
  const localizations = useCurrentQuestionLocalizations();
  const copyright = localizations?.eq5dCopyright;

  if (!copyright) {
    return null;
  }

  return (
    <Box mt={2}>
      <Divider />
      <Box p={2}>
        <Typography variant="caption">{copyright.replace('&copy;', '©')}</Typography>
      </Box>
    </Box>
  );
};
