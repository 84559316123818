import { useMemo } from 'react';
import { Stack } from 'components';
import { SurveyStatus } from 'services';
import { Chip, Typography, Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomTableProps, CustomTable, useTableStyle } from './Table';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { useHomeStyles } from '../../styles/Home.style';
import { FC } from 'react';

const SurveyRow: FC<{ status: string }> = ({ status, children }) => (
  <Stack variant="horizontal" gap={1} alignItems="center">
    {status === '' ? (
      <EventAvailableIcon fontSize="large" color="primary" />
    ) : (
      <InsertInvitationIcon fontSize="large" color="action" />
    )}
    <div>{children}</div>
  </Stack>
);

const StatusChip: FC<{ status: SurveyStatus; className?: string }> = ({ className, status }) => {
  const { t } = useTranslation('dashboard');
  return (
    <Chip
      size="small"
      label={t(`status.${status}`)}
      variant="outlined"
      disabled={status !== 'Available'}
      className={className}
      color={status === 'Available' ? 'primary' : 'default'}
    />
  );
};

export interface UpcomingSurvey {
  name: string;
  status: SurveyStatus;
  description: string;
  date: string;
  availableToTake: boolean;
  studyId: number;
  triggerTakeSurvey: () => void;
}

export interface UpcomingSurveyTableProps {
  rows: UpcomingSurvey[];
}

export const MobileUpcomingSurveyTable: React.FC<UpcomingSurveyTableProps> = ({ rows }) => {
  const { t } = useTranslation('dashboard');
  const tableHeaders = [t('survey')];
  const tableRow: CustomTableProps['rows'] = useMemo(
    () =>
      rows.map(({ name, status, date, availableToTake, triggerTakeSurvey }) => [
        {
          scope: 'row',
          style: { width: '100%' },
          children: (
            <Stack gap={2} alignItems="center" width="100%">
              <Box display="flex" flex={1} justifyContent="space-between" alignItems="center">
                <SurveyRow status={status}>
                  <Typography>{name}</Typography>
                </SurveyRow>
                <StatusChip status={status} />
              </Box>
              {status !== 'Completed' && (
                <Box display="flex" flex={1} justifyContent="space-between" alignItems="center">
                  <Typography variant="caption">{date}</Typography>
                  <Button disabled={!availableToTake} color="primary" size="medium" onClick={triggerTakeSurvey}>
                    {t('takeSurvey')}
                  </Button>
                </Box>
              )}
            </Stack>
          )
        }
      ]),
    [rows, t]
  );

  return <CustomTable headers={tableHeaders} rows={tableRow} />;
};

export const UpcomingSurveyTable: React.FC<UpcomingSurveyTableProps> = ({ rows }) => {
  const { t } = useTranslation('dashboard');
  const tableClasses = useTableStyle();
  const classes = useHomeStyles();
  const tableHeader = useMemo(() => [t('surveyName'), t('statusText'), t('dateText'), t('actions')], [t]);

  const tableRow: CustomTableProps['rows'] = useMemo(
    () =>
      rows.map(({ name, status, description, date, availableToTake, triggerTakeSurvey }) => [
        {
          scope: 'row',
          children: (
            <SurveyRow status={status}>
              <Typography>{name}</Typography>
              <Typography variant="caption">{description}</Typography>
            </SurveyRow>
          )
        },
        {
          className: `${tableClasses.tableCell} ${classes.textCenter}`,
          children: <StatusChip status={status} />
        },
        {
          className: `${tableClasses.tableCell} ${classes.textCenter}`,
          children: date
        },
        {
          className: `${tableClasses.tableCell} ${classes.textCenter}`,
          children: status !== 'Completed' && (
            <Button disabled={!availableToTake} color="primary" size="small" onClick={triggerTakeSurvey}>
              {t('takeSurvey')}
            </Button>
          )
        }
      ]),
    [rows, t, classes, tableClasses]
  );

  return <CustomTable headers={tableHeader} rows={tableRow} />;
};
