import orderBy from 'lodash/orderBy';
import supportedCountries, { countryBy } from './countries';
import { Country } from '../types';
import { CONFIG } from '../constants';
import uniq from 'lodash/uniq';
export * from './signInOptions';
export { default as countryId } from './countryId';

export * from './countries';

const displayFor = (country: Country): string => {
  if (CONFIG.isProd) {
    return country.display;
  }
  return `${country.code} - ${country.display}`;
};

export const initialCountryId = 1;

const orderedCountries = (countries: Country[]) =>
  orderBy(
    countries.map(country => ({
      value: country.id,
      label: displayFor(country)
    })),
    'label'
  );

const availableCountriesSorted = orderedCountries(supportedCountries);

export const countries = orderBy(
  supportedCountries.map(x => ({ value: x.id, label: displayFor(x) })),
  'label'
);

export const availableCountries = (availableCountryIds: number[]) => {
  if (availableCountryIds.length === 0) {
    return availableCountriesSorted;
  }

  return orderedCountries(uniq(availableCountryIds.map(id => countryBy(id))));
};
