import { CustomTable, CustomTableProps, useTableStyle } from './Table';
import { Box, Typography } from '@mui/material';
import { PreviewSurveyButton, ShareSurveyButton } from './ActionMenuItems';
import { useHomeStyles } from '../../styles/Home.style';
import { useTranslation } from 'react-i18next';
import { useTheme, Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import { formatter, PastSurvey } from 'services';
import { Stack } from 'components';

interface PastSurveyTableProps {
  pastSurveys: PastSurvey[];
  selectedInstanceId: number | undefined;
  onSelected: (pastSurvey: PastSurvey) => void;
}

const useStyle = makeStyles<Theme>(theme =>
  createStyles({
    scores: {
      marginTop: theme.spacing(1)
    },
    table: {
      maxHeight: 425
    },
    optionCells: {
      padding: theme.spacing(0, 0),
      cursor: 'default'
    }
  })
);

const formatScore = (score: number | null | undefined) => score ?? 'NA';

const ScoreEntry = ({ caption, value }: { caption: string; value: number | null | undefined }) => {
  const { t } = useTranslation('pastSurveys');
  return (
    <Typography variant="caption">
      <strong>
        {t(caption)} {formatScore(value)}
      </strong>
    </Typography>
  );
};

interface PastSurveyTableRowProps {
  pastSurvey: PastSurvey;
  cellStyle: string;
  formattedDate: string;
  onSelected: (pastSurvey: PastSurvey) => void;
}

const getMobilePastSurveyTableRow = ({ pastSurvey, cellStyle, formattedDate, onSelected }: PastSurveyTableRowProps) => {
  const { EQ5DScore, EQ5DVas, ProbeScore, SurveyName } = pastSurvey;

  const scoresForMobile = (
    <Box display="flex" flexDirection="column">
      <ScoreEntry caption="probe" value={ProbeScore} />
      <ScoreEntry caption="eq5d" value={EQ5DScore} />
      <ScoreEntry caption="vas" value={EQ5DVas} />
    </Box>
  );

  return [
    {
      scope: 'row',
      className: cellStyle,
      onClick: () => onSelected(pastSurvey),
      children: (
        <Box display="flex" justifyContent="space-between">
          <div>
            <Typography style={{ whiteSpace: 'nowrap' }}>{SurveyName}</Typography>
            <Typography variant="caption">{formattedDate}</Typography>
            {scoresForMobile}
          </div>
          <Box display="flex" flexDirection="column" justifyContent="space-evenly" alignItems="center">
            <Stack gap={1}>
              <ShareSurveyButton pastSurvey={pastSurvey} />
              <PreviewSurveyButton pastSurvey={pastSurvey} />
            </Stack>
          </Box>
        </Box>
      )
    }
  ];
};

const PastSurveyTable: React.FC<PastSurveyTableProps> = ({ pastSurveys, selectedInstanceId, onSelected }) => {
  const tableClasses = useTableStyle();
  const classes = useHomeStyles();
  const { t } = useTranslation('pastSurveys');
  const theme = useTheme();
  const tableHeader = [t('survey'), t('probe'), t('eq5d'), t('vas'), t('actions'), ''];
  const tableMobileHeader = [t('survey')];
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const pastSurveyClasses = useStyle();
  const headerData = isMobile ? tableMobileHeader : tableHeader;

  const tableRow: CustomTableProps['rows'] = pastSurveys.map(pastSurvey => {
    const { DateCompleted, EQ5DScore, EQ5DVas, ProbeScore, SurveyName, InstanceId } = pastSurvey;
    const isSurveyActive = selectedInstanceId === InstanceId;
    const cellStyle = isSurveyActive ? tableClasses.tableHighlight : '';
    const scoresCellClassNames = clsx(tableClasses.tableCell, classes.textCenter, cellStyle);
    const formattedDate = formatter.displayDate(DateCompleted);

    if (isMobile) {
      return getMobilePastSurveyTableRow({ pastSurvey, cellStyle, formattedDate, onSelected });
    }

    const scores = [ProbeScore, EQ5DScore, EQ5DVas].map(score => {
      return {
        className: scoresCellClassNames,
        onClick: () => onSelected(pastSurvey),
        children: formatScore(score)
      };
    });

    return [
      {
        scope: 'row',
        className: cellStyle,
        onClick: () => onSelected(pastSurvey),
        children: (
          <>
            <Typography style={{ whiteSpace: 'nowrap' }}>{SurveyName}</Typography>
            <Typography variant="caption">{formattedDate}</Typography>
          </>
        )
      },
      ...scores,
      {
        className: `${tableClasses.tableCell} ${classes.textCenter} ${cellStyle} ${pastSurveyClasses.optionCells}`,
        children: <ShareSurveyButton pastSurvey={pastSurvey} />
      },
      {
        className: `${tableClasses.tableCell} ${classes.textCenter} ${cellStyle} ${pastSurveyClasses.optionCells}`,
        children: <PreviewSurveyButton pastSurvey={pastSurvey} />
      }
    ];
  });

  return <CustomTable headers={headerData} rows={tableRow} className={pastSurveyClasses.table} />;
};

export default PastSurveyTable;
