import { Reducer } from 'redux';
import produce from 'immer';
import { initialCountryId } from '../../countries-list';
import { initialLanguage } from '../../localization';
import { SystemActions } from './System.actions';
import { LoginType } from '../../types';
import { languageFor } from '../../languages-list';
import { UserActions } from '../User/User.actions';
import { SurveyActions } from '../../actions';
import { STUDY_GUID, STUDY_ID } from '../../constants';

const languageCode = localStorage.getItem('i18nextLng') || initialLanguage;

export type SystemState = {
  countryId: number;
  languageId: number;
  appLoading: boolean;
  availableLanguageIds: number[];
  availableCountryIds: number[];
  loginType: LoginType;
  studyGuid: string;
  studyId: number;
  isUsingStudyLink?: boolean;
  firstLogin?: boolean;
};

const defaultSurveySystem = {
  studyGuid: STUDY_GUID,
  studyId: STUDY_ID,
  availableLanguageIds: [],
  availableCountryIds: [],
  loginType: LoginType.ThirdPartyApp | LoginType.Probe | LoginType.Guest
};

export const initialState: SystemState = {
  countryId: initialCountryId,
  languageId: languageFor(languageCode).languageId,
  appLoading: false,
  ...defaultSurveySystem
};

export const SystemReducer: Reducer<SystemState, SystemActions | UserActions | SurveyActions> = (
  state = initialState,
  action
): SystemState =>
  produce(state, (draft: SystemState) => {
    switch (action.type) {
      case 'SET_LANGUAGE': {
        draft.languageId = action.languageId;
        return;
      }
      case 'SET_LOADING': {
        draft.appLoading = action.appLoading;
        return;
      }
      case 'SET_COUNTRY_ID': {
        draft.countryId = action.countryId;
        return;
      }
      case 'SET_STUDY_LINK_ID': {
        draft.studyGuid = action.studyGuid;
        draft.studyId = action.studyId;
        draft.isUsingStudyLink = true;
        draft.firstLogin = action.firstLogin;
        return;
      }
      case 'SET_LOGIN_TYPE': {
        draft.loginType = action.loginType;
        return;
      }
      case 'SET_AVAILABLE_LANGUAGE_IDS': {
        draft.availableLanguageIds = action.languageIds;
        if (draft.availableLanguageIds.length > 0 && !draft.availableLanguageIds.includes(draft.languageId)) {
          draft.languageId = draft.availableLanguageIds[0];
        }
        return;
      }
      case 'SET_AVAILABLE_COUNTRY_IDS': {
        draft.availableCountryIds = action.countryIds;
        if (draft.availableCountryIds.length > 0 && !draft.availableCountryIds.includes(draft.countryId)) {
          draft.countryId = draft.availableCountryIds[0];
        }
        return;
      }
      case 'SUBMIT_SURVEY_SUCCESS': {
        //Survey submitted, we want to reset everything related to link and study
        draft.studyGuid = defaultSurveySystem.studyGuid;
        draft.studyId = defaultSurveySystem.studyId;
        //Once the survey is submitted, we do not want to start another one again
        draft.isUsingStudyLink = false;
        return;
      }

      case 'SIGN_OUT':
        return {
          ...state,
          ...defaultSurveySystem
        };
    }
  });
