import { FC, ChangeEventHandler } from 'react';
import { TextField } from '@mui/material';
import { questions, actions } from 'services';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCurrentQuestion } from '../../hooks';

const { QuestionTypes } = questions;

interface QuestionComponentProps {
  label: string;
  value?: string;
  type: 'text' | 'multiline' | 'number';
  ariaLabel?: string;
  width?: number | string;
  onChange?: QuestionComponentProps['type'] extends 'multiline'
    ? ChangeEventHandler<HTMLInputElement>
    : ChangeEventHandler<HTMLTextAreaElement>;
}

export const TextQuestionComponent: FC<QuestionComponentProps> = ({
  value,
  type,
  onChange,
  label,
  ariaLabel,
  width
}) => {
  return (
    <TextField
      label={label}
      type={type}
      style={{ width: width || '100%' }}
      onChange={onChange}
      value={value}
      multiline={type === 'multiline'}
      maxRows={4}
      rows={4}
      inputProps={{
        'aria-label': ariaLabel || label
      }}
    />
  );
};

export const TextQuestion: FC = () => {
  const dispatch = useDispatch();
  const question = useCurrentQuestion();

  const { t } = useTranslation('surveyCompletion');
  const defaultValue = question.Result[0]?.InputText || '';
  const questionType: QuestionComponentProps['type'] = (() => {
    switch (question.QuestionType.Id) {
      case QuestionTypes.TEXT_NUMERIC:
        return 'number';
      case QuestionTypes.TEXT_AREA:
        return 'multiline';
      default:
        return 'text';
    }
  })();

  const handleAnswer: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
    const value = questionType === 'number' ? e.target.value.replace(/\D/g, '') : e.target.value;
    dispatch(actions.answerTextQuestion(question, value));
  };

  return (
    <TextQuestionComponent
      label=""
      type={questionType}
      value={defaultValue}
      onChange={handleAnswer}
      ariaLabel={t('edit')}
    />
  );
};
