type ScoreValue = number | null | undefined;

type WithScores = {
  ProbeScore?: ScoreValue;
  EQ5DScore?: ScoreValue;
  EQ5DVas?: ScoreValue;
};

export const hasScore = ({ ProbeScore, EQ5DScore, EQ5DVas }: WithScores): boolean => {
  return [ProbeScore, EQ5DScore, EQ5DVas].some(x => Boolean(x));
};

export const scaleScores = ({ ProbeScore, EQ5DScore, EQ5DVas }: WithScores): WithScores => {
  return {
    //already between 0 and 100
    EQ5DVas,
    //between 0 and 1
    ProbeScore: scaleValue(ProbeScore, 0, 1),
    EQ5DScore: scaleValue(EQ5DScore, -0.28098, 1)
  };
};

const scaleValue = (score: ScoreValue, oldMin: number, oldMax: number): ScoreValue => {
  if (score === undefined || score === null) {
    return score;
  }

  return ((score - oldMin) / (oldMax - oldMin)) * 100;
};
