import sortBy from 'lodash/sortBy';
import { normalize, schema } from 'normalizr';
import { CompositeSurvey, NormalizedSection, Question, NormalizedSurvey } from './types';

export const doNormalize = (survey: CompositeSurvey): NormalizedSurvey => {
  const question = new schema.Entity('Questions', {}, { idAttribute: 'Id' });
  const requisite = new schema.Entity('Requisites', {}, { idAttribute: 'RequisiteId' });
  const section = new schema.Entity(
    'Sections',
    {
      Questions: [question]
    },
    { idAttribute: 'Id' }
  );
  const root = new schema.Entity(
    'Surveys',
    {
      Sections: [section],
      Requisites: [requisite]
    },
    { idAttribute: 'InstanceId' }
  );

  const normalizedData = normalize(survey, root);

  return normalizedDataWithSortedQuestionsBySection(normalizedData);
};

const normalizedDataWithSortedQuestionsBySection = (normalizedData: any): NormalizedSurvey => {
  const { entities } = normalizedData;
  const surveyData = entities.Surveys[normalizedData.result];
  const { Instructions, LanguageCode, Title, InstanceId, ProjectId, Id, CreatedOn, ExpiresOn } = surveyData;
  const { Questions, Requisites, Sections } = entities;
  const sections: NormalizedSection[] = Object.values(Sections);
  const questions: Question[] = Object.values(Questions);
  return {
    Id,
    InstanceId,
    ProjectId,
    Instructions,
    LanguageCode,
    Title,
    CreatedOn,
    ExpiresOn,
    Sections: sections,
    Requisites,
    Questions: sortBy(questions, 'ProcessingOrder')
  };
};
