import { Typography } from '@mui/material';
import { useHomeStyles } from '../../styles/Home.style';
import { useTranslation } from 'react-i18next';
import { useStateSelector, numberOfDaysToCompleteSurvey } from 'services';
import CalenderIcon from '@mui/icons-material/InsertInvitationOutlined';
import { CreateSurveyButton } from './CreateSurveyButton';
import { Space, Stack, WidgetCard } from 'components';

export const ExpiredSurvey = () => {
  const classes = useHomeStyles();
  const { t: tDashboard } = useTranslation('dashboard');
  const survey = useStateSelector('survey');
  const expireDays = numberOfDaysToCompleteSurvey(survey);

  return (
    <WidgetCard>
      <Typography variant="h3" className={classes.surveyTitle}>
        {tDashboard('surveyExpired')}
      </Typography>
      <Stack variant="horizontal" gap={2}>
        <CalenderIcon color="error" />
        <Typography variant="subtitle1" color="error">
          {tDashboard('surveyExpiredDescription', {
            timeInDays: expireDays
          })}
        </Typography>
      </Stack>
      <Space gap={2} />
      <CreateSurveyButton studyId={survey.studyId} isCurrentSurveyExpired={true} />
    </WidgetCard>
  );
};
