import { actions, Column, formatter, Question, questions, Row } from 'services';
import { Checkbox, FormControlLabel, Radio, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import some from 'lodash/some';
import difference from 'lodash/difference';
import { FC, useMemo, memo, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Stack, useMultipleAnswersStyles } from '../../';
import { useResponsive } from '../../hooks';

type TableByColumns = { columns: Column[]; rows: Row[] };

const isColumnsEqual = (col1: Column[], col2: Column[]) =>
  !difference(
    col1.map(c => c.Id),
    col2.map(c => c.Id)
  ).length;

const groupRowsByColumn = (rows: Row[]) => {
  const group: TableByColumns[] = [];
  rows.forEach(row => {
    const { Columns: columns } = row;
    let isGroupExists = false;
    group.map(g => {
      if (isColumnsEqual(g.columns, columns)) {
        g.rows.push(row);
        isGroupExists = true;
      }
      return g;
    });
    if (!isGroupExists) {
      group.push({
        columns,
        rows: [row]
      });
    }
  });
  return group;
};

interface TableProps {
  table: TableByColumns;
  question: Question;
  handleChange: (row: Row, column: Column, nextRowId?: number) => () => void;
}
interface AnswerProps {
  question: Question;
  column: Column;
  row: Row;
  nextRowId?: number;
  handleChange: () => void;
}

const useStyles = makeStyles<Theme, TableProps>(theme =>
  createStyles({
    tableWrapper: ({ table }) => ({
      display: 'grid',
      position: 'relative',
      gridTemplateColumns: `auto repeat(${table.columns.length}, 1fr)`,
      gridGap: theme.spacing(2),
      gridRowGap: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: `auto`,
        gridRowGap: 0
      },
      '& > *': {
        justifySelf: 'center',
        alignSelf: 'center',
        [theme.breakpoints.down('md')]: {
          justifySelf: 'start'
        },
        [`&:nth-child(${table.columns.length + 1}n + 1)`]: {
          justifySelf: 'start',
          [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(2)
          }
        },
        [`&:nth-child(-n+${table.columns.length + 1})`]: {
          [theme.breakpoints.down('md')]: {
            display: 'none'
          },
          position: 'sticky',
          top: 0,
          backgroundColor: theme.palette.background.paper,
          alignSelf: 'stretch',
          padding: theme.spacing(1),
          textAlign: 'center',
          width: '100%',
          height: '100%',
          zIndex: 1,
          borderBottom: `1px solid ${theme.palette.divider}`
        }
      }
    })
  })
);

const RenderAnswer: FC<AnswerProps> = props => {
  const { column, question, row, handleChange } = props;
  const answer = column.Answers[0];
  const selected = some(column.Result, { AnswerId: answer.Id });
  const { isMobile } = useResponsive();
  const classes = useMultipleAnswersStyles({});

  const Selector = questions.isSingleAnswerTableQuestion(question, column) ? Radio : Checkbox;

  if (!isMobile) {
    return (
      <Selector checked={selected} name={row.Id.toString()} onMouseDown={handleChange} onKeyPress={handleChange} />
    );
  }

  return (
    <FormControlLabel
      value={`${answer.Id}`}
      style={{ marginLeft: 5, width: '100%' }}
      control={<Selector checked={selected} />}
      onMouseDown={handleChange}
      onKeyPress={handleChange}
      name={`${row.Id}`}
      label={<Typography variant="body2">{answer.Value}</Typography>}
      className={selected ? classes.activeOption : ''}
    />
  );
};

const nextRowId = (rows: Row[], index: number) => rows[index + 1]?.Id || undefined;

const Table: FC<TableProps> = props => {
  const classes = useStyles(props);
  const { table, question, handleChange } = props;

  return (
    <div className={classes.tableWrapper}>
      <div></div>
      {table.columns.map(column => (
        <div key={column.Id}>{column.Answers[0].Value}</div>
      ))}
      {table.rows.map((row, rowIndex) => (
        <Fragment key={row.Id}>
          <Typography
            variant="subtitle2"
            dangerouslySetInnerHTML={{
              __html: formatter.rowTitle(row).text
            }}
            id={questions.rowQuestionId(row.Id)}
          />
          {row.Columns.map(column => (
            <RenderAnswer
              {...{ question, column, row }}
              key={`${column.Id} ${row.Id}`}
              handleChange={handleChange(row, column, nextRowId(table.rows, rowIndex))}
            />
          ))}
        </Fragment>
      ))}
    </div>
  );
};

const TableMultipleOption: FC<{
  question: Question;
}> = ({ question }) => {
  const table = questions.tableFrom(question);
  const rows = useMemo(() => groupRowsByColumn(table.Rows), [table]);
  const dispatch = useDispatch();

  const handleChange = (row: Row, column: Column) => () => {
    const answer = column.Answers[0];
    dispatch(actions.answerTableFixedQuestion({ question, row, column }, answer));
  };

  return (
    <Stack gap={1} width="100%">
      {rows.map((tableByColumn, colIndex) => (
        <Table question={question} table={tableByColumn} key={colIndex} handleChange={handleChange} />
      ))}
    </Stack>
  );
};

export const TableMultipleOptionQuestion = memo(TableMultipleOption);
