import {
  answeredQuestionsInSurvey,
  indexOfSection,
  sectionContaining,
  useStateSelector,
  visibleQuestionsInSection,
  visibleQuestionsInSurvey
} from 'services';
import { percentFrom } from 'services/formatter';
import { indexOfQuestionById } from 'services/questions';
import { Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentQuestion } from '..';

const useStyle = makeStyles<Theme, { progress: string }>(theme =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center'
    },
    progressContainer: {
      height: 20,
      width: '100%',
      backgroundColor: 'lightGray',
      borderRadius: 50,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    filler: {
      height: '100%',
      width: ({ progress }) => progress,
      backgroundColor: `${theme.palette.secondary.main}`,
      borderRadius: 'inherit',
      textAlign: 'right'
    },
    label: {
      padding: 5,
      color: 'white',
      fontWeight: 'bold'
    }
  })
);
const MobileSurveyProgressBar: React.FC = () => {
  const { t } = useTranslation('survey');
  const currentSurvey = useStateSelector('survey');
  const question = useCurrentQuestion();
  const section = sectionContaining(question, currentSurvey);
  const indexSection = indexOfSection(section, currentSurvey);
  const questionsInSection = visibleQuestionsInSection(section, currentSurvey);
  const indexOfQuestionInSection = indexOfQuestionById(questionsInSection, question.Id);
  const questionsInSurvey = visibleQuestionsInSurvey(currentSurvey);
  const total = questionsInSurvey.length;
  const current = answeredQuestionsInSurvey(currentSurvey).length;
  const index = indexOfQuestionById(questionsInSurvey, question.Id);
  const title = `${indexSection + 1}. ${section.Title.toUpperCase()} (${indexOfQuestionInSection + 1}/${
    questionsInSection.length
  })`;

  const percentage = percentFrom(current / total);

  const classes = useStyle({ progress: percentage });
  const progressBarText = t('progressText', { index: index + 1, total });
  return (
    <div className={classes.container}>
      <Typography variant="subtitle2">{title}</Typography>
      <div className={classes.progressContainer}>
        <div className={classes.filler}>
          <span className={classes.label}>{percentage}</span>
        </div>
      </div>
      <Typography variant="body2">{progressBarText}</Typography>
    </div>
  );
};

export default MobileSurveyProgressBar;
