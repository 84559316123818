import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Typography } from '@mui/material';

interface InfoWithIconProps {
  icon: any;
  title: string;
  info: string;
}

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center'
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.secondary.main,
      width: 66,
      height: 66,
      borderRadius: 33,
      [theme.breakpoints.down('md')]: {
        width: 40,
        height: 40,
        borderRadius: 20
      },
      marginRight: theme.spacing(2),
      '& > *': {
        color: theme.palette.secondary.contrastText,
        width: 46,
        height: 46,
        [theme.breakpoints.down('md')]: {
          width: 30,
          height: 30
        }
      }
    }
  })
);

export const InfoWithIcon: React.FC<InfoWithIconProps> = props => {
  const classes = useStyles();
  const { icon, title, info } = props;
  return (
    <div className={classes.root}>
      <div className={classes.icon}>{icon}</div>
      <div>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="subtitle1">{info}</Typography>
      </div>
    </div>
  );
};
