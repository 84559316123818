import dayjs from 'dayjs';
import { post, get, put, basicAuthHeader, tokenAuthHeader } from './axios-helpers';
import { createHashFor, generateRandomString } from './crypto';
import { Token, ProbeUser, AuthenticationParams, ProviderConfig, SSO_PROVIDER } from './types';
import {
  REFRESH_TOKEN_DELTA_IN_MINUTES,
  PROBE_RESEARCH_CLIENT_ID,
  PROBE_ENDPOINT_BASE,
  REDIRECT_URL,
  PROVIDERS
} from './constants';
import { now } from './time-helpers';
import {
  StudyEnrollmentRequest,
  RegisterOAuthRequest,
  RegisterRequest,
  ValidLinkRequest,
  ValidLinkResponse,
  UserInfo
} from './api-hooks';

const PROBE_RESEARCH_PROVIDER = PROVIDERS['PROBEResearch'];
const PROBE_ACCOUNT_ENDPOINT_BASE = `${PROBE_ENDPOINT_BASE}api/v1/account/`;
const REGISTER_PROBE_USER = `${PROBE_ACCOUNT_ENDPOINT_BASE}register`;
const REGISTER_OAUTH_PROVIDER = `${PROBE_ACCOUNT_ENDPOINT_BASE}register/oauth`;
const UPDATE_PROBE_USER = `${PROBE_ACCOUNT_ENDPOINT_BASE}update`;
const UPDATE_PASSWORD = `${PROBE_ACCOUNT_ENDPOINT_BASE}changepassword`;
const USER_INFO_END_POINT = `${PROBE_ACCOUNT_ENDPOINT_BASE}user/info`;
const RESET_PASSWORD = `${PROBE_ACCOUNT_ENDPOINT_BASE}forgotPassword`;
const VALID_LINK = `${PROBE_ACCOUNT_ENDPOINT_BASE}validLink`;

const STUDY_ENROLL = `${PROBE_ENDPOINT_BASE}api/v1/study/enroll`;
const GET_STUDY_GUID_LOGIN = `${PROBE_ENDPOINT_BASE}api/v1/study/guidlogin`;

interface OAuthParams {
  authUrl: string;
  codeVerifier: string;
}

export interface TokenParams {
  token: Token;
  providerToken: Token | undefined;
  providerName: string | undefined;
}

export const signInWithUsernameAndPasswordAsync = async (username: string, password: string, redirectUrl?: string) => {
  const params = {
    grant_type: 'password',
    redirect_uri: redirectUrl || REDIRECT_URL,
    username,
    password
  };
  const token = await getAccessTokenAsync(params, PROBE_RESEARCH_PROVIDER);
  const userInfo = await getUserInfoAsync(token);
  return { token, userInfo };
};

export const registerForStudyGuestLoginAsync = async (studyGuid: string): Promise<Token> => {
  const token = await post(
    GET_STUDY_GUID_LOGIN,
    { guid: studyGuid },
    basicAuthHeader(PROBE_RESEARCH_PROVIDER.clientId)
  );

  return {
    ...token,
    expires_on: expiresOnFrom(token.expires_in)
  };
};

export const enrollInStudyAsync = (studyGuid: string, token: Token) => {
  if (!studyGuid) {
    return;
  }
  const params: StudyEnrollmentRequest = { Token: studyGuid };
  return put(STUDY_ENROLL, params, tokenAuthHeader(token.access_token));
};

export const registerProbeUserAsync = (probeUserParams: RegisterRequest) =>
  post(REGISTER_PROBE_USER, probeUserParams, basicAuthHeader(PROBE_RESEARCH_CLIENT_ID));

export const isStudyLinkValidAsync = async (studyGuid: string, token: Token): Promise<boolean> => {
  const params: ValidLinkRequest = { guid: studyGuid };
  const res = await get<ValidLinkResponse>(VALID_LINK, { params }, tokenAuthHeader(token.access_token));
  return Boolean(res.isValidLink);
};

const generateOAuthUrl = (redirectUri: string, providerConfig: ProviderConfig): OAuthParams => {
  const codeVerifier = generateRandomString();
  const state = generateRandomString();
  const codeChallenge = createHashFor(codeVerifier);
  const { authorizationEndPoint, clientId, scope } = providerConfig;
  let authUrl =
    `${authorizationEndPoint}?response_type=code` +
    `&client_id=${clientId}` +
    `&code_challenge=${codeChallenge}` +
    '&code_challenge_method=S256' +
    `&redirect_uri=${encodeURIComponent(redirectUri)}` +
    `&state=${state}`;

  if (scope) {
    authUrl += `&scope=${encodeURIComponent(scope)}`;
  }

  return {
    authUrl,
    codeVerifier
  };
};

const getAuthorizationCodeTokenAsync = async (
  authorizationCode: string,
  codeVerifier: string,
  redirectUrl: string,
  providerConfig: ProviderConfig
): Promise<Token> => {
  const params = {
    grant_type: 'authorization_code',
    redirect_uri: redirectUrl,
    code: authorizationCode,
    code_verifier: codeVerifier,
    client_id: providerConfig.clientId
  };

  const token = await getAccessTokenAsync(params, providerConfig);
  //For Auth0, id_token is in fact the access_token. We make sure our token are consistent
  return { ...token, access_token: token.id_token ?? token.access_token };
};

const getProbeAccessTokenAsync = (
  providerAccessToken: Token,
  redirectUrl: string,
  provider: SSO_PROVIDER
): Promise<Token> => {
  const params = {
    grant_type: 'urn:hiru:third_party_token',
    redirect_uri: redirectUrl,
    access_token: providerAccessToken.access_token,
    access_token_provider: provider
  };
  return getAccessTokenAsync(params, PROBE_RESEARCH_PROVIDER);
};

export const SSO = () => {
  const TEMP_CODE_VERIFIER = 'TEMP_CODE_VERIFIER';
  const TEMP_PROVIDER = 'TEMP_PROVIDER';
  const TEMP_STUDY_GUID = 'TEMP_STUDY_GUID';
  const TEMP_STUDY_ID = 'TEMP_STUDY_ID';
  const path = '/callback';
  const RedirectURL = window.location.origin + path;

  return {
    path,

    signIn: (provider: SSO_PROVIDER, studyGuid: string | undefined, studyId: number | undefined) => {
      const { authUrl, codeVerifier } = generateOAuthUrl(RedirectURL, PROVIDERS[provider]);
      localStorage.setItem(TEMP_CODE_VERIFIER, codeVerifier);
      localStorage.setItem(TEMP_PROVIDER, provider);
      if (studyGuid && studyId !== undefined) {
        localStorage.setItem(TEMP_STUDY_GUID, studyGuid);
        localStorage.setItem(TEMP_STUDY_ID, String(studyId));
      }
      window.location.href = authUrl;
    },

    authenticateAsync: async (authorizationCode: string): Promise<AuthenticationParams | undefined> => {
      const codeVerifier = localStorage.getItem(TEMP_CODE_VERIFIER);
      const provider = localStorage.getItem(TEMP_PROVIDER) as SSO_PROVIDER;
      const studyGuid = localStorage.getItem(TEMP_STUDY_GUID) ?? undefined;
      const studyId = Number(localStorage.getItem(TEMP_STUDY_ID));

      if (!codeVerifier || !provider) {
        return undefined;
      }

      localStorage.removeItem(TEMP_CODE_VERIFIER);
      localStorage.removeItem(TEMP_PROVIDER);
      localStorage.removeItem(TEMP_STUDY_GUID);
      localStorage.removeItem(TEMP_STUDY_ID);
      const providerConfig = PROVIDERS[provider];
      const providerToken = await getAuthorizationCodeTokenAsync(
        authorizationCode,
        codeVerifier,
        RedirectURL,
        providerConfig
      );

      let token: Token;
      try {
        token = await getProbeAccessTokenAsync(providerToken, RedirectURL, provider);
      } catch (error) {
        if (error.message !== 'no_matching_user') {
          throw error;
        }

        // user does not exist? Register
        await registerToProbeResearchAsync(provider, providerToken, studyGuid);

        // try to  get probe access token again
        token = await getProbeAccessTokenAsync(providerToken, RedirectURL, provider);
      }
      const userInfo = await getUserInfoAsync(token);
      return {
        token,
        providerToken,
        provider,
        userInfo,
        studyGuid: studyGuid,
        studyId
      };
    }
  };
};

export const refreshProbeAccessTokenAsync = (token: Pick<Token, 'refresh_token'>, redirectUrl?: string) => {
  const params = {
    grant_type: 'refresh_token',
    redirect_uri: redirectUrl || REDIRECT_URL,
    refresh_token: token.refresh_token
  };

  return getAccessTokenAsync(params, PROBE_RESEARCH_PROVIDER);
};

export const updateProbeUserAsync = (probeUserParams: ProbeUser, token: Token) =>
  put(UPDATE_PROBE_USER, probeUserParams, tokenAuthHeader(token.access_token));

export const getUserInfoAsync = (token: Token): Promise<UserInfo> =>
  get(USER_INFO_END_POINT, undefined, tokenAuthHeader(token.access_token));

export const resetPasswordAsync = async (email: string) =>
  post(RESET_PASSWORD, { email }, basicAuthHeader(PROBE_RESEARCH_CLIENT_ID));

export const updatePasswordAsync = (currentPassword: string, newPassword: string, token: Token) =>
  post(
    UPDATE_PASSWORD,
    { CurrentPassword: currentPassword, NewPassword: newPassword },
    tokenAuthHeader(token.access_token)
  );

const registerToProbeResearchAsync = (
  provider: SSO_PROVIDER,
  accessToken: Token,
  studyGuid: string | undefined
): Promise<void> => {
  const params: RegisterOAuthRequest = {
    Provider: provider,
    Token: accessToken.access_token,
    StudyParticipantGuid: studyGuid
  };

  return post(REGISTER_OAUTH_PROVIDER, params, basicAuthHeader(PROBE_RESEARCH_CLIENT_ID));
};

const getAccessTokenAsync = async (params: any, providerConfig: ProviderConfig): Promise<Token> => {
  const { tokenEndPoint, clientId } = providerConfig;
  const token = await post(tokenEndPoint, params, basicAuthHeader(clientId), /*addAppHeader*/ false);
  return {
    ...token,
    expires_on: expiresOnFrom(token.expires_in)
  };
};

export const shouldRefresh = (token: Token): boolean => {
  const expireOn = dayjs(token.expires_on);
  return dayjs().isAfter(expireOn);
};

export const expiresOnFrom = (expiresIn: number): Date =>
  // Add the token expiry time and remove some minutes to be sure that we will refresh in time
  dayjs(now()).add(expiresIn, 'second').subtract(REFRESH_TOKEN_DELTA_IN_MINUTES, 'minute').toDate();
