import { hasExpired, useStateSelector } from 'services';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { PATH } from '../routes/routes';
import { useHomeStyles } from '../styles/Home.style';
import { PastSurveys } from './DashboardWidgets/PastSurveys';
import { UpcomingSurveys } from './DashboardWidgets/UpcomingSurveys';
import { ContinueSurvey } from './Survey/ContinueSurvey';
import { ExpiredSurvey } from './Survey/ExpiredSurvey';
import { SubmittedSurvey } from './Survey/SubmittedSurvey';
import { useDispatch } from 'react-redux';
import Disclaimer from './Disclaimer';
import Content from './Content';

export const Home = () => {
  const classes = useHomeStyles();
  const survey = useStateSelector('survey');
  const isExpired = hasExpired(survey);
  const { push } = useHistory();
  const dispatch = useDispatch();

  const { isGuest } = useStateSelector('user');
  const { submitted } = survey;

  useEffect(() => {
    if (survey.isDefined && !survey.instructionsRead && isGuest) {
      push(PATH.INDEX.INSTRUCTIONS);
    }
  }, [survey, push, isGuest, dispatch]);

  const ComponentToRender = () => {
    if (isExpired) {
      return <ExpiredSurvey />;
    }

    if (submitted) {
      return <SubmittedSurvey />;
    }

    return <ContinueSurvey />;
  };

  if (isGuest) {
    return <Content>{survey.isDefined && <ComponentToRender />}</Content>;
  }

  return (
    <Content rootClass={classes.rootDashboard}>
      <Disclaimer />
      {survey.isDefined && <ComponentToRender />}
      <UpcomingSurveys />
      <PastSurveys />
    </Content>
  );
};
