import { PROBE_ENDPOINT_BASE, PROBE_RESEARCH_CLIENT_ID } from './constants';
import { get, basicAuthHeader } from './axios-helpers';

export interface InstitutionInfo {
  Name: string;
  Description: string;

  /*
   * true if the NHF institution is returned, false otherwise.
   * One exception is if the US is passed, it will return as Default: false as that is the institution for the US.
   */
  Default: true;
}

const INSTITUTION_ENDPOINT = `${PROBE_ENDPOINT_BASE}api/v1/Utility/Institution`;

export const getInstitutionInfo = (countryId: number, languageId: number): Promise<InstitutionInfo> => {
  const params = {
    CountryId: countryId,
    LanguageId: languageId
  };

  return get(INSTITUTION_ENDPOINT, { params }, basicAuthHeader(PROBE_RESEARCH_CLIENT_ID));
};
