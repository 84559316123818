import { AppThunk } from '..';
import { createAction } from '../../actions';
import { LoginType, SSO_PROVIDER, Token } from '../../types';
import { setUserState, signOut } from '../User/User.actions';
import { batch } from 'react-redux';
import { StudyConfiguration, ParticipantInfo, CacheToken } from '../../api-hooks';
import { UserState } from '../User/User.reducer';
import { expiresOnFrom, getUserInfoAsync, isStudyLinkValidAsync } from '../../auth';

export type StartSurvey = {
  loginType: LoginType;
  compositeId: number;
  availableLanguages: number[];
  availableCountries: number[];
};

type StartStudyFromLink = {
  loginType: LoginType;
  studyGuid: string;
  studyId: number;
  firstLogin: boolean;
  availableLanguages: number[];
  availableCountries: number[];
  shouldSignUserOut: boolean;
};

export const setCountryId = (countryId: number) => createAction('SET_COUNTRY_ID', { countryId });

export const setLanguage = (languageId: number) => createAction('SET_LANGUAGE', { languageId });

export const setAppLoading = (loading: boolean) => createAction('SET_LOADING', { appLoading: loading });

export const setLoginType = (loginType: LoginType) => createAction('SET_LOGIN_TYPE', { loginType });

export const setStudyLinkId = (studyGuid: string, studyId: number, firstLogin: boolean) =>
  createAction('SET_STUDY_LINK_ID', { studyGuid, studyId, firstLogin });

export const setAvailableLanguages = (languageIds: number[]) =>
  createAction('SET_AVAILABLE_LANGUAGE_IDS', { languageIds });

export const setAvailableCountries = (countryIds: number[]) =>
  createAction('SET_AVAILABLE_COUNTRY_IDS', { countryIds });

export const startAsEmbeddedDashboard =
  (tokenCache: CacheToken): AppThunk =>
  async dispatch => {
    //if the user is logged in, make sure we log out first
    const token: Token = {
      ...tokenCache,
      expires_in: 3600,
      expires_on: expiresOnFrom(3600)
    };

    const { LanguageId, CountryId } = await getUserInfoAsync(token);

    batch(() => {
      dispatch(signOut());
      dispatch(setCountryId(CountryId));
      dispatch(setLanguage(LanguageId));
      dispatch(setUserState({ token, isGuest: true }));
    });
  };

export const startStudyFromLink =
  (studyGuid: string, studyConfiguration: StudyConfiguration): AppThunk =>
  async dispatch => {
    const { AuthorizedCountries, AuthorizedLanguages, StudyId: studyId, LoginTypes } = studyConfiguration;
    const loginType = loginTypeFromAllowedTypes(LoginTypes);
    dispatch(
      startStudyFromConfig({
        studyGuid,
        firstLogin: true,
        studyId,
        availableCountries: AuthorizedCountries,
        availableLanguages: AuthorizedLanguages,
        loginType,
        shouldSignUserOut: true
      })
    );
  };

export const resumeFromStudyLink =
  (studyGuid: string, studyConfiguration: StudyConfiguration): AppThunk =>
  async (dispatch, getState: () => { user: UserState }) => {
    const {
      AuthorizedCountries,
      AuthorizedLanguages,
      StudyId: studyId,
      ParticipantInfo: participantInfo
    } = studyConfiguration;
    const { user } = getState();
    const loginType = loginTypeFromStudyStatus(participantInfo);
    let studyLinkValid = false;
    if (user.token) {
      studyLinkValid = await isStudyLinkValidAsync(studyGuid, user.token);
    }
    dispatch(
      startStudyFromConfig({
        studyGuid,
        availableCountries: AuthorizedCountries,
        availableLanguages: AuthorizedLanguages,
        studyId,
        loginType,
        shouldSignUserOut: !studyLinkValid,
        firstLogin: Boolean(participantInfo.FirstLogin)
      })
    );
  };

const loginTypeFromAllowedTypes = (loginTypes: string[]): LoginType => {
  let allowedTypes: LoginType | undefined;
  loginTypes.forEach((x: string) => {
    const type = LoginType[x as keyof typeof LoginType] as LoginType;
    allowedTypes = allowedTypes === undefined ? type : allowedTypes | type;
  });
  return allowedTypes ?? LoginType.ParticipantLink | LoginType.Probe | LoginType.ThirdPartyApp;
};

const loginTypeFromStudyStatus = ({ Provider, UrlLoginConfirmed }: ParticipantInfo): LoginType => {
  if (UrlLoginConfirmed) {
    return LoginType.ParticipantLink;
  }

  const provider = Provider as SSO_PROVIDER;
  if (provider === 'PROBEResearch') {
    return LoginType.Probe;
  }

  return LoginType.ThirdPartyApp;
};

const startStudyFromConfig =
  ({
    loginType,
    studyGuid,
    availableLanguages,
    availableCountries,
    shouldSignUserOut,
    studyId,
    firstLogin
  }: StartStudyFromLink): AppThunk =>
  dispatch => {
    const countries = availableCountries;
    const languages = availableLanguages;

    batch(() => {
      shouldSignUserOut && dispatch(signOut());
      dispatch(setLoginType(loginType));
      dispatch(setStudyLinkId(studyGuid, studyId, firstLogin));
      dispatch(setAvailableLanguages(languages));
      dispatch(setAvailableCountries(countries));
    });
  };

export type SystemActions = ReturnType<
  | typeof setCountryId
  | typeof setLanguage
  | typeof setAppLoading
  | typeof setLoginType
  | typeof setAvailableLanguages
  | typeof setAvailableCountries
  | typeof setStudyLinkId
>;
