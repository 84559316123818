import { FabOutlined, Stack, Swipe, useHandler, useNotification } from 'components';
import {
  getSignInOptions,
  getHemophiliaAppOptions,
  store,
  countryBy,
  languageFor,
  languageById,
  useStateSelector,
  LoginType
} from 'services';
import { Divider, Fab, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { AccountCircleOutlined, ArrowForward, PeopleOutlined } from '@mui/icons-material';
import { Fragment, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { PATH } from '../routes/routes';
import queryString from 'query-string';
import { useAsyncDispatch } from '../hooks/useAsyncDispatch';

interface Options {
  icon: ReactNode;
  title: string;
  info: string | ReactNode;
  nextButton: ReactNode;
}

const useStyles = makeStyles(
  createStyles({
    option: {
      width: '100%',
      display: 'inline-grid',
      gridTemplateColumns: '40px auto 56px',
      gridGap: 10
    }
  })
);
const SignInOptions = () => {
  const { countryId, languageId, loginType } = useStateSelector('system');
  const { t } = useTranslation('account');
  const { t: tError } = useTranslation('errors');
  const { notify } = useNotification();
  const { i18n } = useTranslation();
  const { push } = useHistory();
  const classes = useStyles({});
  const dispatch = useAsyncDispatch();
  const signInOptions: Options[] = [];
  const { provider } = getHemophiliaAppOptions(countryId);
  const { signInAsStudyParticipant } = store.actions.user;

  const changeLanguage = useHandler((newLanguageId: number) => {
    const languageCode = languageById(newLanguageId).code;
    i18n.changeLanguage(languageCode);
    dispatch(store.actions.system.setLanguage(newLanguageId));
  });

  useEffect(() => {
    const { country: countryCode, language } = queryString.parse(window.location.search);
    const newCountryId = countryCode ? countryBy(countryCode as string).id : countryId;
    const newLanguageId = language ? languageFor(language as string).languageId : languageId;
    if (newCountryId !== countryId) {
      dispatch(store.actions.system.setCountryId(newCountryId));
    }
    if (newLanguageId !== languageId) {
      changeLanguage(newLanguageId);
    }
  }, [countryId, languageId, dispatch, changeLanguage]);

  const handleStudyParticipantLinkSignIn = async () => {
    try {
      //Only one type allowed, we login automatically as there are no choices to be made anyways
      if (loginType === LoginType.ParticipantLink) {
        await dispatch(signInAsStudyParticipant());
        push(PATH.INDEX.ROOT);
        return;
      }

      push(PATH.INDEX.PARTICIPANT);
    } catch (error: any) {
      notify({ type: 'error', message: tError(error.message) });
    }
  };

  const allSignInOptions = getSignInOptions(countryId, loginType);
  allSignInOptions.forEach((option, index) => {
    switch (option) {
      case 'probeAccount':
        signInOptions.push({
          icon: <AccountCircleOutlined fontSize="large" color="primary" />,
          title: t('loginAsAProbeUser'),
          info: t('loginAsAProbeUserDescription', {
            provider
          }),
          nextButton: (
            <Fab
              data-testid="sign-in-probe-account"
              color={index ? 'primary' : 'secondary'}
              onClick={() => push(PATH.INDEX.SIGN_IN)}
              aria-label={t('loginAsAProbeUser')}
            >
              <ArrowForward />
            </Fab>
          )
        });
        break;
      case 'participantLink':
        signInOptions.push({
          icon: <PeopleOutlined fontSize="large" color="primary" />,
          title: t('startStudyAnonymously'),
          info: t('startStudyAnonymouslyDescription'),
          nextButton: (
            <FabOutlined
              data-testid="sign-in-guest"
              color="primary"
              onClick={handleStudyParticipantLinkSignIn}
              aria-label={t('startStudyAnonymously')}
            >
              <ArrowForward />
            </FabOutlined>
          )
        });
        break;

      default:
        break;
    }
  });

  return (
    <Swipe direction="left">
      <Stack gap={2} data-testid="sign-in-options">
        {signInOptions.map((option, key) => (
          <Fragment key={key}>
            {!!key && <Divider />}

            <div className={classes.option}>
              {option.icon}
              <div>
                <Typography variant="body1">{option.title}</Typography>
                <Typography variant="caption">{option.info}</Typography>
              </div>
              {option.nextButton}
            </div>
          </Fragment>
        ))}
      </Stack>
    </Swipe>
  );
};

export default SignInOptions;
